import { createContext } from 'react';
export const MapsContext = createContext({
    selectedBasemap: null,
    onChangeBasemap: () => console.warn('MapsContext.onChangeBasemap is not implemented'),
    onOpenAoiMap: () => console.warn('MapsContext.onSetAoiMapOpen is not implemented'),
    zoomLevel: null,
    onSetZoomLevel: () => console.warn('MapsContext.onSetZoomLevel is not implemented'),
    bounds: null,
    onSetBounds: () => console.warn('MapsContext.onSetBounds is not implemented'),
    getOutlineColor: () => null,
});
