import _isNil from 'lodash/isNil';
const singleton = Symbol('singletonSymbol');
const singletonEnforcer = Symbol('singletonEnforcerSymbol');
export class TokenHandler {
    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) {
            throw new Error("can't construct singleton");
        }
    }
    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new TokenHandler(singletonEnforcer);
        }
        return this[singleton];
    }
    setToken(token) {
        this._token = token;
        return this;
    }
    hasToken() {
        return !_isNil(this._token);
    }
    getToken() {
        return this._token;
    }
}
