import { createApi } from '@reduxjs/toolkit/query/react';
import _isNil from 'lodash/isNil';
import { axiosBaseQuery } from '@Services/axios/AxiosClient';
import { graphqlBaseQuery } from '@Services/graphql/GraphqlClient';
import { createToast } from '@Services/notifications/NotificationService';
export var TagType;
(function (TagType) {
    TagType["JOBS"] = "Jobs";
    TagType["JOB"] = "Job";
    TagType["RUN_RESULT_JOB"] = "RunResultJob";
    TagType["AOI"] = "AreaOfInterest";
    TagType["AOI_RUN"] = "AreaOfInterestRun";
    TagType["USER"] = "User";
    TagType["AOI_RUN_RESULT"] = "AreaOfInterestRunResult";
    TagType["COMPARISON_RESULT"] = "ComparisonResult";
    TagType["STUS"] = "STUs";
})(TagType || (TagType = {}));
export const BASE_API_URL = `https://${process.env.URL_PREFIX}hasura.${process.env.BASE_URL}/api/rest`;
export const WEB_API_URL = `https://${process.env.URL_PREFIX}webapi.${process.env.BASE_URL}`;
export const GRAPHQL_URL = `https://${process.env.URL_PREFIX}hasura.${process.env.BASE_URL}/v1/graphql`;
export const AOIRERPORT_FAAS_URL = `https://${process.env.URL_PREFIX}reporting-api-783510602082.australia-southeast1.run.app`;
export const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: axiosBaseQuery({ baseUrl: BASE_API_URL }),
    endpoints: () => ({}),
    tagTypes: [
        TagType.JOBS,
        TagType.JOB,
        TagType.RUN_RESULT_JOB,
        TagType.AOI,
        TagType.AOI_RUN,
        TagType.USER,
        TagType.AOI_RUN_RESULT,
        TagType.COMPARISON_RESULT,
    ],
});
export const webApi = createApi({
    reducerPath: 'webApi',
    baseQuery: axiosBaseQuery({ baseUrl: WEB_API_URL }),
    endpoints: () => ({}),
});
export const faasApi = createApi({
    reducerPath: 'faasApi',
    endpoints: () => ({}),
    baseQuery: axiosBaseQuery(),
});
export const aoiReportfaasApi = createApi({
    reducerPath: 'aoiReportfaasApi',
    endpoints: () => ({}),
    baseQuery: axiosBaseQuery({ baseUrl: AOIRERPORT_FAAS_URL }),
});
export const graphqlApi = createApi({
    reducerPath: 'gqlApi',
    endpoints: () => ({}),
    baseQuery: graphqlBaseQuery({ baseUrl: GRAPHQL_URL }),
    tagTypes: [TagType.STUS],
});
export const ErrorHandler = (api) => (next) => (action) => {
    if (action.error) {
        if (action.error.message &&
            action.error.message.indexOf('Aborted due to condition callback returning false') > -1) {
            return next(action);
        }
        if (!_isNil(action.payload)) {
            const payload = action.payload;
            createToast(`Error: ${payload.status} - ${payload.data.code}`, 'error', payload.data.error);
        }
        else {
            const errorAction = action.error;
            createToast(errorAction.name, 'error', errorAction.message);
        }
    }
    return next(action);
};
