import { createSelector, createSlice } from '@reduxjs/toolkit';
import { layersSelectedIndexState } from '@Data/layers/Reducer';
import { initialTimeState } from './State';
const TimeSlice = createSlice({
    name: 'Playback',
    initialState: initialTimeState,
    reducers: {
        reset() {
            return initialTimeState;
        },
        setTimestamps(state, action) {
            state.timestamps = action.payload;
            state.selectedTimestamp = action.payload[0];
        },
        setSelectedTimestamp(state, action) {
            state.selectedTimestamp = action.payload;
        },
    },
});
export const TimeActions = TimeSlice.actions;
export const timestampsState = (state) => state.time.timestamps;
export const selectedTimestampState = (state) => state.time.selectedTimestamp;
export const selectedTimestampIndexState = createSelector(selectedTimestampState, layersSelectedIndexState, (timestamp, selectedIndex) => {
    if (!timestamp || !selectedIndex)
        return null;
    return `${timestamp}${selectedIndex.spectral_index}`;
});
export default TimeSlice.reducer;
