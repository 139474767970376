import { getSpectralIndicesLabelsByTheme } from '@Services/helpers/Themes';
export function transformGetAoiRunsResponse(response) {
    var _a, _b;
    const theme = (_b = (_a = response.aoi[0]) === null || _a === void 0 ? void 0 : _a.job) === null || _b === void 0 ? void 0 : _b.theme;
    const aoiRuns = response.aoi_run.map((aoiRun) => {
        var _a, _b, _c;
        return (Object.assign(Object.assign({}, aoiRun), { aoi_run_results: getSpectralIndicesLabelsByTheme(theme, aoiRun.aoi_run_results, ['value', 'id']), themeName: theme.name, job_spectral_indices: (_c = (_b = (_a = response.aoi[0]) === null || _a === void 0 ? void 0 : _a.job) === null || _b === void 0 ? void 0 : _b.job_spectral_indices) !== null && _c !== void 0 ? _c : [] }));
    });
    return {
        aoiRuns: aoiRuns,
        count: response.aoi_run_aggregate.aggregate.count,
    };
}
