import { TagType, baseApi } from '@Services/redux';
import { transformGetAoiRunsResponse } from './helpers';
export const aoiRunsApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getAoiRuns: build.query({
            query: (payload) => {
                return {
                    url: `get_aoi_runs/${payload.aoi_id}`,
                    method: 'POST',
                    data: payload,
                };
            },
            transformResponse: (gqlResponse) => transformGetAoiRunsResponse(gqlResponse),
            providesTags: [TagType.AOI_RUN],
        }),
    }),
});
export const { useGetAoiRunsQuery } = aoiRunsApi;
