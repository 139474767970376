var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import transformScale from '@turf/transform-scale';
import _isNil from 'lodash/isNil';
import axiosInstance from '@Services/axios/AxiosClient';
import { GlobalKeys } from '@Services/globalVariables/GlobalKeys';
import { clearGlobalVariable } from '@Services/globalVariables/GlobalVariableManager';
import { WEB_API_URL, store } from '@Services/redux';
import { H3Actions } from './Reducer';
import { H3AbortControllerKey, abortOutstandingH3Requests, addH3AbortController, cacheQueriedPolygon, getUnqueriedPolygonByResolution, } from './helpers';
export function clearAllCachedData() {
    clearGlobalVariable(GlobalKeys.CancelTokens);
    clearGlobalVariable(GlobalKeys.QueriedPolygonsByResolution);
}
export function cancelH3Requests() {
    const abortControllerKey = H3AbortControllerKey.GET_DATA;
    abortOutstandingH3Requests(abortControllerKey);
    store.dispatch(H3Actions.setDataLoadingState('loaded'));
}
export function getH3Data(bbox, resolution, metadata) {
    return __awaiter(this, void 0, void 0, function* () {
        const abortControllerKey = H3AbortControllerKey.GET_DATA;
        abortOutstandingH3Requests(abortControllerKey);
        const abortSignal = addH3AbortController(abortControllerKey);
        const unqueriedPolygon = getUnqueriedPolygonByResolution(bbox, resolution);
        if (_isNil(unqueriedPolygon))
            return null;
        store.dispatch(H3Actions.setDataLoadingState('fetching'));
        const response = yield axiosInstance({
            url: `${WEB_API_URL}/data_access/h3-formatted`,
            method: 'POST',
            data: {
                bbox: transformScale(unqueriedPolygon, 1.15),
                resolution: resolution,
                run_result_ids: metadata.map((m) => m.id),
            },
            signal: abortSignal,
        }).catch((e) => {
            console.error(e);
            return null;
        });
        if (_isNil(response)) {
            store.dispatch(H3Actions.setDataLoadingState('loaded'));
            return null;
        }
        const timestamp = Date.now();
        cacheQueriedPolygon(bbox, resolution, timestamp);
        const parsed = parseH3Response(response);
        store.dispatch(H3Actions.setDataLoadingState('loaded'));
        return { resolution: resolution, id: timestamp, cells: parsed };
    });
}
function parseH3Response(response) {
    const output = [];
    response.map((r) => {
        Object.entries(r).forEach(([rrId, values]) => {
            output.push({
                id: rrId,
                values: values,
            });
        });
    });
    return output;
}
