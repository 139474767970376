import { ReactComponent as ErrorIcon } from '@Icons/lg/alert-octagon.svg';
import { ReactComponent as SaveIcon } from '@Icons/md/check.svg';
import { ReactComponent as EditIcon } from '@Icons/md/edit-2.svg';
import { ReactComponent as TrashIcon } from '@Icons/md/trash-2.svg';
import { ReactComponent as CloseIcon } from '@Icons/md/x.svg';
import { CircularProgress } from '@mui/material';
import { IconButton, TextInput, useOnEnter } from '@orbica/component-sdk';
import ColorStyles from '@orbica/component-sdk/build/css/colors.scss';
import Tokens from '@orbica/component-sdk/build/css/tokens.scss';
import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import area from '@turf/area';
import cx from 'classnames';
import _cloneDeep from 'lodash/cloneDeep';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { convertAreaFromSqm } from '@Services/helpers/Area';
import { AreaOfInterestOutline } from './AreaOfInterestOutline';
const StyledAoiContainer = styled('div') `
    display: grid;
    grid-template-columns: 45px 1fr 60px;
    column-gap: 4px;
    align-items: center;
    margin: 4px 0;

    .icons-container {
        display: flex;
        .icon-btn:first-child {
            margin-right: 4px;
        }
    }

    .name-input,
    .name-label {
        min-width: 0;
    }

    .name-label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .area-label {
        grid-column: 2;
        font-size: 10px;
    }

    .error {
        color: ${Tokens.feedbackError};
        svg {
            path {
                fill: ${Tokens.feedbackError};
            }
        }
    }

    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
var AoiListItemViewState;
(function (AoiListItemViewState) {
    AoiListItemViewState[AoiListItemViewState["VIEW"] = 0] = "VIEW";
    AoiListItemViewState[AoiListItemViewState["EDIT"] = 1] = "EDIT";
    AoiListItemViewState[AoiListItemViewState["DELETE"] = 2] = "DELETE";
})(AoiListItemViewState || (AoiListItemViewState = {}));
export const AreaOfInterestListItem = (props) => {
    var _a;
    const inputRef = useRef(null);
    const [viewState, setViewState] = useState(!props.feature.properties.name || props.feature.properties.name === ''
        ? AoiListItemViewState.EDIT
        : AoiListItemViewState.VIEW);
    const [pendingName, setPendingName] = useState('');
    useOnEnter([inputRef], () => {
        handleUpdateName();
    });
    const handleEnterEdit = () => {
        var _a;
        props.onZoomToBounds();
        setPendingName((_a = props.feature.properties.name) !== null && _a !== void 0 ? _a : '');
        setViewState(AoiListItemViewState.EDIT);
    };
    const handleUpdateName = () => {
        const clonedFeature = _cloneDeep(props.feature);
        clonedFeature.properties.name = pendingName;
        props.onUpdate(clonedFeature);
        setViewState(AoiListItemViewState.VIEW);
    };
    const iconHtml = props.isLoading ? (React.createElement("div", { className: "icon-container" },
        React.createElement(CircularProgress, { size: 25 }))) : (React.createElement(AreaOfInterestOutline, { feature: props.feature, color: props.color, onClick: props.onZoomToBounds, tooltip: "Zoom to bounds" }));
    const innerHtml = viewState === AoiListItemViewState.EDIT ? (React.createElement(React.Fragment, null,
        React.createElement(TextInput, { size: "small", value: pendingName, onChange: (e) => setPendingName(e.target.value), autoFocus: true, containerClassName: "name-input", error: pendingName.length > 63, errorMessage: "Max length is 63 characters", placeholder: "Enter a name", ref: inputRef }),
        React.createElement("div", { className: "icons-container" },
            React.createElement(IconButton, { IconProps: {
                    icon: React.createElement(CloseIcon, null),
                }, onClick: () => setViewState(AoiListItemViewState.VIEW), tooltip: "Cancel", size: "small", className: "icon-btn" }),
            React.createElement(IconButton, { IconProps: {
                    icon: React.createElement(SaveIcon, null),
                    color: ColorStyles.feedbackSuccess400,
                }, onClick: handleUpdateName, tooltip: "Save", disabled: pendingName === props.feature.properties.name ||
                    pendingName === '', size: "small", className: "icon-btn" })))) : (React.createElement(React.Fragment, null,
        React.createElement("span", { className: cx(TypographyStyles.smallSemibold, 'name-label') }, props.feature.properties.name &&
            props.feature.properties.name !== ''
            ? props.feature.properties.name
            : 'Please enter a name'),
        React.createElement("div", { className: "icons-container" },
            React.createElement(IconButton, { IconProps: {
                    icon: React.createElement(EditIcon, null),
                }, onClick: handleEnterEdit, tooltip: "Edit name", size: "small", className: "icon-btn" }),
            React.createElement(IconButton, { IconProps: {
                    icon: React.createElement(TrashIcon, null),
                    color: ColorStyles.feedbackError400,
                }, onClick: props.onDelete, tooltip: "Delete", size: "small", className: "icon-btn" }))));
    const flavorMessage = props.errorMessage ? (React.createElement("span", { className: 'area-label error' }, props.errorMessage)) : (React.createElement("span", { className: 'area-label' }, convertAreaFromSqm((_a = props.feature.properties.area) !== null && _a !== void 0 ? _a : area(props.feature))));
    return (React.createElement(StyledAoiContainer, null,
        iconHtml,
        innerHtml,
        props.errorMessage && (React.createElement("div", { className: "icon-container error" },
            React.createElement(ErrorIcon, null))),
        flavorMessage));
};
