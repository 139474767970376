var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Skeleton } from '@mui/material';
import GridStyles from '@orbica/component-sdk/build/css/grid.scss';
import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import _isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { downloadFileFromS3 } from '@Data/runResults/Api';
import { MainImage } from './MainImage';
import { Thumbnail } from './Thumbnail';
const CarouselContainer = styled('div') `
    grid-column: span 6;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: ${GridStyles.columnGap};
    row-gap: ${GridStyles.rowGap};
`;
const TitleContainer = styled('div') `
    grid-column: span 5;
`;
const ThumbnailContainer = styled('div') `
    grid-column: span 1;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .thumbnail {
        width: 100%;
        height: auto;
    }

    img {
        cursor: pointer;
    }
`;
const MainImgContainer = styled('div') `
    grid-column: span 5;

    .mainImg {
        width: 100%;
        height: auto;
    }
`;
export var ComparisonImageType;
(function (ComparisonImageType) {
    ComparisonImageType[ComparisonImageType["CURRENT"] = 0] = "CURRENT";
    ComparisonImageType[ComparisonImageType["BASELINE"] = 1] = "BASELINE";
    ComparisonImageType[ComparisonImageType["BASELINE_GIF"] = 2] = "BASELINE_GIF";
    ComparisonImageType[ComparisonImageType["CHANGE"] = 3] = "CHANGE";
    ComparisonImageType[ComparisonImageType["RGB_COG"] = 4] = "RGB_COG";
})(ComparisonImageType || (ComparisonImageType = {}));
export function getTitleFromImageType(type) {
    switch (type) {
        case ComparisonImageType.CURRENT:
            return 'Latest Image';
        case ComparisonImageType.BASELINE:
            return 'Baseline Image';
        case ComparisonImageType.BASELINE_GIF:
            return 'Changes in Baseline Images';
        case ComparisonImageType.CHANGE:
            return 'Detected Change';
        case ComparisonImageType.RGB_COG:
            return 'Latest Image (True Colour)';
    }
}
export function getDateFromImageType(type, acquisition_time, baseline_time_range) {
    switch (type) {
        case ComparisonImageType.CURRENT:
            return acquisition_time;
        case ComparisonImageType.BASELINE:
            return baseline_time_range;
        case ComparisonImageType.RGB_COG:
            return acquisition_time;
    }
}
export const ImageCarousel = (props) => {
    const [selectedImg, setSelectedImg] = useState({
        url: null,
        type: null,
    });
    const [currentImg, setCurrentImg] = useState(false);
    const [baselineImg, setBaselineImg] = useState(false);
    const [baselineGif, setBaselineGif] = useState(false);
    const [changeImg, setChangeImg] = useState(false);
    const [rgbCogImg, setRgbCogImg] = useState(false);
    const handleSetSelectedImg = (url, type) => {
        setSelectedImg({
            url: url,
            type: type,
        });
    };
    useEffect(() => {
        function getImg(url, onComplete) {
            return __awaiter(this, void 0, void 0, function* () {
                const decodedImg = yield downloadFileFromS3(url);
                onComplete(decodedImg);
            });
        }
        if (!props.isLoading) {
            if (!_isNil(props.current)) {
                getImg(props.current, (img) => {
                    setCurrentImg(img);
                    handleSetSelectedImg(img, ComparisonImageType.CURRENT);
                });
            }
            else {
                setCurrentImg(null);
            }
            if (!_isNil(props.baseline)) {
                getImg(props.baseline, (img) => setBaselineImg(img));
            }
            else {
                setBaselineImg(null);
            }
            if (!_isNil(props.baseline_gif)) {
                getImg(props.baseline_gif, (img) => setBaselineGif(img));
            }
            else {
                setBaselineGif(null);
            }
            if (!_isNil(props.change)) {
                getImg(props.change, (img) => setChangeImg(img));
            }
            else {
                setChangeImg(null);
            }
            if (!_isNil(props.rgbCog)) {
                getImg(props.rgbCog, (img) => setRgbCogImg(img));
            }
            else {
                setRgbCogImg(null);
            }
        }
    }, [
        props.isLoading,
        props.current,
        props.baseline,
        props.baseline_gif,
        props.change,
        props.rgbCog,
    ]);
    return (React.createElement(CarouselContainer, null,
        React.createElement("div", null),
        React.createElement(TitleContainer, null, !_isNil(selectedImg.type) ? (React.createElement("span", { className: TypographyStyles.heading3Light }, getTitleFromImageType(selectedImg.type))) : (React.createElement(Skeleton, { variant: "text", height: 32, width: 250 }))),
        React.createElement(ThumbnailContainer, null,
            React.createElement(Thumbnail, { imageState: currentImg, onClick: handleSetSelectedImg, type: ComparisonImageType.CURRENT, isLoading: props.isLoading }),
            React.createElement(Thumbnail, { imageState: baselineImg, onClick: handleSetSelectedImg, type: ComparisonImageType.BASELINE, isLoading: props.isLoading }),
            React.createElement(Thumbnail, { imageState: baselineGif, onClick: handleSetSelectedImg, type: ComparisonImageType.BASELINE_GIF, isLoading: props.isLoading }),
            React.createElement(Thumbnail, { imageState: changeImg, onClick: handleSetSelectedImg, type: ComparisonImageType.CHANGE, isLoading: props.isLoading }),
            React.createElement(Thumbnail, { imageState: rgbCogImg, onClick: handleSetSelectedImg, type: ComparisonImageType.RGB_COG, isLoading: props.isLoading })),
        React.createElement(MainImgContainer, null,
            React.createElement(MainImage, { imgUrl: selectedImg.url, isLoading: props.isLoading }))));
};
