import { ReactComponent as InfoIcon } from '@Icons/md/info.svg';
import { Popover } from '@mui/material';
import { IconButton, toTitleCase } from '@orbica/component-sdk';
import React, { useState } from 'react';
import { EntityAttributeCard } from '@Components/Cards';
import { ThemeInformationCard } from '../ThemeInformationCard/ThemeInformationCard';
import Styles from './ThemeAttributeCard.scss';
export const ThemeAttributeCard = (props) => {
    var _a;
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(EntityAttributeCard, { isLoading: props.isLoading, title: "Theme", value: React.createElement("div", { style: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                } },
                toTitleCase((_a = props.theme) === null || _a === void 0 ? void 0 : _a.name),
                React.createElement(IconButton, { IconProps: {
                        icon: React.createElement(InfoIcon, null),
                    }, onClick: handleClick })) }),
        React.createElement(Popover, { open: Boolean(anchorEl), onClose: () => setAnchorEl(null), anchorEl: anchorEl },
            React.createElement(ThemeInformationCard, { theme: props.theme, renderOpen: true, className: Styles.themeInfoCard, disableCollapse: true }))));
};
