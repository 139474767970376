var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AssistiveText, DateRangePicker, DrawerAccordion, FormControl, Label, PrimaryButton, } from '@orbica/component-sdk';
import _isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Legend } from '@Components/Symbology/Legend/Legend';
import { OpacitySlider } from '@Components/Symbology/OpacitySlider/OpacitySlider';
import { getAugmentedThemes, getSentinelCollectionImages, } from '@Data/imagery/Api';
import { SENTINEL_2_MIN_DATE } from '@Data/imagery/helpers';
import { layersSelectedIndexState } from '@Data/layers/Reducer';
import { resetSymbology } from '@Data/symbology/Api';
import { MapsContext } from '@Services/maps';
import { createToast } from '@Services/notifications/NotificationService';
import Styles from './PreExploreDrawer.scss';
import { SatelliteImageSelectorDialog } from './SatelliteImageSelectorDialog';
import { ThemeSelector } from './ThemeSelector';
export const PreExploreDrawer = (props) => {
    const mapsContext = useContext(MapsContext);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [sentinelCollectionResponse, setSentinelCollectionResponse] = useState(null);
    const [isFetchingCollections, setIsFetchingCollections] = useState(false);
    const [themes, setThemes] = useState(null);
    const [hasLoaded, setHasLoaded] = useState(false);
    const indexInfo = useSelector(layersSelectedIndexState);
    const areFieldsFilled = !_isNil(startDate) && !_isNil(endDate);
    const canQuery = areFieldsFilled && mapsContext.zoomLevel > 9;
    useEffect(() => {
        return () => {
            resetSymbology();
        };
    }, []);
    useEffect(() => {
        const handleGetAugmentedThemes = () => __awaiter(void 0, void 0, void 0, function* () {
            const augmentedThemes = yield getAugmentedThemes();
            setThemes(augmentedThemes);
        });
        if (!hasLoaded) {
            handleGetAugmentedThemes();
        }
    }, [hasLoaded]);
    const handleFetchImages = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsFetchingCollections(true);
        const response = yield getSentinelCollectionImages(startDate, endDate, mapsContext.bounds);
        setIsFetchingCollections(false);
        if (response.numberMatched > 0) {
            setSentinelCollectionResponse(response);
        }
        else {
            createToast('No results were found for that date, in that area', 'error');
        }
    });
    const handleSelectImage = (collections) => {
        props.onSetCollectionIdDates(collections);
        setSentinelCollectionResponse(null);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerAccordion, { title: "Date range", defaultExpanded: true },
            React.createElement(DateRangePicker, { startDate: startDate, endDate: endDate, onChangeStartDate: (d) => setStartDate(d), onChangeEndDate: (d) => setEndDate(d), minDate: SENTINEL_2_MIN_DATE, maxDate: DateTime.now(), StartDateProps: {
                    containerClassName: Styles.drawerChild,
                    label: 'Start Date',
                }, EndDateProps: {
                    containerClassName: Styles.drawerChild,
                    label: 'End Date',
                } }),
            React.createElement("div", { className: Styles.submitButtonContainer },
                React.createElement(PrimaryButton, { disabled: !canQuery, onClick: handleFetchImages, isLoading: isFetchingCollections, loadingText: "Fetching Results" }, "Fetch Results"),
                !canQuery && areFieldsFilled && (React.createElement(AssistiveText, { error: true, message: "Zoom to a smaller area on the map" })),
                !_isNil(props.collections) &&
                    props.collections.length > 0 && (React.createElement(FormControl, { containerClassName: Styles.imageDateContainer },
                    React.createElement(Label, { label: "Selected image date" }),
                    React.createElement("span", null, DateTime.fromISO(props.collections[0].date).toLocaleString(DateTime.DATE_MED)))))),
        !_isNil(props.collections) && props.collections.length > 0 ? (React.createElement(React.Fragment, null,
            React.createElement(DrawerAccordion, { title: "Spectral Indices", defaultExpanded: true },
                React.createElement(ThemeSelector, { onSetLayer: props.onSetLayer, selectedLayer: props.selectedLayer, themes: themes })),
            React.createElement(DrawerAccordion, { title: "Symbology" },
                (!_isNil(indexInfo) ||
                    !_isNil(props.selectedLayer)) && (React.createElement(Legend, { type: "explore", selectedLayer: props.selectedLayer })),
                React.createElement(OpacitySlider, null))))
            : (React.createElement("div", { className: Styles.emptyState },
                React.createElement("div", null,
                    React.createElement("p", null, "Preview Sentinel-2 imagery for a location and time before you set up a job via the Schedule Tab. It loads up Sentinel-2 granules and calculates the Measure/Index you select."),
                    React.createElement("b", null, "Imagery date range"),
                    React.createElement("p", null, "Here you can choose a range of dates that you want to view imagery for."),
                    React.createElement("b", null, "Map window"),
                    React.createElement("p", null, "Shows the basemap and layer outputs."),
                    React.createElement("b", null, "Map zoom controls"),
                    React.createElement("p", null, "You can set the map zoom and bearing (direction) here."),
                    React.createElement("b", null, "Map tools"),
                    React.createElement("p", null, "This panel contains a number of tools for use on the map:"),
                    React.createElement("ul", null,
                        React.createElement("li", null, "Basemap type (Satellite, Hybrid, Aerial (NZ Only), Light and Dark)."),
                        React.createElement("li", null, "Address search."),
                        React.createElement("li", null, "Measure distance: A tool to measure distance in Kilometers."),
                        React.createElement("li", null, "Draw polygon: This tool allows the creation of one or more polygons which can be used to measure area (in km\u00B2).")),
                    React.createElement("p", null, "It also allows you to:"),
                    React.createElement("ul", null,
                        React.createElement("li", null, "Create a new Job using the polygon/s as AOIs."),
                        React.createElement("li", null, "Export to a single or multiple polygon GeoJSON."),
                        React.createElement("li", null, "Delete all polygons."),
                        React.createElement("li", null, "Name, edit and delete individual polygons (similar to Selecting an AOI).")),
                    React.createElement("p", null,
                        "To learn more check out the ",
                        React.createElement("a", { href: "https://docs.orbica.com/docs/Documentation/Explore%20Tab/explore%20tab/", target: "_blank" }, "User Guide"),
                        " ")))),
        React.createElement(SatelliteImageSelectorDialog, { onClose: () => setSentinelCollectionResponse(null), onSelectImage: handleSelectImage, collectionResponse: sentinelCollectionResponse })));
};
