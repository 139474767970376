import { Slider } from '@orbica/component-sdk';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import _debounce from 'lodash/debounce';
import React from 'react';
import { setOpacity } from '@Data/symbology/Api';
export const OpacitySlider = () => {
    const handleChange = _debounce((e, value) => {
        setOpacity(value);
    }, 0);
    return (React.createElement(Slider, { label: "Opacity", defaultValue: 1, min: 0, max: 1, step: 0.05, onChange: handleChange, valueLabelFormat: (v) => `${Math.floor(v * 100)}%`, containerClassName: GridContentStyles.max }));
};
