import logoImg from '@Images/logo_white.png';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Styles from './LoginView.scss';
const infoDivId = 'infoDiv';
export const LoginView = () => {
    return (React.createElement("div", { className: Styles.loginContainer },
        React.createElement("div", { className: Styles.borderContainer },
            React.createElement("div", { className: Styles.infoContainer },
                React.createElement("div", { className: Styles.info, id: infoDivId },
                    React.createElement("a", { href: "https://www.orbica.com", target: "_blank", rel: "noreferrer" },
                        React.createElement("img", { src: logoImg, alt: "Orbica Logo" })),
                    React.createElement("div", null,
                        React.createElement("span", { className: Styles.h1 }, "Environmental"),
                        React.createElement("span", { className: Styles.h1 }, "Monitoring at"),
                        React.createElement("span", { className: Styles.h1 }, "Scale.")))),
            React.createElement("div", { className: Styles.welcomeContainer },
                React.createElement("div", { className: Styles.welcome },
                    React.createElement(Outlet, null))))));
};
