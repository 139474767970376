import { MainContainer, Page, PageContent } from '@orbica/component-sdk';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '@Components/Header/Header';
import { Tabs } from '@Components/Tabs/Tabs';
import Styles from './ScheduleView.scss';
export const ScheduleView = () => {
    return (React.createElement(Page, null,
        React.createElement(Header, null,
            React.createElement(Tabs, { currentView: "schedule" })),
        React.createElement(PageContent, null,
            React.createElement(MainContainer, { GridContainerProps: { className: Styles.scheduleGrid } },
                React.createElement("div", { className: Styles.scheduleGridContainer },
                    React.createElement(Outlet, null))))));
};
