import { DrawerAccordion } from '@orbica/component-sdk';
import React from 'react';
import { useSelector } from 'react-redux';
import { ExtrusionController } from '@Components/Symbology/ExtrusionController/ExtrusionController';
import { Legend } from '@Components/Symbology/Legend/Legend';
import { OpacitySlider } from '@Components/Symbology/OpacitySlider/OpacitySlider';
import { colorRangesState, selectedSymbologyState, } from '@Data/symbology/Reducer';
export const SymbologyAccordion = () => {
    const symbology = useSelector(selectedSymbologyState);
    const colorRanges = useSelector(colorRangesState);
    return (symbology &&
        colorRanges && (React.createElement(DrawerAccordion, { title: "Symbology", defaultExpanded: true },
        React.createElement(Legend, null),
        React.createElement(OpacitySlider, null),
        React.createElement(ExtrusionController, null))));
};
