import { Select } from '@orbica/component-sdk';
import { SelectOption } from '@orbica/component-sdk';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import Tokens from '@orbica/component-sdk/build/css/tokens.scss';
import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { setColorBrewOption } from '@Data/symbology/Api';
import { selectedColorBrewState } from '@Data/symbology/Reducer';
import { ColorBrewerOptions } from '@Data/symbology/Types';
const StyledContainer = styled('div') `
    display: flex;
    flex-direction: column;
    padding: 5px 0;

    img {
        height: 15px;
        width: auto;
    }

    &:hover {
        background-color: ${Tokens.defaultHover};
    }
`;
const StyledImg = styled('img') `
    height: auto;
    width: 100%;
`;
const ColorBrewerDisplay = ({ option, }) => {
    return (React.createElement(StyledContainer, null,
        React.createElement("span", { className: TypographyStyles.smallRegular }, option.label),
        React.createElement("img", { src: option.img, alt: `${option.value}-option` })));
};
export const ColorBrewerSelect = () => {
    const selectedBrew = useSelector(selectedColorBrewState);
    const handleChange = (e, value) => {
        setColorBrewOption(value);
    };
    return (React.createElement(Select, { containerClassName: GridContentStyles.max, value: selectedBrew, placeholder: "Select Color Palette", onChange: handleChange, renderValue: (value) => (React.createElement(StyledImg, { src: value.img, alt: `${value.value}` })), label: "Colour Palette", size: "small" }, ColorBrewerOptions.map((option) => (React.createElement(SelectOption, { value: option, key: option.value },
        React.createElement(ColorBrewerDisplay, { option: option }))))));
};
