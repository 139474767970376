var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ReactComponent as CloseIcon } from '@Icons/sm/x.svg';
import { Skeleton } from '@mui/material';
import { IconButton, MenuItem, SearchInput } from '@orbica/component-sdk';
import _debounce from 'lodash/debounce';
import _isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { updateGeocoderLocation } from '@Data/mapToolbar/Api';
import { geocoderLocationState } from '@Data/mapToolbar/Reducer';
const AddressSearchContainer = styled('div') `
    grid-column: 1/-1;
`;
const SkeletonContainer = styled('div') `
    margin-top: 5px;
`;
export const AddressSearchSelector = () => {
    const [filter, setFilter] = useState(null);
    const [filterKey, setFilterKey] = useState(Math.random().toString(16));
    const [filterResponses, setFilterResponses] = useState(null);
    const [searchInputLoading, setSearchInputLoading] = useState(false);
    const geocoderLocation = useSelector(geocoderLocationState);
    const handleClearFilter = () => {
        setFilter('');
        setFilterKey(Math.random().toString(16));
        updateGeocoderLocation(null);
        setFilterResponses(null);
    };
    const handleFilterChange = _debounce((e) => setFilter(e.target.value), 500);
    const orderNZfirst = (json) => {
        return json.features.sort((a, b) => {
            const aInNZ = a.properties.display_name.includes('New Zealand');
            const bInNZ = b.properties.display_name.includes('New Zealand');
            if (aInNZ && !bInNZ) {
                return -1;
            }
            else if (!aInNZ && bInNZ) {
                return 1;
            }
            return 0;
        });
    };
    const fetchAddress = (query) => __awaiter(void 0, void 0, void 0, function* () {
        setSearchInputLoading(true);
        const features = [];
        try {
            const request = `https://nominatim.openstreetmap.org/search?q=${query}&format=geojson&polygon_geojson=1&addressdetails=1`;
            const response = yield fetch(request);
            const geojson = yield response.json();
            const reorderedFeatures = orderNZfirst(geojson);
            for (const feature of reorderedFeatures) {
                const center = [
                    feature.bbox[0] + (feature.bbox[2] - feature.bbox[0]) / 2,
                    feature.bbox[1] + (feature.bbox[3] - feature.bbox[1]) / 2,
                ];
                const point = {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: center,
                    },
                    place_name: feature.properties.display_name,
                    properties: feature.properties,
                    text: feature.properties.display_name,
                    place_type: ['place'],
                    center,
                };
                features.push(point);
            }
        }
        catch (e) {
            console.error(`Failed to forwardGeocode with error: ${e}`);
        }
        return features;
    });
    useEffect(() => {
        if (!_isNil(filter) && filter !== '') {
            fetchAddress(filter).then((result) => {
                setSearchInputLoading(false);
                setFilterResponses(result);
            });
        }
    }, [filter]);
    const handleAddressSearchClick = (event, o) => {
        updateGeocoderLocation(o);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(AddressSearchContainer, null,
            React.createElement(SearchInput, { key: filterKey, hideButton: true, placeholder: "Search address", onChange: handleFilterChange, endAdornment: React.createElement(IconButton, { size: "small", IconProps: { icon: React.createElement(CloseIcon, null) }, disablePadding: true, style: {
                        display: filter === '' ? 'none' : 'flex',
                    }, onClick: handleClearFilter }) }),
            React.createElement(SkeletonContainer, null, _isNil(filterResponses) ? (searchInputLoading ? (React.createElement(React.Fragment, null,
                React.createElement(Skeleton, { animation: "wave" }),
                React.createElement(Skeleton, { animation: "wave" }),
                React.createElement(Skeleton, { animation: "wave" }))) : null) : (filterResponses.map((o) => (React.createElement(MenuItem, { onClick: (event) => handleAddressSearchClick(event, o), selected: o.place_name ===
                    (geocoderLocation === null || geocoderLocation === void 0 ? void 0 : geocoderLocation.place_name), key: `o.place_name-${Math.random().toString(5)}`, value: o.place_name }, o.place_name))))))));
};
