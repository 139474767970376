import { HttpResponse, http } from 'msw';
import { BASE_API_URL, WEB_API_URL } from '@Services/redux';
function getEndpoint(endpoint, type = 'base') {
    const url = type === 'base' ? BASE_API_URL : WEB_API_URL;
    return `${url}/${endpoint}`;
}
function getQueryParams(request, param, multiple = false) {
    const url = new URL(request.url);
    return multiple
        ? url.searchParams.getAll(param)
        : url.searchParams.get(param);
}
export const handlers = [
    http.get(getEndpoint('getImageryProviders'), () => {
        return HttpResponse.json([
            { id: 'ed798225-0c63-4925-9693-68f80d193ad6', name: 'sentinel2a' },
        ]);
    }),
    http.get(getEndpoint('indices/domains', 'webapi'), ({ request }) => {
        return HttpResponse.json([
            'vegetation',
            'water',
            'burn',
            'soil',
            'urban',
            'snow',
        ]);
    }),
    http.get(getEndpoint('indices/get', 'webapi'), ({ request }) => {
        const domainName = getQueryParams(request, 'domain');
        if (domainName === 'vegetation') {
            return HttpResponse.json({
                AFRI1600: {
                    application_domain: 'vegetation',
                    bands: ['N', 'S1'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-11-17',
                    formula: '(N - 0.66 * S1) / (N + 0.66 * S1)',
                    long_name: 'Aerosol Free Vegetation Index (1600 nm)',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                    ],
                    reference: 'https://doi.org/10.1016/S0034-4257(01)00190-0',
                    short_name: 'AFRI1600',
                },
                AFRI2100: {
                    application_domain: 'vegetation',
                    bands: ['N', 'S2'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-11-17',
                    formula: '(N - 0.5 * S2) / (N + 0.5 * S2)',
                    long_name: 'Aerosol Free Vegetation Index (2100 nm)',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                    ],
                    reference: 'https://doi.org/10.1016/S0034-4257(01)00190-0',
                    short_name: 'AFRI2100',
                },
                ARI: {
                    application_domain: 'vegetation',
                    bands: ['G', 'RE1'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-09-20',
                    formula: '(1 / G) - (1 / RE1)',
                    long_name: 'Anthocyanin Reflectance Index',
                    platforms: ['Sentinel-2'],
                    reference: 'https://doi.org/10.1562/0031-8655(2001)074%3C0038:OPANEO%3E2.0.CO;2',
                    short_name: 'ARI',
                },
                ARI2: {
                    application_domain: 'vegetation',
                    bands: ['N', 'G', 'RE1'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-08',
                    formula: 'N * ((1 / G) - (1 / RE1))',
                    long_name: 'Anthocyanin Reflectance Index 2',
                    platforms: ['Sentinel-2'],
                    reference: 'https://doi.org/10.1562/0031-8655(2001)074%3C0038:OPANEO%3E2.0.CO;2',
                    short_name: 'ARI2',
                },
                AVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-08',
                    formula: '(N * (1.0 - R) * (N - R)) ** (1/3)',
                    long_name: 'Advanced Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.465.8749&rep=rep1&type=pdf',
                    short_name: 'AVI',
                },
                BCC: {
                    application_domain: 'vegetation',
                    bands: ['B', 'R', 'G'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-01-17',
                    formula: 'B / (R + G + B)',
                    long_name: 'Blue Chromatic Coordinate',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/0034-4257(87)90088-5',
                    short_name: 'BCC',
                },
                BNDVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'B'],
                    contributor: 'https://github.com/MATRIX4284',
                    date_of_addition: '2021-04-07',
                    formula: '(N - B)/(N + B)',
                    long_name: 'Blue Normalized Difference Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/S1672-6308(07)60027-4',
                    short_name: 'BNDVI',
                },
                CIG: {
                    application_domain: 'vegetation',
                    bands: ['N', 'G'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-04-07',
                    formula: '(N / G) - 1.0',
                    long_name: 'Chlorophyll Index Green',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1078/0176-1617-00887',
                    short_name: 'CIG',
                },
                CIRE: {
                    application_domain: 'vegetation',
                    bands: ['N', 'RE1'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-13',
                    formula: '(N / RE1) - 1',
                    long_name: 'Chlorophyll Index Red Edge',
                    platforms: ['Sentinel-2'],
                    reference: 'https://doi.org/10.1078/0176-1617-00887',
                    short_name: 'CIRE',
                },
                CVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R', 'G'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-04-07',
                    formula: '(N * R) / (G ** 2.0)',
                    long_name: 'Chlorophyll Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1007/s11119-010-9204-3',
                    short_name: 'CVI',
                },
                DSI: {
                    application_domain: 'vegetation',
                    bands: ['S1', 'N'],
                    contributor: 'https://github.com/remi-braun',
                    date_of_addition: '2022-10-26',
                    formula: 'S1/N',
                    long_name: 'Drought Stress Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                    ],
                    reference: 'https://www.asprs.org/wp-content/uploads/pers/1999journal/apr/1999_apr_495-501.pdf',
                    short_name: 'DSI',
                },
                DSWI1: {
                    application_domain: 'vegetation',
                    bands: ['N', 'S1'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-10-29',
                    formula: 'N/S1',
                    long_name: 'Disease-Water Stress Index 1',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                    ],
                    reference: 'https://doi.org/10.1080/01431160310001618031',
                    short_name: 'DSWI1',
                },
                DSWI2: {
                    application_domain: 'vegetation',
                    bands: ['S1', 'G'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-10-29',
                    formula: 'S1/G',
                    long_name: 'Disease-Water Stress Index 2',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                    ],
                    reference: 'https://doi.org/10.1080/01431160310001618031',
                    short_name: 'DSWI2',
                },
                DSWI3: {
                    application_domain: 'vegetation',
                    bands: ['S1', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-10-29',
                    formula: 'S1/R',
                    long_name: 'Disease-Water Stress Index 3',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                    ],
                    reference: 'https://doi.org/10.1080/01431160310001618031',
                    short_name: 'DSWI3',
                },
                DSWI4: {
                    application_domain: 'vegetation',
                    bands: ['G', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-10-29',
                    formula: 'G/R',
                    long_name: 'Disease-Water Stress Index 4',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1080/01431160310001618031',
                    short_name: 'DSWI4',
                },
                DSWI5: {
                    application_domain: 'vegetation',
                    bands: ['N', 'G', 'S1', 'R'],
                    contributor: 'https://github.com/remi-braun',
                    date_of_addition: '2022-10-26',
                    formula: '(N + G)/(S1 + R)',
                    long_name: 'Disease-Water Stress Index 5',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                    ],
                    reference: 'https://doi.org/10.1080/01431160310001618031',
                    short_name: 'DSWI5',
                },
                DVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-14',
                    formula: 'N - R',
                    long_name: 'Difference Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/0034-4257(94)00114-3',
                    short_name: 'DVI',
                },
                ExG: {
                    application_domain: 'vegetation',
                    bands: ['G', 'R', 'B'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-14',
                    formula: '2 * G - R - B',
                    long_name: 'Excess Green Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.13031/2013.27838',
                    short_name: 'ExG',
                },
                ExGR: {
                    application_domain: 'vegetation',
                    bands: ['G', 'R', 'B'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-08',
                    formula: '(2.0 * G - R - B) - (1.3 * R - G)',
                    long_name: 'ExG - ExR Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/j.compag.2008.03.009',
                    short_name: 'ExGR',
                },
                ExR: {
                    application_domain: 'vegetation',
                    bands: ['R', 'G'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-08',
                    formula: '1.3 * R - G',
                    long_name: 'Excess Red Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1117/12.336896',
                    short_name: 'ExR',
                },
                FCVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R', 'G', 'B'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-01-20',
                    formula: 'N - ((R + G + B)/3.0)',
                    long_name: 'Fluorescence Correction Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/j.rse.2020.111676',
                    short_name: 'FCVI',
                },
                GARI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'G', 'B', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-04-07',
                    formula: '(N - (G - (B - R))) / (N - (G + (B - R)))',
                    long_name: 'Green Atmospherically Resistant Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/S0034-4257(96)00072-7',
                    short_name: 'GARI',
                },
                GBNDVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'G', 'B'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-04-07',
                    formula: '(N - (G + B))/(N + (G + B))',
                    long_name: 'Green-Blue Normalized Difference Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/S1672-6308(07)60027-4',
                    short_name: 'GBNDVI',
                },
                GCC: {
                    application_domain: 'vegetation',
                    bands: ['G', 'R', 'B'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-01-17',
                    formula: 'G / (R + G + B)',
                    long_name: 'Green Chromatic Coordinate',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/0034-4257(87)90088-5',
                    short_name: 'GCC',
                },
                GEMI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-04-07',
                    formula: '((2.0*((N ** 2.0)-(R ** 2.0)) + 1.5*N + 0.5*R)/(N + R + 0.5))*(1.0 - 0.25*((2.0 * ((N ** 2.0) - (R ** 2)) + 1.5 * N + 0.5 * R)/(N + R + 0.5)))-((R - 0.125)/(1 - R))',
                    long_name: 'Global Environment Monitoring Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'http://dx.doi.org/10.1007/bf00031911',
                    short_name: 'GEMI',
                },
                GLI: {
                    application_domain: 'vegetation',
                    bands: ['G', 'R', 'B'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-04-07',
                    formula: '(2.0 * G - R - B) / (2.0 * G + R + B)',
                    long_name: 'Green Leaf Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'http://dx.doi.org/10.1080/10106040108542184',
                    short_name: 'GLI',
                },
                GNDVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'G'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-04-07',
                    formula: '(N - G)/(N + G)',
                    long_name: 'Green Normalized Difference Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/S0034-4257(96)00072-7',
                    short_name: 'GNDVI',
                },
                GOSAVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'G'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-08',
                    formula: '(N - G) / (N + G + 0.16)',
                    long_name: 'Green Optimized Soil Adjusted Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.2134/agronj2004.0314',
                    short_name: 'GOSAVI',
                },
                GRNDVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'G', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-04-07',
                    formula: '(N - (G + R))/(N + (G + R))',
                    long_name: 'Green-Red Normalized Difference Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/S1672-6308(07)60027-4',
                    short_name: 'GRNDVI',
                },
                GRVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'G'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-08',
                    formula: 'N/G',
                    long_name: 'Green Ratio Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.2134/agronj2004.0314',
                    short_name: 'GRVI',
                },
                GVMI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'S2'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-04-07',
                    formula: '((N + 0.1) - (S2 + 0.02)) / ((N + 0.1) + (S2 + 0.02))',
                    long_name: 'Global Vegetation Moisture Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                    ],
                    reference: 'https://doi.org/10.1016/S0034-4257(02)00037-8',
                    short_name: 'GVMI',
                },
                IKAW: {
                    application_domain: 'vegetation',
                    bands: ['R', 'B'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-08',
                    formula: '(R - B)/(R + B)',
                    long_name: 'Kawashima Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1006/anbo.1997.0544',
                    short_name: 'IKAW',
                },
                IPVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-08',
                    formula: 'N/(N + R)',
                    long_name: 'Infrared Percentage Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/0034-4257(90)90085-Z',
                    short_name: 'IPVI',
                },
                MCARI: {
                    application_domain: 'vegetation',
                    bands: ['RE1', 'R', 'G'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-13',
                    formula: '((RE1 - R) - 0.2 * (RE1 - G)) * (RE1 / R)',
                    long_name: 'Modified Chlorophyll Absorption in Reflectance Index',
                    platforms: ['Sentinel-2'],
                    reference: 'http://dx.doi.org/10.1016/S0034-4257(00)00113-9',
                    short_name: 'MCARI',
                },
                MCARI1: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R', 'G'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-14',
                    formula: '1.2 * (2.5 * (N - R) - 1.3 * (N - G))',
                    long_name: 'Modified Chlorophyll Absorption in Reflectance Index 1',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/j.rse.2003.12.013',
                    short_name: 'MCARI1',
                },
                MCARI2: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R', 'G'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-14',
                    formula: '(1.5 * (2.5 * (N - R) - 1.3 * (N - G))) / ((((2.0 * N + 1) ** 2) - (6.0 * N - 5 * (R ** 0.5)) - 0.5) ** 0.5)',
                    long_name: 'Modified Chlorophyll Absorption in Reflectance Index 2',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/j.rse.2003.12.013',
                    short_name: 'MCARI2',
                },
                MCARIOSAVI: {
                    application_domain: 'vegetation',
                    bands: ['RE1', 'R', 'G', 'N'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-11-06',
                    formula: '(((RE1 - R) - 0.2 * (RE1 - G)) * (RE1 / R)) / (1.16 * (N - R) / (N + R + 0.16))',
                    long_name: 'MCARI/OSAVI Ratio',
                    platforms: ['Sentinel-2'],
                    reference: 'https://doi.org/10.1016/S0034-4257(00)00113-9',
                    short_name: 'MCARIOSAVI',
                },
                MGRVI: {
                    application_domain: 'vegetation',
                    bands: ['G', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-14',
                    formula: '(G ** 2.0 - R ** 2.0) / (G ** 2.0 + R ** 2.0)',
                    long_name: 'Modified Green Red Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/j.jag.2015.02.012',
                    short_name: 'MGRVI',
                },
                MNDVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'S2'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-04-07',
                    formula: '(N - S2)/(N + S2)',
                    long_name: 'Modified Normalized Difference Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                    ],
                    reference: 'https://doi.org/10.1080/014311697216810',
                    short_name: 'MNDVI',
                },
                MRBVI: {
                    application_domain: 'vegetation',
                    bands: ['R', 'B'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-08',
                    formula: '(R ** 2.0 - B ** 2.0)/(R ** 2.0 + B ** 2.0)',
                    long_name: 'Modified Red Blue Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.3390/s20185055',
                    short_name: 'MRBVI',
                },
                MSAVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-13',
                    formula: '0.5 * (2.0 * N + 1 - (((2 * N + 1) ** 2) - 8 * (N - R)) ** 0.5)',
                    long_name: 'Modified Soil-Adjusted Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/0034-4257(94)90134-1',
                    short_name: 'MSAVI',
                },
                MSI: {
                    application_domain: 'vegetation',
                    bands: ['S1', 'N'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-08',
                    formula: 'S1/N',
                    long_name: 'Moisture Stress Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                    ],
                    reference: 'https://doi.org/10.1016/0034-4257(89)90046-1',
                    short_name: 'MSI',
                },
                MSR: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-14',
                    formula: '(N / R - 1) / ((N / R + 1) ** 0.5)',
                    long_name: 'Modified Simple Ratio',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1080/07038992.1996.10855178',
                    short_name: 'MSR',
                },
                MTVI1: {
                    application_domain: 'vegetation',
                    bands: ['N', 'G', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-14',
                    formula: '1.2 * (1.2 * (N - G) - 2.5 * (R - G))',
                    long_name: 'Modified Triangular Vegetation Index 1',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/j.rse.2003.12.013',
                    short_name: 'MTVI1',
                },
                MTVI2: {
                    application_domain: 'vegetation',
                    bands: ['N', 'G', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-14',
                    formula: '(1.5 * (1.2 * (N - G) - 2.5 * (R - G))) / ((((2.0 * N + 1) ** 2) - (6.0 * N - 5 * (R ** 0.5)) - 0.5) ** 0.5)',
                    long_name: 'Modified Triangular Vegetation Index 2',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/j.rse.2003.12.013',
                    short_name: 'MTVI2',
                },
                NDDI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R', 'G'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-04-07',
                    formula: '(((N - R)/(N + R)) - ((G - N)/(G + N)))/(((N - R)/(N + R)) + ((G - N)/(G + N)))',
                    long_name: 'Normalized Difference Drought Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1029/2006GL029127',
                    short_name: 'NDDI',
                },
                NDII: {
                    application_domain: 'vegetation',
                    bands: ['N', 'S1'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-01-20',
                    formula: '(N - S1)/(N + S1)',
                    long_name: 'Normalized Difference Infrared Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                    ],
                    reference: 'https://www.asprs.org/wp-content/uploads/pers/1983journal/jan/1983_jan_77-83.pdf',
                    short_name: 'NDII',
                },
                NDMI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'S1'],
                    contributor: 'https://github.com/bpurinton',
                    date_of_addition: '2021-12-01',
                    formula: '(N - S1)/(N + S1)',
                    long_name: 'Normalized Difference Moisture Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                    ],
                    reference: 'https://doi.org/10.1016/S0034-4257(01)00318-2',
                    short_name: 'NDMI',
                },
                NDREI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'RE1'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-13',
                    formula: '(N - RE1) / (N + RE1)',
                    long_name: 'Normalized Difference Red Edge Index',
                    platforms: ['Sentinel-2'],
                    reference: 'https://doi.org/10.1016/1011-1344(93)06963-4',
                    short_name: 'NDREI',
                },
                NDVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-04-07',
                    formula: '(N - R)/(N + R)',
                    long_name: 'Normalized Difference Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://ntrs.nasa.gov/citations/19740022614',
                    short_name: 'NDVI',
                },
                NDYI: {
                    application_domain: 'vegetation',
                    bands: ['G', 'B'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-09-18',
                    formula: '(G - B) / (G + B)',
                    long_name: 'Normalized Difference Yellowness Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/j.rse.2016.06.016',
                    short_name: 'NDYI',
                },
                NGRDI: {
                    application_domain: 'vegetation',
                    bands: ['G', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-04-07',
                    formula: '(G - R) / (G + R)',
                    long_name: 'Normalized Green Red Difference Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/0034-4257(79)90013-0',
                    short_name: 'NGRDI',
                },
                NIRv: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-11-16',
                    formula: '((N - R) / (N + R)) * N',
                    long_name: 'Near-Infrared Reflectance of Vegetation',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1126/sciadv.1602244',
                    short_name: 'NIRv',
                },
                NLI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-11',
                    formula: '((N ** 2) - R)/((N ** 2) + R)',
                    long_name: 'Non-Linear Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1080/02757259409532252',
                    short_name: 'NLI',
                },
                NMDI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'S1', 'S2'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-11',
                    formula: '(N - (S1 - S2))/(N + (S1 - S2))',
                    long_name: 'Normalized Multi-band Drought Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                    ],
                    reference: 'https://doi.org/10.1029/2007GL031021',
                    short_name: 'NMDI',
                },
                NRFIg: {
                    application_domain: 'vegetation',
                    bands: ['G', 'S2'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-09-18',
                    formula: '(G - S2) / (G + S2)',
                    long_name: 'Normalized Rapeseed Flowering Index Green',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                    ],
                    reference: 'https://doi.org/10.3390/rs13010105',
                    short_name: 'NRFIg',
                },
                NRFIr: {
                    application_domain: 'vegetation',
                    bands: ['R', 'S2'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-09-18',
                    formula: '(R - S2) / (R + S2)',
                    long_name: 'Normalized Rapeseed Flowering Index Red',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                    ],
                    reference: 'https://doi.org/10.3390/rs13010105',
                    short_name: 'NRFIr',
                },
                NormG: {
                    application_domain: 'vegetation',
                    bands: ['G', 'N', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-08',
                    formula: 'G/(N + G + R)',
                    long_name: 'Normalized Green',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.2134/agronj2004.0314',
                    short_name: 'NormG',
                },
                NormNIR: {
                    application_domain: 'vegetation',
                    bands: ['N', 'G', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-08',
                    formula: 'N/(N + G + R)',
                    long_name: 'Normalized NIR',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.2134/agronj2004.0314',
                    short_name: 'NormNIR',
                },
                NormR: {
                    application_domain: 'vegetation',
                    bands: ['R', 'N', 'G'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-08',
                    formula: 'R/(N + G + R)',
                    long_name: 'Normalized Red',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.2134/agronj2004.0314',
                    short_name: 'NormR',
                },
                OSAVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-11',
                    formula: '(N - R) / (N + R + 0.16)',
                    long_name: 'Optimized Soil-Adjusted Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/0034-4257(95)00186-7',
                    short_name: 'OSAVI',
                },
                RCC: {
                    application_domain: 'vegetation',
                    bands: ['R', 'G', 'B'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-01-17',
                    formula: 'R / (R + G + B)',
                    long_name: 'Red Chromatic Coordinate',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/0034-4257(87)90088-5',
                    short_name: 'RCC',
                },
                RDVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-14',
                    formula: '(N - R) / ((N + R) ** 0.5)',
                    long_name: 'Renormalized Difference Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/0034-4257(94)00114-3',
                    short_name: 'RDVI',
                },
                RGBVI: {
                    application_domain: 'vegetation',
                    bands: ['G', 'B', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-08',
                    formula: '(G ** 2.0 - B * R)/(G ** 2.0 + B * R)',
                    long_name: 'Red Green Blue Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/j.jag.2015.02.012',
                    short_name: 'RGBVI',
                },
                RGRI: {
                    application_domain: 'vegetation',
                    bands: ['R', 'G'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-08',
                    formula: 'R/G',
                    long_name: 'Red-Green Ratio Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/j.jag.2014.03.018',
                    short_name: 'RGRI',
                },
                RI: {
                    application_domain: 'vegetation',
                    bands: ['R', 'G'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-09',
                    formula: '(R - G)/(R + G)',
                    long_name: 'Redness Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://www.documentation.ird.fr/hor/fdi:34390',
                    short_name: 'RI',
                },
                SI: {
                    application_domain: 'vegetation',
                    bands: ['B', 'G', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-08',
                    formula: '((1.0 - B) * (1.0 - G) * (1.0 - R)) ** (1/3)',
                    long_name: 'Shadow Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.465.8749&rep=rep1&type=pdf',
                    short_name: 'SI',
                },
                SIPI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'A', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-09-17',
                    formula: '(N - A) / (N - R)',
                    long_name: 'Structure Insensitive Pigment Index',
                    platforms: ['Sentinel-2', 'Landsat-OLI'],
                    reference: 'https://eurekamag.com/research/009/395/009395053.php',
                    short_name: 'SIPI',
                },
                SLAVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R', 'S2'],
                    contributor: 'https://github.com/geoSanjeeb',
                    date_of_addition: '2023-07-03',
                    formula: 'N/(R + S2)',
                    long_name: 'Specific Leaf Area Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                    ],
                    reference: 'https://www.asprs.org/wp-content/uploads/pers/2000journal/february/2000_feb_183-191.pdf',
                    short_name: 'SLAVI',
                },
                SR: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-08',
                    formula: 'N/R',
                    long_name: 'Simple Ratio',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.2307/1936256',
                    short_name: 'SR',
                },
                SR2: {
                    application_domain: 'vegetation',
                    bands: ['N', 'G'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-09',
                    formula: 'N/G',
                    long_name: 'Simple Ratio (800 and 550 nm)',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1080/01431169308904370',
                    short_name: 'SR2',
                },
                SR3: {
                    application_domain: 'vegetation',
                    bands: ['N2', 'G', 'RE1'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-09',
                    formula: 'N2/(G * RE1)',
                    long_name: 'Simple Ratio (860, 550 and 708 nm)',
                    platforms: ['Sentinel-2'],
                    reference: 'https://doi.org/10.1016/S0034-4257(98)00046-7',
                    short_name: 'SR3',
                },
                SeLI: {
                    application_domain: 'vegetation',
                    bands: ['N2', 'RE1'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-04-08',
                    formula: '(N2 - RE1) / (N2 + RE1)',
                    long_name: 'Sentinel-2 LAI Green Index',
                    platforms: ['Sentinel-2'],
                    reference: 'https://doi.org/10.3390/s19040904',
                    short_name: 'SeLI',
                },
                TCARI: {
                    application_domain: 'vegetation',
                    bands: ['RE1', 'R', 'G'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-13',
                    formula: '3 * ((RE1 - R) - 0.2 * (RE1 - G) * (RE1 / R))',
                    long_name: 'Transformed Chlorophyll Absorption in Reflectance Index',
                    platforms: ['Sentinel-2'],
                    reference: 'https://doi.org/10.1016/S0034-4257(02)00018-4',
                    short_name: 'TCARI',
                },
                TCARIOSAVI: {
                    application_domain: 'vegetation',
                    bands: ['RE1', 'R', 'G', 'N'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-11-06',
                    formula: '(3 * ((RE1 - R) - 0.2 * (RE1 - G) * (RE1 / R))) / (1.16 * (N - R) / (N + R + 0.16))',
                    long_name: 'TCARI/OSAVI Ratio',
                    platforms: ['Sentinel-2'],
                    reference: 'https://doi.org/10.1016/S0034-4257(02)00018-4',
                    short_name: 'TCARIOSAVI',
                },
                TCI: {
                    application_domain: 'vegetation',
                    bands: ['RE1', 'G', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-14',
                    formula: '1.2 * (RE1 - G) - 1.5 * (R - G) * (RE1 / R) ** 0.5',
                    long_name: 'Triangular Chlorophyll Index',
                    platforms: ['Sentinel-2'],
                    reference: 'http://dx.doi.org/10.1109/TGRS.2007.904836',
                    short_name: 'TCI',
                },
                TDVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-09',
                    formula: '1.5 * ((N - R)/((N ** 2.0 + R + 0.5) ** 0.5))',
                    long_name: 'Transformed Difference Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1109/IGARSS.2002.1026867',
                    short_name: 'TDVI',
                },
                TGI: {
                    application_domain: 'vegetation',
                    bands: ['R', 'G', 'B'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-14',
                    formula: '- 0.5 * (190 * (R - G) - 120 * (R - B))',
                    long_name: 'Triangular Greenness Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'http://dx.doi.org/10.1016/j.jag.2012.07.020',
                    short_name: 'TGI',
                },
                TVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2022-04-08',
                    formula: '(((N - R)/(N + R)) + 0.5) ** 0.5',
                    long_name: 'Transformed Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://ntrs.nasa.gov/citations/19740022614',
                    short_name: 'TVI',
                },
                TriVI: {
                    application_domain: 'vegetation',
                    bands: ['N', 'G', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-05-14',
                    formula: '0.5 * (120 * (N - G) - 200 * (R - G))',
                    long_name: 'Triangular Vegetation Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'http://dx.doi.org/10.1016/S0034-4257(00)00197-8',
                    short_name: 'TriVI',
                },
                VARI: {
                    application_domain: 'vegetation',
                    bands: ['G', 'R', 'B'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-04-07',
                    formula: '(G - R) / (G + R - B)',
                    long_name: 'Visible Atmospherically Resistant Index',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/S0034-4257(01)00289-9',
                    short_name: 'VARI',
                },
                VARI700: {
                    application_domain: 'vegetation',
                    bands: ['RE1', 'R', 'B'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-09-20',
                    formula: '(RE1 - 1.7 * R + 0.7 * B) / (RE1 + 1.3 * R - 1.3 * B)',
                    long_name: 'Visible Atmospherically Resistant Index (700 nm)',
                    platforms: ['Sentinel-2'],
                    reference: 'https://doi.org/10.1016/S0034-4257(01)00289-9',
                    short_name: 'VARI700',
                },
                VI700: {
                    application_domain: 'vegetation',
                    bands: ['RE1', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-09-20',
                    formula: '(RE1 - R) / (RE1 + R)',
                    long_name: 'Vegetation Index (700 nm)',
                    platforms: ['Sentinel-2'],
                    reference: 'https://doi.org/10.1016/S0034-4257(01)00289-9',
                    short_name: 'VI700',
                },
                VIG: {
                    application_domain: 'vegetation',
                    bands: ['G', 'R'],
                    contributor: 'https://github.com/davemlz',
                    date_of_addition: '2021-09-20',
                    formula: '(G - R) / (G + R)',
                    long_name: 'Vegetation Index Green',
                    platforms: [
                        'Sentinel-2',
                        'Landsat-OLI',
                        'Landsat-TM',
                        'Landsat-ETM+',
                        'MODIS',
                        'Planet-Fusion',
                    ],
                    reference: 'https://doi.org/10.1016/S0034-4257(01)00289-9',
                    short_name: 'VIG',
                },
            });
        }
        else {
            return null;
        }
    }),
    http.get(getEndpoint('get_jobs'), () => {
        const output = {
            job: [
                {
                    id: 'b6acb3bc-2750-4bdb-9588-b83545c069bf',
                    name: 'Sample Job',
                    start_date: null,
                    end_date: null,
                    state: 'initializing',
                    user_id: '123e4567-e89b-12d3-a456-426614174000',
                    imagery: 'sample_imagery_data',
                    is_active: false,
                    created_at: '2024-01-11T23:29:31.980729+00:00',
                    updated_at: '2024-01-11T23:29:31.980729+00:00',
                    cloud_coverage: 23.5,
                    tasks: [
                        {
                            task_runs_aggregate: {
                                aggregate: {
                                    next_date_run: {
                                        date: '2024-02-08T23:29:32+00:00',
                                    },
                                },
                            },
                        },
                    ],
                },
            ],
        };
        return HttpResponse.json(output);
    }),
    http.get(getEndpoint('get_job/*'), ({ request }) => {
        const output = {
            job: [
                {
                    id: 'b6acb3bc-2750-4bdb-9588-b83545c069bf',
                    name: 'Sample Job',
                    start_date: null,
                    state: 'initializing',
                    user_id: '123e4567-e89b-12d3-a456-426614174000',
                    updated_at: '2024-01-11T23:29:31.980729+00:00',
                    imagery: 'vegetation',
                    is_active: false,
                    full_extent: {
                        type: 'MultiPolygon',
                        crs: {
                            type: 'name',
                            properties: {
                                name: 'urn:ogc:def:crs:EPSG::4326',
                            },
                        },
                        coordinates: [
                            [
                                [
                                    [172.639847, -43.52565],
                                    [172.640245, -43.529487],
                                    [172.643143, -43.528478],
                                    [172.641748, -43.525009],
                                    [172.639847, -43.52565],
                                ],
                            ],
                            [
                                [
                                    [172.655897, -43.540206],
                                    [172.660202, -43.5416],
                                    [172.658807, -43.537305],
                                    [172.654502, -43.53591],
                                    [172.655897, -43.540206],
                                ],
                            ],
                            [
                                [
                                    [172.617981, -43.509437],
                                    [172.620879, -43.507042],
                                    [172.623777, -43.509437],
                                    [172.620879, -43.511831],
                                    [172.617981, -43.509437],
                                ],
                            ],
                            [
                                [
                                    [172.634031, -43.493881],
                                    [172.636929, -43.492486],
                                    [172.639827, -43.493881],
                                    [172.636929, -43.495275],
                                    [172.634031, -43.493881],
                                ],
                            ],
                        ],
                    },
                    full_geojson: {
                        features: [
                            {
                                geometry: {
                                    coordinates: [
                                        [
                                            [
                                                172.6179014904908,
                                                -43.534013769591134,
                                            ],
                                            [
                                                172.61828957291664,
                                                -43.53381057462073,
                                            ],
                                            [
                                                172.6190010573676,
                                                -43.53374805295268,
                                            ],
                                            [
                                                172.6196263012783,
                                                -43.5340762909837,
                                            ],
                                            [
                                                172.619798782357,
                                                -43.53456082957409,
                                            ],
                                            [
                                                172.61954006073807,
                                                -43.53496721377566,
                                            ],
                                            [
                                                172.6186776553443,
                                                -43.53523292504356,
                                            ],
                                            [
                                                172.6179014904908,
                                                -43.534873433049256,
                                            ],
                                            [
                                                172.6175996486034,
                                                -43.53435763644574,
                                            ],
                                            [
                                                172.6179014904908,
                                                -43.534013769591134,
                                            ],
                                        ],
                                    ],
                                    type: 'Polygon',
                                },
                                id: '2569caaa18efcebb48505eda0f407f9c',
                                properties: {
                                    name: 'christchurch park',
                                },
                                type: 'Feature',
                            },
                            {
                                geometry: {
                                    coordinates: [
                                        [
                                            [
                                                172.62007906411026,
                                                -43.53584249352575,
                                            ],
                                            [
                                                172.62229975799806,
                                                -43.534670240974194,
                                            ],
                                            [
                                                172.6244988917516,
                                                -43.53521729500065,
                                            ],
                                            [
                                                172.62115707085104,
                                                -43.53717101886975,
                                            ],
                                            [
                                                172.62007906411026,
                                                -43.53584249352575,
                                            ],
                                        ],
                                    ],
                                    type: 'Polygon',
                                },
                                id: 'f0052d2c40ee9487ece2527310f85bbb',
                                properties: {
                                    name: 'hospital grounds',
                                },
                                type: 'Feature',
                            },
                            {
                                geometry: {
                                    coordinates: [
                                        [
                                            [
                                                172.61311514055592,
                                                -43.53266954398628,
                                            ],
                                            [
                                                172.6160473188947,
                                                -43.53266954398628,
                                            ],
                                            [
                                                172.6175996486034,
                                                -43.53316972445587,
                                            ],
                                            [
                                                172.6169528445568,
                                                -43.53423259418039,
                                            ],
                                            [
                                                172.6164138411864,
                                                -43.53442015748053,
                                            ],
                                            [
                                                172.61309358042,
                                                -43.53340418262339,
                                            ],
                                            [
                                                172.61311514055592,
                                                -43.53266954398628,
                                            ],
                                        ],
                                    ],
                                    type: 'Polygon',
                                },
                                id: 'be3724ea99000b7912d920350e467a5d',
                                properties: {
                                    name: 'north 1',
                                },
                                type: 'Feature',
                            },
                            {
                                geometry: {
                                    coordinates: [
                                        [
                                            [
                                                172.61249269787612,
                                                -43.53251212667367,
                                            ],
                                            [
                                                172.61249269787612,
                                                -43.53907275787889,
                                            ],
                                            [
                                                172.62452487525752,
                                                -43.53907275787889,
                                            ],
                                            [
                                                172.62452487525752,
                                                -43.53251212667367,
                                            ],
                                            [
                                                172.61249269787612,
                                                -43.53251212667367,
                                            ],
                                        ],
                                    ],
                                    type: 'Polygon',
                                },
                                id: '89d5db23663e1bab83d3c793bcbffed8',
                                properties: {
                                    name: 'north 2',
                                },
                                type: 'Feature',
                            },
                        ],
                        type: 'FeatureCollection',
                    },
                    end_date: null,
                    description: 'This is a sample job description.',
                    created_at: '2024-01-11T23:29:31.980729+00:00',
                    cloud_coverage: 23.5,
                    job_spectral_indices: [
                        {
                            id: 'afeff2d3-1d2c-4b37-a875-e4aff4a21179',
                            index_key: 'NDVI',
                        },
                    ],
                    platform: {
                        id: 'cf47d316-053f-43a6-a2cc-82019960c6cb',
                        awsm_name: 'sentinel2a',
                        name: 'sentinel2a',
                    },
                    baseline: null,
                    tasks: [
                        {
                            task_runs_aggregate: {
                                aggregate: {
                                    next_date_run: {
                                        date: '2024-01-22T23:29:32+00:00',
                                    },
                                },
                            },
                        },
                    ],
                },
            ],
        };
        return HttpResponse.json(output);
    }),
    http.get(getEndpoint('get_tasks/*'), ({ request }) => {
        const response = {
            task: [
                {
                    id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    name: 'christchurch park',
                    aoi_geom: {
                        type: 'Polygon',
                        crs: {
                            type: 'name',
                            properties: {
                                name: 'urn:ogc:def:crs:EPSG::4326',
                            },
                        },
                        coordinates: [
                            [
                                [172.6179014904908, -43.534013769591134],
                                [172.61828957291664, -43.53381057462073],
                                [172.6190010573676, -43.53374805295268],
                                [172.6196263012783, -43.5340762909837],
                                [172.619798782357, -43.53456082957409],
                                [172.61954006073807, -43.53496721377566],
                                [172.6186776553443, -43.53523292504356],
                                [172.6179014904908, -43.534873433049256],
                                [172.6175996486034, -43.53435763644574],
                                [172.6179014904908, -43.534013769591134],
                            ],
                        ],
                    },
                },
                {
                    id: '5622cc42-c1c4-4d28-b7af-49b295164cba',
                    name: 'hospital grounds',
                    aoi_geom: {
                        type: 'Polygon',
                        crs: {
                            type: 'name',
                            properties: {
                                name: 'urn:ogc:def:crs:EPSG::4326',
                            },
                        },
                        coordinates: [
                            [
                                [172.62007906411026, -43.53584249352575],
                                [172.62229975799806, -43.534670240974194],
                                [172.6244988917516, -43.53521729500065],
                                [172.62115707085104, -43.53717101886975],
                                [172.62007906411026, -43.53584249352575],
                            ],
                        ],
                    },
                },
                {
                    id: '7a71d427-4647-4ae3-a5cb-d63119164462',
                    name: 'north 1',
                    aoi_geom: {
                        type: 'Polygon',
                        crs: {
                            type: 'name',
                            properties: {
                                name: 'urn:ogc:def:crs:EPSG::4326',
                            },
                        },
                        coordinates: [
                            [
                                [172.61311514055592, -43.53266954398628],
                                [172.6160473188947, -43.53266954398628],
                                [172.6175996486034, -43.53316972445587],
                                [172.6169528445568, -43.53423259418039],
                                [172.6164138411864, -43.53442015748053],
                                [172.61309358042, -43.53340418262339],
                                [172.61311514055592, -43.53266954398628],
                            ],
                        ],
                    },
                },
                {
                    id: '6f7fb06e-faaf-4a71-9ae7-e2c526d2c827',
                    name: 'north 2',
                    aoi_geom: {
                        type: 'Polygon',
                        crs: {
                            type: 'name',
                            properties: {
                                name: 'urn:ogc:def:crs:EPSG::4326',
                            },
                        },
                        coordinates: [
                            [
                                [172.61249269787612, -43.53251212667367],
                                [172.61249269787612, -43.53907275787889],
                                [172.62452487525752, -43.53907275787889],
                                [172.62452487525752, -43.53251212667367],
                                [172.61249269787612, -43.53251212667367],
                            ],
                        ],
                    },
                },
            ],
        };
        return HttpResponse.json(response);
    }),
    http.get(getEndpoint('get_task/*'), () => {
        const response = {
            task: [
                {
                    id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    name: 'christchurch park',
                    aoi_geom: {
                        type: 'Polygon',
                        crs: {
                            type: 'name',
                            properties: {
                                name: 'urn:ogc:def:crs:EPSG::4326',
                            },
                        },
                        coordinates: [
                            [
                                [172.6179014904908, -43.534013769591134],
                                [172.61828957291664, -43.53381057462073],
                                [172.6190010573676, -43.53374805295268],
                                [172.6196263012783, -43.5340762909837],
                                [172.619798782357, -43.53456082957409],
                                [172.61954006073807, -43.53496721377566],
                                [172.6186776553443, -43.53523292504356],
                                [172.6179014904908, -43.534873433049256],
                                [172.6175996486034, -43.53435763644574],
                                [172.6179014904908, -43.534013769591134],
                            ],
                        ],
                    },
                },
            ],
        };
        return HttpResponse.json(response);
    }),
    http.get(getEndpoint('get_task_runs/*'), () => {
        const response = {
            task_run: [
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-02-08T23:29:32+00:00',
                    id: '9a8444bf-2d64-4521-af76-3f7c6080aa76',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-02-22T23:29:32+00:00',
                    id: '85947bc7-0423-4f2e-9a7c-66294201da65',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-03-07T23:29:32+00:00',
                    id: '1032249e-b9e7-4e52-85e5-c130a317a62c',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-03-21T23:29:32+00:00',
                    id: 'db94d34f-1961-4d77-9800-f776154a4913',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-04-04T23:29:32+00:00',
                    id: '3286aafa-967e-4e89-8515-0ad6dfb29715',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-04-18T23:29:32+00:00',
                    id: '6664a6ab-0c83-4fd9-a3e7-1173eb55d477',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-05-02T23:29:32+00:00',
                    id: 'd3e7ab51-375f-475e-a494-042576db051d',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-05-16T23:29:32+00:00',
                    id: '44417d7e-b2f1-4ea8-a14a-d05c0372e33c',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-05-30T23:29:32+00:00',
                    id: '840051ed-5bbd-4afa-b3e0-05a056aaa3b4',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-06-13T23:29:32+00:00',
                    id: '2a57c37e-ea22-4f86-b30d-5af358ff4afc',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-06-27T23:29:32+00:00',
                    id: 'a5890da3-ce12-496c-a591-d3f443d6a4b0',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-07-11T23:29:32+00:00',
                    id: 'db44a4f7-aedd-4fc5-816d-3345849805a8',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-07-25T23:29:32+00:00',
                    id: '6f08a339-f50b-4596-aa05-0e6b22559c84',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-08-08T23:29:32+00:00',
                    id: '4044d22f-2b05-46f1-969a-a61557318e04',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-08-22T23:29:32+00:00',
                    id: '42c22bbd-4888-444a-8c7d-b5ff36958761',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-09-05T23:29:32+00:00',
                    id: 'e4c9f2b5-c97b-4f02-8d8b-3a5d9b81cade',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-09-19T23:29:32+00:00',
                    id: '81f1c930-897c-497d-a166-93d7766ec03b',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-10-03T23:29:32+00:00',
                    id: '05eb46ec-1de6-4528-b399-6ea62f2e8816',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-10-17T23:29:32+00:00',
                    id: '811c23f5-0492-4884-9699-6312b091ceb3',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-10-31T23:29:32+00:00',
                    id: 'a3b42dfd-97ba-42fd-8ff4-94b2e1fa9abd',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-11-14T23:29:32+00:00',
                    id: 'b30ce64d-e284-47fa-94f4-d872af3ff5f6',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-11-28T23:29:32+00:00',
                    id: '5f73646d-a95d-4d7c-b989-4b1f86d7255f',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-12-12T23:29:32+00:00',
                    id: '4d45bfe3-f31d-45b7-af1e-bda87aaea91d',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-12-26T23:29:32+00:00',
                    id: '5229ea0a-a18f-4e16-b817-1c993fa462a7',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2025-01-09T23:29:32+00:00',
                    id: '73832d7d-c7b6-4b3f-9323-313ff857199a',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2025-01-23T23:29:32+00:00',
                    id: '1bd92786-3171-46a2-b876-0883238f08b3',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
                {
                    created_at: '2024-01-11T23:29:32+00:00',
                    date_run: '2024-01-12T23:29:32+00:00',
                    id: '1d35a460-cbe3-4db5-b68b-0bad8151c3e7',
                    status: 'scheduled',
                    task_id: '4a5f5bd6-34df-4644-8db6-ca896dc39d49',
                    updated_at: '2024-01-11T23:29:32+00:00',
                    index_strname: '',
                },
            ],
        };
        return HttpResponse.json(response);
    }),
    http.get(getEndpoint('get_aoi_run_result/*'), () => {
        return HttpResponse.json({
            aoi_run_result: [
                {
                    id: '66bb8534-8de1-4baf-aaec-63394258f08c',
                    aoi_run_id: 'a749c1b4-be5b-41ad-b8ed-d0c514fc4bbb',
                    result: 'EXCEEDED',
                    acquisition_time: '2023-12-19T22:28:13.913+00:00',
                    aoi_run: {
                        scheduled_date: '2024-02-07T18:36:32+00:00',
                        run_date: '2024-02-07T18:36:32+00:00',
                        status: 'COMPLETED',
                        aoi: {
                            aoi_geom: {
                                type: 'Polygon',
                                crs: {
                                    type: 'name',
                                    properties: {
                                        name: 'urn:ogc:def:crs:EPSG::4326',
                                    },
                                },
                                coordinates: [
                                    [
                                        [
                                            172.62411584060703,
                                            -43.530719855283316,
                                        ],
                                        [
                                            172.62898843108144,
                                            -43.53151704171492,
                                        ],
                                        [
                                            172.62784574393385,
                                            -43.53412736275731,
                                        ],
                                        [172.6254310088329, -43.53537777592522],
                                        [
                                            172.62390023925832,
                                            -43.53548718584389,
                                        ],
                                        [
                                            172.6239864797974,
                                            -43.533533407419306,
                                        ],
                                        [172.6227575521126, -43.53234547919442],
                                        [
                                            172.62411584060703,
                                            -43.530719855283316,
                                        ],
                                    ],
                                ],
                            },
                            area: 319789.010109633,
                            job: {
                                platform: {
                                    name: 'Sentinel 2',
                                },
                            },
                            name: 'hagley park',
                        },
                    },
                    spectral_index: 'NDVI',
                },
            ],
        });
    }),
    http.get(getEndpoint('get_themes'), ({ request }) => {
        return HttpResponse.json([
            {
                id: '1',
                name: 'agriculture',
                description: 'Crop health and productivity are directly linked to environmental changes such as seasonal variations, soil characteristics and water availability. Access to the latest data can provide key early insights into plant stress.',
                indices: [
                    {
                        name: 'Crop health',
                        description: 'To understand the health and growth of a crop, the amount of greenness and leaf water content can be measured. ',
                    },
                    {
                        name: 'Crop water stress',
                        description: 'By detecting changes in the moisture content of a crop, the degree of plant water stress can be assessed and used to mitigate any early-stage water-related issues.',
                    },
                    {
                        name: 'Crop maturity',
                        description: 'To understand the health and growth of a crop, the amount of chlorophyll and leaf water content can be measured.  This analysis is especially sensitive to plant health during the mid-to-late growing season when plants are mature and ready for harvest.',
                    },
                ],
            },
            {
                id: '2',
                name: 'forestry',
                description: 'Understanding the changes and health of exotic and indigenous trees is key to making informed decisions about forest management.',
                indices: [
                    {
                        name: 'a',
                        description: 'abcsalkdfjsdf',
                    },
                    {
                        name: 'b',
                        description: 'abcsalkdfjsdf',
                    },
                ],
            },
        ]);
    }),
];
