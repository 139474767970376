import { RadioButton } from '@orbica/component-sdk';
import Flex from '@orbica/component-sdk/build/css/flex.scss';
import GridStyles from '@orbica/component-sdk/build/css/grid.scss';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import cx from 'classnames';
import React from 'react';
import { OutlinedContainer } from '@Components/Containers';
export const OutlinedSelectableContainer = (props) => {
    return (React.createElement(OutlinedContainer, { className: GridStyles.dialogGrid, "$selected": props.isSelected, "$grid": true },
        React.createElement(RadioButton, { checked: props.isSelected, onChange: props.onChange, containerClassName: GridContentStyles.max, label: props.title, labelClassName: TypographyStyles.baseSemibold, labelStyle: { width: '100%' } }),
        props.description && (React.createElement("div", { className: cx(GridContentStyles.max, Flex.flex, Flex.column) }, props.description.map((d, i) => (React.createElement("span", { className: cx(TypographyStyles.smallRegular), key: `${props.title}description${i}` }, d))))),
        props.children));
};
