import { MainContainer, Page, PageContent, StaticDrawer, } from '@orbica/component-sdk';
import React from 'react';
import { Header } from '@Components/Header/Header';
import { Tabs } from '@Components/Tabs/Tabs';
export const MonitorView = () => {
    return (React.createElement(Page, null,
        React.createElement(Header, null,
            React.createElement(Tabs, { currentView: "monitor" })),
        React.createElement(PageContent, null,
            React.createElement(StaticDrawer, null),
            React.createElement(MainContainer, { hasDrawer: true }))));
};
