import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
export const StyledToastContainer = styled(ToastContainer) `
    &&&.Toastify__toast-container {
        width: auto;
        padding: 0;
    }

    .Toastify__toast {
        padding: 0;
        max-height: auto;
    }
`;
