export function blobToUrl(blob) {
    return URL.createObjectURL(blob);
}
export function extractFilenameFromPath(pathname) {
    const lastIndex = pathname.lastIndexOf('/');
    if (lastIndex !== -1) {
        return pathname.substring(lastIndex + 1);
    }
    else {
        return pathname;
    }
}
export function downloadFileToLocal(filename, fileUrl) {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
