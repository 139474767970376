var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cx from 'classnames';
import React from 'react';
import { AdditionalInfoCard } from '@Components/Cards';
import { getThemeIcon } from '@Data/imagery/helpers';
import { ThemeIndices } from './ThemeIndices';
import Styles from './ThemeInformationCard.scss';
export const ThemeInformationCard = (_a) => {
    var { theme, isSelected, onClick, renderOpen, disableCollapse } = _a, divProps = __rest(_a, ["theme", "isSelected", "onClick", "renderOpen", "disableCollapse"]);
    return (React.createElement(AdditionalInfoCard, Object.assign({ title: theme.name, information: React.createElement(ThemeIndices, { indices: theme.theme_spectral_indices }), className: cx(Styles.themeCard, isSelected ? Styles.selected : ''), onClick: onClick, icon: React.createElement("img", { src: getThemeIcon(theme.name) }), renderOpen: renderOpen, disableCollapse: disableCollapse }, divProps), theme.description));
};
