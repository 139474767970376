var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { TagType, baseApi, graphqlApi, webApi } from '@Services/redux';
import { transformBaseJob, transformDetailedJob, transformGetJobRunResultDataResponse, transformGetJobsOverviewResponse, } from './helpers';
export const jobsBaseApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getJobs: build.query({
            query: (params) => {
                return {
                    url: 'get_jobs_overview',
                    method: 'POST',
                    data: params,
                };
            },
            transformResponse: (gqlResponse) => transformGetJobsOverviewResponse(gqlResponse),
            providesTags: [TagType.JOBS],
        }),
        editJob: build.mutation({
            query(body) {
                return {
                    url: `update_job`,
                    method: 'PATCH',
                    data: body,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: TagType.JOB },
                { type: TagType.JOBS },
            ],
            onQueryStarted: (_, { dispatch, queryFulfilled }) => __awaiter(void 0, void 0, void 0, function* () {
                yield queryFulfilled;
                dispatch(graphqlApi.util.invalidateTags([TagType.STUS]));
            }),
        }),
        getJob: build.query({
            query: (jobId) => ({ url: `get_job/${jobId}`, method: 'GET' }),
            transformResponse: (gqlResponse) => transformDetailedJob(gqlResponse.job[0]),
            providesTags: [TagType.JOB],
        }),
        getRunResultJobDetails: build.query({
            query: (jobId) => ({
                url: `get_run_result_job_details/${jobId}`,
                method: 'GET',
            }),
            transformResponse: (gqlResponse) => transformGetJobRunResultDataResponse(gqlResponse),
            providesTags: [TagType.RUN_RESULT_JOB],
        }),
        archiveJob: build.mutation({
            query(jobId) {
                return {
                    url: `archive_job/${jobId}`,
                    method: 'PATCH',
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: TagType.JOBS }],
            onQueryStarted: (_, { dispatch, queryFulfilled }) => __awaiter(void 0, void 0, void 0, function* () {
                yield queryFulfilled;
                dispatch(graphqlApi.util.invalidateTags([TagType.STUS]));
            }),
        }),
        getDemoJobs: build.query({
            query: () => {
                return {
                    url: 'demo-jobs-overview',
                    method: 'GET',
                };
            },
            transformResponse: (gqlResponse) => gqlResponse.job_aoi_overview.map((j) => transformBaseJob(j)),
        }),
    }),
});
export const jobsWebApi = webApi.injectEndpoints({
    endpoints: (build) => ({
        updateJobUsers: build.mutation({
            query: (body) => {
                return {
                    url: 'jobs/update_job_users',
                    method: 'PATCH',
                    data: body,
                };
            },
            onQueryStarted: (_, { dispatch, queryFulfilled }) => __awaiter(void 0, void 0, void 0, function* () {
                yield queryFulfilled;
                dispatch(baseApi.util.invalidateTags([TagType.JOB]));
            }),
        }),
        createJob: build.mutation({
            query: (body) => {
                return {
                    url: 'jobs/create_job',
                    method: 'POST',
                    data: body,
                };
            },
            onQueryStarted: (_, { dispatch, queryFulfilled }) => __awaiter(void 0, void 0, void 0, function* () {
                yield queryFulfilled;
                dispatch(baseApi.util.invalidateTags([TagType.JOBS]));
                dispatch(graphqlApi.util.invalidateTags([TagType.STUS]));
            }),
        }),
    }),
});
export const { useGetJobsQuery, useGetJobQuery, useEditJobMutation, useGetRunResultJobDetailsQuery, useArchiveJobMutation, useGetDemoJobsQuery, } = jobsBaseApi;
export const { useUpdateJobUsersMutation, useCreateJobMutation } = jobsWebApi;
