import BrBG from '@Images/colorRanges/diverging/BrBG.png';
import PRGn from '@Images/colorRanges/diverging/PRGn.png';
import PiYG from '@Images/colorRanges/diverging/PiYG.png';
import PuOr from '@Images/colorRanges/diverging/PuOr.png';
import RdBu from '@Images/colorRanges/diverging/RdBu.png';
import RdGy from '@Images/colorRanges/diverging/RdGy.png';
import RdYlBu from '@Images/colorRanges/diverging/RdYlBu.png';
import RdYlGn from '@Images/colorRanges/diverging/RdYlGn.png';
import Spectral from '@Images/colorRanges/diverging/Spectral.png';
export const ColorBrewerOptions = [
    { label: 'Spectral', value: 'Spectral', img: Spectral },
    { label: 'Brown-Blue', value: 'BrBG', img: BrBG },
    { label: 'Pink-Green', value: 'PiYG', img: PiYG },
    { label: 'Purple-Green', value: 'PRGn', img: PRGn },
    { label: 'Purple-Orange', value: 'PuOr', img: PuOr },
    { label: 'Red-Blue', value: 'RdBu', img: RdBu },
    { label: 'Red-Grey', value: 'RdGy', img: RdGy },
    { label: 'Red-Yellow-Blue', value: 'RdYlBu', img: RdYlBu },
    { label: 'Red-Yellow-Green', value: 'RdYlGn', img: RdYlGn },
];
