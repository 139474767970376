import { ReactComponent as DataLineIcon } from '@Icons/md/data-line.svg';
import { ReactComponent as DataPolygonIcon } from '@Icons/md/data-polygon.svg';
import { ReactComponent as MapIcon } from '@Icons/md/map.svg';
import { ReactComponent as SearchIcon } from '@Icons/md/search.svg';
import { MainContainer, StaticDrawer, Toolbar } from '@orbica/component-sdk';
import React, { useEffect, useState } from 'react';
import { AddressSearchSelector } from '@Components/ToolBar/AddressSearchSelector';
import { BaseMapSelector } from '@Components/ToolBar/BaseMapSelector';
import { DrawLineSelector } from '@Components/ToolBar/DrawLineSelector';
import { DrawPolygonSelector } from '@Components/ToolBar/DrawPolygonSelector';
import { resetMapToolbarStates, setMapDrawState } from '@Data/mapToolbar/Api';
import ExploreStyles from '../../ExploreView.scss';
import { PreExploreDrawer } from './components/Drawer/PreExploreDrawer';
import { PreExploreMap } from './components/Map/PreExploreMap';
export const PreExploreView = () => {
    const [collectionIdDates, setCollectionIdDates] = useState(null);
    const [selectedLayer, setSelectedLayer] = useState(null);
    const handleSetCollectionIdDates = (collectionIds) => {
        setCollectionIdDates(collectionIds);
    };
    const handleToolbarClose = () => {
        setMapDrawState();
    };
    useEffect(() => {
        return () => {
            resetMapToolbarStates();
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(StaticDrawer, { fullWidth: true, className: ExploreStyles.accordionDrawerContainer },
            React.createElement(PreExploreDrawer, { onSetCollectionIdDates: handleSetCollectionIdDates, collections: collectionIdDates, onSetLayer: (i) => setSelectedLayer(i), selectedLayer: selectedLayer })),
        React.createElement(MainContainer, { hasDrawer: true, fullWidth: true, fillScreen: true, GridContainerProps: {
                className: ExploreStyles.mainContainer,
            } },
            React.createElement(PreExploreMap, { collectionIds: collectionIdDates, selectedLayer: selectedLayer })),
        React.createElement(Toolbar, { onClose: handleToolbarClose, items: [
                {
                    title: 'Basemaps',
                    IconProps: { icon: React.createElement(MapIcon, null) },
                    content: React.createElement(BaseMapSelector, null),
                    id: 'basemapSelector',
                },
                {
                    title: 'Address Search',
                    IconProps: { icon: React.createElement(SearchIcon, null) },
                    content: React.createElement(AddressSearchSelector, null),
                    id: 'addressSearch',
                },
                {
                    title: 'Measure Distance',
                    IconProps: { icon: React.createElement(DataLineIcon, null) },
                    onActivate: () => setMapDrawState(),
                    onDeactivate: handleToolbarClose,
                    id: 'measureDistance',
                    content: React.createElement(DrawLineSelector, null),
                },
                {
                    title: 'Draw Polygon',
                    IconProps: { icon: React.createElement(DataPolygonIcon, null) },
                    onDeactivate: handleToolbarClose,
                    onActivate: () => setMapDrawState(),
                    content: React.createElement(DrawPolygonSelector, null),
                    id: 'drawPolygon',
                },
            ] })));
};
