import LayoutStyles from '@Styles/layout.scss';
import { Tooltip } from '@orbica/component-sdk';
import React from 'react';
import { AreaOfInterestMap } from '@Components/AreaOfInterest/AreaOfInterestMap';
import { EntityAttributeCard } from '@Components/Cards';
import { ScheduledDateIndicator } from '@Components/EntityDetails/ScheduledDateIndicator';
import { AreaConversionTooltipTable } from '@Components/Tables';
import { ThemeAttributeCard } from '@Components/Themes/ThemeAttributeCard/ThemeAttributeCard';
import { convertAreaFromSqm } from '@Services/helpers/Area';
import Styles from './AoiDescription.scss';
export const AoiDescription = ({ selectedAoi, isLoading, }) => {
    return (React.createElement("div", { className: LayoutStyles.entityDescriptionContainer },
        React.createElement(EntityAttributeCard, { isLoading: isLoading, contentHeight: "100%", value: React.createElement("div", { className: Styles.mapThumbnailContainer },
                React.createElement(AreaOfInterestMap, { feature: selectedAoi === null || selectedAoi === void 0 ? void 0 : selectedAoi.aoi_geom, name: selectedAoi === null || selectedAoi === void 0 ? void 0 : selectedAoi.name })) }),
        React.createElement(EntityAttributeCard, { isLoading: isLoading, title: "Area", value: React.createElement(Tooltip, { title: React.createElement(AreaConversionTooltipTable, { area: selectedAoi === null || selectedAoi === void 0 ? void 0 : selectedAoi.area }) },
                React.createElement("span", null, convertAreaFromSqm(selectedAoi === null || selectedAoi === void 0 ? void 0 : selectedAoi.area))) }),
        React.createElement(EntityAttributeCard, { isLoading: isLoading, title: "Provider", value: selectedAoi === null || selectedAoi === void 0 ? void 0 : selectedAoi.platform_name }),
        React.createElement(EntityAttributeCard, { isLoading: isLoading, title: "Monitoring Interval", value: `Every ${selectedAoi === null || selectedAoi === void 0 ? void 0 : selectedAoi.cadence} days` }),
        React.createElement(EntityAttributeCard, { isLoading: isLoading, title: "Next scheduled run", value: React.createElement(ScheduledDateIndicator, { scheduledDate: selectedAoi === null || selectedAoi === void 0 ? void 0 : selectedAoi.next_run_date }) }),
        (selectedAoi === null || selectedAoi === void 0 ? void 0 : selectedAoi.theme) && (selectedAoi === null || selectedAoi === void 0 ? void 0 : selectedAoi.theme.name) !== 'custom' && (React.createElement(ThemeAttributeCard, { isLoading: isLoading, theme: selectedAoi === null || selectedAoi === void 0 ? void 0 : selectedAoi.theme }))));
};
