import { Tab, TabGroup } from '@orbica/component-sdk';
import GridStyles from '@orbica/component-sdk/build/css/grid.scss';
import Tokens from '@orbica/component-sdk/build/css/tokens.scss';
import _isNil from 'lodash/isNil';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useGetThemesQuery } from '@Data/imagery/Api';
import { IndicesByDomain } from './IndexTabs/IndicesByDomain';
import { ThemeOptions } from './IndexTabs/ThemeOptions';
import { TitleAndDescription } from './TitleAndDescription';
const StyledTabsContainer = styled('div') `
    grid-column: 1/-1;
    display: flex;
    flex-direction: column;

    .tabs {
        width: 100%;
    }
`;
const StyledTabOptionContainer = styled('div') `
    border: 1px solid ${Tokens.primaryGreen};
    border-radius: 0 0 4px 4px;
    padding: 16px;
    margin-bottom: 1px;
`;
export const IndicesStage = (props) => {
    const { data: themes, isFetching } = useGetThemesQuery();
    const [selectedTab, setSelectedTab] = useState(!_isNil(props.theme) && props.theme.name === 'custom'
        ? 'domains'
        : 'themes');
    const handleChangeTab = (tab) => {
        props.onSetTheme(null);
        props.onSetDomain(null);
        props.onUpdateImageryIndices([]);
        setSelectedTab(tab);
    };
    const handleSetPrecannedTheme = (theme) => {
        props.onSetTheme(theme);
        props.onUpdateImageryIndices(theme.theme_spectral_indices.map((i) => i.spectral_index));
    };
    const { customTheme, otherThemes } = useMemo(() => {
        if (_isNil(themes) || themes.length === 0) {
            return {
                customTheme: null,
                otherThemes: [],
            };
        }
        const customTheme = themes.find((t) => t.name === 'custom');
        const otherThemes = themes.filter((t) => t.name !== 'custom');
        return {
            customTheme,
            otherThemes,
        };
    }, [themes]);
    const handleSetCustomIndices = (indices) => {
        props.onSetTheme(customTheme);
        props.onUpdateImageryIndices(indices);
    };
    const innerHtml = selectedTab === 'themes' ? (React.createElement(ThemeOptions, { onSelectTheme: handleSetPrecannedTheme, theme: props.theme, themes: otherThemes, isLoading: isFetching })) : (React.createElement(IndicesByDomain, { imageryIndices: props.imageryIndices, onUpdateImageryIndices: handleSetCustomIndices, domain: props.domain, onSetDomain: props.onSetDomain }));
    return (React.createElement(React.Fragment, null,
        React.createElement(TitleAndDescription, { title: "Select the type of change you want to monitor.", description: 'Select a predefined analysis type or customise indices with advanced monitoring (recommended for scientists and GIS experts).' }),
        React.createElement(StyledTabsContainer, null,
            React.createElement(TabGroup, { value: selectedTab, onChange: (e, v) => handleChangeTab(v), className: "tabs" },
                React.createElement(Tab, { value: 'themes', mode: "light" }, "Analysis Types"),
                React.createElement(Tab, { value: 'domains', mode: "light" }, "Advanced")),
            React.createElement(StyledTabOptionContainer, { className: GridStyles.dialogGrid }, innerHtml))));
};
