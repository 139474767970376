var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Dialog } from '@orbica/component-sdk';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import { usePostHog } from 'posthog-js/react';
import React from 'react';
import { useEditJobMutation } from '@Data/jobs/Api';
import { Analytics } from '@Services/analytics/Events';
export const ToggleMonitoringStatusDialog = (props) => {
    var _a, _b, _c, _d;
    const [editJob, { isLoading: isSubmitting }] = useEditJobMutation();
    const posthog = usePostHog();
    const targetStatus = ((_a = props.job) === null || _a === void 0 ? void 0 : _a.is_active) ? 'Stop' : 'Activate';
    const submittingText = ((_b = props.job) === null || _b === void 0 ? void 0 : _b.is_active) ? 'Stopping' : 'Activating';
    const title = `${targetStatus} ${(_c = props.job) === null || _c === void 0 ? void 0 : _c.name} monitoring`;
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        yield editJob({
            id: props.job.id,
            description: props.job.description,
            name: props.job.name,
            is_active: !props.job.is_active,
        });
        posthog === null || posthog === void 0 ? void 0 : posthog.capture(Analytics.EditJob.MonitoringStatusToggled, {
            jobId: props.job.id,
            isMonitoring: !props.job.is_active,
        });
        props.onClose();
    });
    return (React.createElement(Dialog, { open: props.isOpen, onClose: props.onClose, title: title, FooterProps: {
            onSubmit: handleSubmit,
            submitText: `${targetStatus} Monitoring`,
            submittingText: `${submittingText} Monitoring`,
            isSubmitting: isSubmitting,
        } },
        React.createElement("span", { className: GridContentStyles.max },
            "Are you sure you want to ",
            targetStatus.toLowerCase(),
            " monitoring for ", (_d = props.job) === null || _d === void 0 ? void 0 :
            _d.name,
            "?")));
};
