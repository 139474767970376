import _isNil from 'lodash/isNil';
import { DateTime, Duration } from 'luxon';
import { getSpectralIndicesLabelsByTheme } from '@Services/helpers/Themes';
import { JobState, } from './Types';
export function getTimeUntilNextScheduledDate(date) {
    if (!date)
        return null;
    const scheduledDate = DateTime.fromISO(date);
    const today = DateTime.now().startOf('day');
    const diff = scheduledDate.diff(today, ['months', 'days']).toObject();
    diff.days = Math.round(diff.days);
    if (diff.months === 0) {
        delete diff.months;
    }
    return Duration.fromObject(diff).toHuman();
}
export function transformDetailedJob(job) {
    var _a, _b, _c;
    function getRunDate(runDateArray, type) {
        var _a, _b;
        const runDateType = `${type}_run_date`;
        if (_isNil(runDateArray))
            return null;
        return ((_b = (_a = runDateArray[0]) === null || _a === void 0 ? void 0 : _a.aoi_runs_aggregate.aggregate[runDateType].date) !== null && _b !== void 0 ? _b : null);
    }
    if (_isNil(job)) {
        return null;
    }
    let displayIndices;
    if (!job.theme || ((_a = job.theme) === null || _a === void 0 ? void 0 : _a.name) === 'custom') {
        displayIndices = job.job_spectral_indices.map((index) => ({
            id: index.id,
            label: index.index_key,
        }));
    }
    else {
        displayIndices = job.theme.theme_spectral_indices.map((themeIndex) => ({
            id: themeIndex.spectral_index.id,
            label: themeIndex.name,
        }));
    }
    return Object.assign(Object.assign({}, job), { next_run_date: getRunDate(job.next_run_date, 'next'), last_run_date: getRunDate(job.last_run_date, 'last'), cadence: (_b = job.cadences[0]) === null || _b === void 0 ? void 0 : _b.days, job_users: (_c = job.job_users) === null || _c === void 0 ? void 0 : _c.map((u) => u.user) });
}
export function transformBaseJob(job) {
    var _a, _b, _c;
    const isCalculating = !_isNil(job) ||
        [JobState.WAITING, JobState.INITIALIZING].includes(job.state);
    return Object.assign(Object.assign({}, job), { cadence: (_a = job === null || job === void 0 ? void 0 : job.cadences[0]) !== null && _a !== void 0 ? _a : undefined, themeName: (_c = (_b = job === null || job === void 0 ? void 0 : job.theme) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : undefined, aois_count: isCalculating && job.aois_count === 0 ? '-' : job.aois_count });
}
export function transformGetJobsOverviewResponse(response) {
    return {
        jobs: response.job_aoi_overview.map((jobOverview) => transformBaseJob(jobOverview)),
        count: response.job_aoi_overview_aggregate.aggregate.count,
    };
}
export function transformGetJobRunResultDataResponse(response) {
    var _a;
    const acquisition_times = response.aoi_run.map((r) => r.acquisition_time);
    const job = response.job[0];
    const jobIndices = getSpectralIndicesLabelsByTheme(job.theme, job.job_spectral_indices, ['id', 'index_key'], 'index_key');
    return Object.assign(Object.assign({}, job), { acquisition_times: acquisition_times, job_spectral_indices: jobIndices, themeName: (_a = job.theme) === null || _a === void 0 ? void 0 : _a.name });
}
