var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Dialog } from '@orbica/component-sdk';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import cx from 'classnames';
import React from 'react';
export const ConfirmCancelProcessDialog = (props) => {
    var _a;
    const handleCancelProcess = () => __awaiter(void 0, void 0, void 0, function* () {
        yield props.onCancelProcess();
        props.onClose();
    });
    const text = (_a = props.cancelText) !== null && _a !== void 0 ? _a : `Are you sure you want to cancel ${props.processName}?`;
    return (React.createElement(Dialog, { open: props.isOpen, onClose: props.onClose, title: `Cancel ${props.processName}?`, FooterProps: {
            onSubmit: handleCancelProcess,
            submitText: 'Yes',
            cancelText: 'No',
        } },
        React.createElement("span", { className: cx(TypographyStyles.baseRegular, GridContentStyles.max) }, text)));
};
