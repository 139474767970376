import { Toast } from '@orbica/component-sdk';
import React from 'react';
import { toast } from 'react-toastify';
export function createToast(title, type, description, id) {
    const toastId = id !== null && id !== void 0 ? id : crypto.randomUUID();
    toast(React.createElement(Toast, { title: title, description: description, type: type, onClose: () => toast.dismiss(toastId) }), {
        toastId: toastId,
        closeOnClick: false,
        bodyStyle: { padding: 0, width: 'auto' },
    });
}
