import _isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import { createToast } from '@Services/notifications/NotificationService';
export function getDateRangeString(options, format = DateTime.DATE_MED) {
    if (!options.dateRange && !options.startDate) {
        createToast('invalid date range', 'error');
        console.error({ options });
    }
    const startDt = options.dateRange
        ? DateTime.fromMillis(options.dateRange[0])
        : options.startDate;
    const endDt = options.dateRange
        ? DateTime.fromMillis(options.dateRange[1])
        : options.endDate;
    if (startDt.toMillis() === endDt.toMillis()) {
        return startDt.toLocaleString(format);
    }
    return `${startDt.toLocaleString(format)} - ${endDt.toLocaleString(format)}`;
}
export function getIndicesFromSearchParams(detailedJob, searchParams) {
    const searchIndices = searchParams.get('indices');
    if (!_isNil(searchIndices)) {
        const searchIndicesArray = searchIndices.split(',');
        return detailedJob === null || detailedJob === void 0 ? void 0 : detailedJob.job_spectral_indices.filter((index) => searchIndicesArray.includes(index.index_key));
    }
    else {
        return detailedJob.job_spectral_indices;
    }
}
export function getDateRangeFromSearchParams(detailedJob, searchParams) {
    var _a;
    if (!_isNil(detailedJob.acquisition_times) &&
        detailedJob.acquisition_times.length > 0) {
        const searchDateRange = searchParams.get('dateRange');
        const formattedDateRange = (_a = searchDateRange === null || searchDateRange === void 0 ? void 0 : searchDateRange.split(',').map((dateStr) => parseInt(dateStr))) !== null && _a !== void 0 ? _a : [];
        if (formattedDateRange.length === 2) {
            return formattedDateRange;
        }
        else {
            const startDt = DateTime.fromISO(detailedJob.acquisition_times[0]);
            const endDt = DateTime.fromISO(detailedJob.acquisition_times[detailedJob.acquisition_times.length - 1]);
            return [startDt.toMillis(), endDt.toMillis()];
        }
    }
    else {
        return null;
    }
}
export function getAoisFromSearchParams(aois, searchParams) {
    const searchAoiIds = searchParams.get('aoiIds');
    if (!_isNil(searchAoiIds)) {
        const aoiIdArray = searchAoiIds.split(',');
        return aois.filter((aoi) => aoiIdArray.includes(aoi.id));
    }
    else {
        return aois;
    }
}
