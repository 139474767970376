import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import _isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BackButton } from '@Components/Buttons/BackButton';
import { ViewResultsButton } from '@Components/Buttons/ViewResultsButton';
import { TitleWithActionContainer } from '@Components/Containers';
import { SpectralIndexPill } from '@Components/EntityDetails/SpectralIndexPill';
import { ResourceNotFound } from '@Components/ResourceNotFound/ResourceNotFound';
import { AoiRunResultValue } from '@Data/aoiRuns/Types';
import { useGetRunResultQuery } from '@Data/runResults/Api';
import { getDateStringFromTimestamp } from '@Services/helpers/Dates';
import { Navigation } from '@Services/navigation/Navigation';
import Styles from './AoiRunResultView.scss';
import { AoiRunResultDescription } from './components/AoiRunResultDescription/AoiRunResultDescription';
import { ComparisonResult } from './components/ComparisonResult/ComparisonResult';
function getIndicesAndDateRange(detailedRunResult) {
    var _a;
    const acquisitionTimeMillis = (_a = DateTime.fromISO(detailedRunResult === null || detailedRunResult === void 0 ? void 0 : detailedRunResult.acquisition_time)) === null || _a === void 0 ? void 0 : _a.toMillis();
    return {
        indices: detailedRunResult === null || detailedRunResult === void 0 ? void 0 : detailedRunResult.spectral_index,
        dateRange: `${acquisitionTimeMillis},${acquisitionTimeMillis}`,
    };
}
function getBaselineInfo(startDate, endDate) {
    return `${getDateStringFromTimestamp(startDate)} - ${getDateStringFromTimestamp(endDate)}`;
}
function getInnerHtml(detailedRunResult) {
    if (_isNil(detailedRunResult)) {
        return null;
    }
    if (_isNil(detailedRunResult.value)) {
        return (React.createElement("div", { className: Styles.aoiWillRunContainer },
            React.createElement("span", { className: TypographyStyles.xlargeLight },
                ' ',
                "This run is scheduled for",
                ' ',
                getDateStringFromTimestamp(detailedRunResult.aoi_run_scheduled_date, DateTime.DATE_FULL))));
    }
    if (detailedRunResult.value === AoiRunResultValue.NO_RESULT) {
        return (React.createElement("div", { className: Styles.aoiWillRunContainer },
            React.createElement("span", { className: TypographyStyles.xlargeLight }, "No suitable image was found for this date, so this run completed without a result."),
            React.createElement("span", { className: TypographyStyles.xlargeLight }, "This normally means that the images we checked either had too much cloud cover to determine whether any change occurred, or the data was malformed.")));
    }
    return (React.createElement(ComparisonResult, { imageAcquisitionTime: detailedRunResult.acquisition_time, baselineStartDate: detailedRunResult.job_baseline.start_date, baselineEndDate: detailedRunResult.job_baseline.end_date }));
}
const AoiRunResultViewTitle = ({ detailedRunResult }) => {
    if (_isNil(detailedRunResult)) {
        return null;
    }
    const indexInfo = (detailedRunResult === null || detailedRunResult === void 0 ? void 0 : detailedRunResult.job_theme.name) === 'custom' ? (React.createElement(SpectralIndexPill, { shortName: detailedRunResult === null || detailedRunResult === void 0 ? void 0 : detailedRunResult.spectral_index, size: "large", className: Styles.indexInfo })) : (React.createElement("div", null,
        React.createElement("span", null, detailedRunResult === null || detailedRunResult === void 0 ? void 0 : detailedRunResult.theme_spectral_index.label)));
    return (React.createElement("div", { className: Styles.title }, detailedRunResult === null || detailedRunResult === void 0 ? void 0 :
        detailedRunResult.aoi_name,
        React.createElement("span", null, "-"),
        indexInfo));
};
export const AoiRunResultView = () => {
    const { jobId, aoiId, aoiRunResultId } = useParams();
    const { data: detailedRunResult, isFetching } = useGetRunResultQuery(aoiRunResultId);
    if (!isFetching && !detailedRunResult) {
        return React.createElement(ResourceNotFound, { resourceName: "Run Result" });
    }
    const actions = (React.createElement(React.Fragment, null,
        React.createElement(ViewResultsButton, { href: Navigation.explore.results.getUrl(Object.assign(Object.assign({ jobId: jobId, aoiIds: aoiId }, getIndicesAndDateRange(detailedRunResult)), { load: true })) }),
        React.createElement(BackButton, { href: Navigation.schedule.aoiRuns.getUrl(jobId, aoiId) })));
    return (React.createElement(React.Fragment, null,
        React.createElement(TitleWithActionContainer, { isLoading: isFetching, title: React.createElement(AoiRunResultViewTitle, { detailedRunResult: detailedRunResult }), actions: actions }),
        React.createElement(AoiRunResultDescription, { selectedAoiRunResult: detailedRunResult, isLoading: isFetching, baselineTimeRange: getBaselineInfo(detailedRunResult === null || detailedRunResult === void 0 ? void 0 : detailedRunResult.job_baseline.start_date, detailedRunResult === null || detailedRunResult === void 0 ? void 0 : detailedRunResult.job_baseline.end_date) }),
        !isFetching && getInnerHtml(detailedRunResult)));
};
