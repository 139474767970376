import combine from '@turf/combine';
import difference from '@turf/difference';
import _isEmpty from 'lodash/isEmpty';
import { GlobalKeys } from '@Services/globalVariables/GlobalKeys';
import { clearGlobalVariable, getGlobalVariable, hasGlobalValue, setGlobalVariable, } from '@Services/globalVariables/GlobalVariableManager';
import { lngLatBoundsToPolygon } from '@Services/helpers/Gis';
export var H3AbortControllerKey;
(function (H3AbortControllerKey) {
    H3AbortControllerKey["GET_DATA"] = "getData";
})(H3AbortControllerKey || (H3AbortControllerKey = {}));
const getAbortControllerGlobalKey = (key) => {
    return `${GlobalKeys.CancelTokens}${key}`;
};
export const abortOutstandingH3Requests = (key) => {
    const controllerKey = getAbortControllerGlobalKey(key);
    if (hasGlobalValue(controllerKey)) {
        const controllers = getGlobalVariable(controllerKey);
        controllers.forEach((controller) => {
            controller.abort();
        });
        setGlobalVariable(controllerKey, []);
    }
};
export const addH3AbortController = (key) => {
    const controllerKey = getAbortControllerGlobalKey(key);
    const controllersArray = hasGlobalValue(controllerKey)
        ? getGlobalVariable(controllerKey)
        : [];
    const newController = new AbortController();
    controllersArray.push(newController);
    setGlobalVariable(controllerKey, controllersArray);
    return newController.signal;
};
export const clearAllH3GlobalVariables = () => {
    Object.values(H3AbortControllerKey).forEach((key) => {
        clearGlobalVariable(getAbortControllerGlobalKey(key));
    });
};
export const abortAllH3Requests = () => {
    Object.values(H3AbortControllerKey).forEach((key) => {
        abortOutstandingH3Requests(key);
    });
};
export function cacheQueriedPolygon(bbox, resolution, timestamp) {
    var _a, _b;
    const queryPolygon = lngLatBoundsToPolygon(bbox);
    const storedData = (_a = getGlobalVariable(GlobalKeys.QueriedPolygonsByResolution)) !== null && _a !== void 0 ? _a : {};
    const polygonsByResolution = (_b = storedData[resolution]) !== null && _b !== void 0 ? _b : {};
    polygonsByResolution[timestamp] = queryPolygon.coordinates;
    storedData[resolution] = polygonsByResolution;
    setGlobalVariable(GlobalKeys.QueriedPolygonsByResolution, storedData);
}
export function getUnqueriedPolygonByResolution(bbox, resolution) {
    var _a, _b, _c;
    const queryPolygon = lngLatBoundsToPolygon(bbox);
    const storedData = (_a = getGlobalVariable(GlobalKeys.QueriedPolygonsByResolution)) !== null && _a !== void 0 ? _a : {};
    const polygonsByResolution = (_b = storedData[resolution]) !== null && _b !== void 0 ? _b : {};
    if (_isEmpty(polygonsByResolution)) {
        return queryPolygon;
    }
    const featureCollection = {
        type: 'FeatureCollection',
        features: Object.values(polygonsByResolution).map((coordinates) => {
            return {
                type: 'Feature',
                geometry: { type: 'Polygon', coordinates: coordinates },
            };
        }),
    };
    const combined = combine(featureCollection);
    const externalArea = difference(queryPolygon, combined.features[0].geometry);
    return (_c = externalArea === null || externalArea === void 0 ? void 0 : externalArea.geometry) !== null && _c !== void 0 ? _c : null;
}
