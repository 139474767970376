import rewind from '@mapbox/geojson-rewind';
import { setAoisFeatureCollection, setLineFeatureCollection, setMapDrawState, } from '@Data/mapToolbar/Api';
export const exportGeojson = (feature) => {
    if (feature) {
        const dataStr = 'data:application/json;charset=utf-8,' +
            encodeURIComponent(JSON.stringify(feature));
        const download = document.createElement('a');
        download.setAttribute('href', dataStr);
        download.setAttribute('download', 'geojson' + '.json');
        document.body.appendChild(download);
        download.click();
        download.remove();
    }
};
export const handleAdd = (e, aoisFeatureCollection, lineFeatureCollection) => {
    const newFeature = e.features[0];
    const geometry = newFeature['geometry'];
    if (geometry['type'] === 'Polygon') {
        if (!aoisFeatureCollection.features.includes(newFeature)) {
            rewind(newFeature, true);
            const newGeojson = Object.assign(Object.assign({}, aoisFeatureCollection), { features: [...aoisFeatureCollection.features, newFeature] });
            setAoisFeatureCollection(newGeojson);
        }
    }
    else if (geometry['type'] === 'LineString') {
        const newLineGeojson = {
            type: lineFeatureCollection === null || lineFeatureCollection === void 0 ? void 0 : lineFeatureCollection.type,
            features: [newFeature],
        };
        setLineFeatureCollection(newLineGeojson);
    }
    setMapDrawState();
};
export const handleUpdate = (e, aoisFeatureCollection) => {
    const updatedFeature = e.features[0];
    rewind(updatedFeature, true);
    const updatedFeatures = aoisFeatureCollection === null || aoisFeatureCollection === void 0 ? void 0 : aoisFeatureCollection.features.map((f) => {
        if (f.id === updatedFeature.id) {
            return {
                geometry: updatedFeature.geometry,
                type: updatedFeature.type,
                id: updatedFeature.id,
                properties: f.properties,
            };
        }
        else {
            return f;
        }
    });
    const newGeojson = Object.assign(Object.assign({}, aoisFeatureCollection), { features: updatedFeatures });
    setAoisFeatureCollection(newGeojson);
};
