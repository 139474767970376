import { createSelector, createSlice } from '@reduxjs/toolkit';
import colorbrewer from 'colorbrewer';
import { layersSelectedIndexState } from '@Data/layers/Reducer';
import { initialSymbologyState } from './State';
const SymbologySlice = createSlice({
    name: 'Symbology',
    initialState: initialSymbologyState,
    reducers: {
        reset() {
            return initialSymbologyState;
        },
        initialize(state, action) {
            state.algorithms = action.payload.algorithms;
            state.selectedDataClass = action.payload.initialDataClass;
            const defaultAlgorithm = action.payload.algorithms.includes("NaturalBreaks")
                ? "NaturalBreaks"
                : action.payload.initialAlgorithm;
            state.selectedAlgorithm = defaultAlgorithm || action.payload.initialAlgorithm;
            state.rawData = action.payload.rawData;
            state.minMaxIndices = action.payload.minMaxIndices;
        },
        setSelectedAlgorithm(state, action) {
            state.selectedAlgorithm = action.payload;
        },
        setSelectedClass(state, action) {
            state.selectedDataClass = action.payload;
        },
        setSelectedColorBrew(state, action) {
            state.selectedColorBrew = action.payload;
        },
        setOpacity(state, action) {
            state.opacity = action.payload;
        },
        toggleExtrusion(state) {
            state.extrude = !state.extrude;
        },
        setExtrusionFactor(state, action) {
            state.extrusionFactor = action.payload;
        },
    },
});
export const SymbologyActions = SymbologySlice.actions;
export const selectedDataClassState = (state) => state.symbology.selectedDataClass;
export const selectedAlgorithmState = (state) => state.symbology.selectedAlgorithm;
export const algorithmsState = (state) => state.symbology.algorithms;
const rawDataState = (state) => state.symbology.rawData;
const minMaxIndicesState = (state) => state.symbology.minMaxIndices;
export const selectedColorBrewState = (state) => state.symbology.selectedColorBrew;
export const selectedSymbologyState = createSelector(rawDataState, layersSelectedIndexState, selectedDataClassState, selectedAlgorithmState, minMaxIndicesState, (rawData, selectedIndex, selectedBreaks, selectedAlgorithm, minMaxIndices) => {
    if (!rawData ||
        !selectedIndex ||
        !selectedBreaks ||
        !selectedAlgorithm ||
        !minMaxIndices) {
        return null;
    }
    try {
        const breaks = rawData[selectedIndex.spectral_index][selectedBreaks.toString()][selectedAlgorithm];
        const selectedMinMaxByIndex = minMaxIndices.find((mmi) => mmi.asi_short_name === selectedIndex.spectral_index);
        return [
            selectedMinMaxByIndex.min,
            ...breaks,
            selectedMinMaxByIndex.max,
        ];
    }
    catch (e) {
        return null;
    }
});
export const colorRangesState = createSelector(selectedDataClassState, selectedColorBrewState, (breaks, selectedBrew) => {
    if (!breaks || !selectedBrew)
        return null;
    return colorbrewer[selectedBrew.value][breaks + 1];
});
export const opacityState = (state) => state.symbology.opacity;
export const colorRangesBreaksState = createSelector(selectedSymbologyState, colorRangesState, (symbology, colorRanges) => {
    if (!symbology || !colorRanges)
        return [];
    const output = [];
    for (let i = 0; i < symbology.length - 1; i += 1) {
        const min = symbology[i];
        const max = symbology[i + 1];
        output.push({
            min: min,
            max: max,
            color: colorRanges[i],
            id: `${i}${min}${max}`,
        });
    }
    return output;
});
export const extrusionConstantState = createSelector(minMaxIndicesState, layersSelectedIndexState, (minMaxIndices, selectedIndex) => {
    var _a;
    if (!minMaxIndices || !selectedIndex)
        return 0;
    const selectedMinMax = minMaxIndices === null || minMaxIndices === void 0 ? void 0 : minMaxIndices.find((mmi) => mmi.asi_short_name === selectedIndex.spectral_index);
    return (_a = Math.abs(selectedMinMax === null || selectedMinMax === void 0 ? void 0 : selectedMinMax.min)) !== null && _a !== void 0 ? _a : 0;
});
export const extrudeState = (state) => state.symbology.extrude;
export const extrusionFactorState = (state) => state.symbology.extrusionFactor;
export default SymbologySlice.reducer;
