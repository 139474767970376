export const Analytics = {
    CreateJob: {
        DialogOpened: 'CreateJob_WizardOpened',
        DialogClosed: 'CreateJob_WizardClosed',
        AoiAdded: 'CreateJob_AoiAdded',
        GeoJsonImported: 'CreateJob_GeoJsonImported',
        JobCreated: 'CreateJob_JobCreated',
    },
    EditJob: {
        RecipientsEdited: 'EditJob_RecipientsEdited',
        MonitoringStatusToggled: 'EditJob_MonitoringStatusToggled',
    },
    JobResults: {
        DownloadedPdf: 'JobResults_DownloadedPdf',
        DownloadedZip: 'JobResults_DownloadedZip',
    },
};
