import { store } from '@Services/redux';
import { MapToolbarActions } from './Reducer';
export function resetMapToolbarStates() {
    store.dispatch(MapToolbarActions.reset());
}
export function setMapDrawState(newState = 'simple_select') {
    store.dispatch(MapToolbarActions.setMapDrawState(newState));
}
export function updateGeocoderLocation(geocoderFeature) {
    store.dispatch(MapToolbarActions.setGeocoderLocationState(geocoderFeature));
}
export function setLineFeatureCollection(newState) {
    store.dispatch(MapToolbarActions.setLineFeatureCollectionState(newState));
}
export function setAoisFeatureCollection(newState) {
    store.dispatch(MapToolbarActions.setAoisFeatureCollectionState(newState));
}
export function setIsCreateJobDialogOpen(newState) {
    store.dispatch(MapToolbarActions.setIsCreateJobDialogOpenState(newState));
}
export function setDeleteAoiId(newState) {
    store.dispatch(MapToolbarActions.setDeleteAoiIdState(newState));
}
export function setSelectedAoiId(newState) {
    store.dispatch(MapToolbarActions.setSelectedAoiIdState(newState));
}
