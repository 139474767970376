import rewind from '@mapbox/geojson-rewind';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import '@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css';
import { CircularProgress } from '@mui/material';
import area from '@turf/area';
import bbox from '@turf/bbox';
import React, { createRef } from 'react';
import { Map as BaseMap, Layer, NavigationControl, Source, } from 'react-map-gl/maplibre';
import { ResizeContainer } from '@Components/Containers';
import { BasemapSelectorControl } from '@Components/Map/Controls/BasemapSelectorControl';
import GeocoderControl from '@Components/Map/Controls/GeocoderControl';
import '@Components/Map/Controls/GeocoderControl.css';
import { MapsContext } from '@Services/maps';
import Styles from './AddAreaOfInterestMap.scss';
const initialViewState = {
    latitude: -41.39055295087553,
    longitude: 171.74532637216453,
    zoom: 4.2,
};
export class AddAreasOfInterestMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
        this.mapRef = createRef();
        this.setDrawState = this.setDrawState.bind(this);
        this.handleLoad = this.handleLoad.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.getHasLoaded = this.getHasLoaded.bind(this);
        this.zoomToBounds = this.zoomToBounds.bind(this);
    }
    getHasLoaded() {
        var _a;
        return (!this.state.isLoading &&
            ((_a = this.mapRef.current) === null || _a === void 0 ? void 0 : _a.hasControl(this.drawControl)));
    }
    setDrawState(drawState) {
        if (this.getHasLoaded()) {
            this.drawControl.changeMode(drawState);
        }
    }
    handleLoad() {
        this.drawControl = new MapboxDraw({
            displayControlsDefault: false,
        });
        this.mapRef.current.addControl(this.drawControl, 'top-right');
        this.mapRef.current.on('draw.create', this.handleAdd);
        this.mapRef.current.on('draw.update', this.handleUpdate);
        this.setState({ isLoading: false }, () => {
            if (this.props.features && this.props.features.length > 0) {
                this.zoomToBounds(this.props.features);
            }
        });
    }
    handleUpdate(e) {
        const updatedFeature = e.features[0];
        const foundFeature = this.props.features.find((f) => updatedFeature.id === f.id);
        updatedFeature.properties = foundFeature.properties;
        updatedFeature.properties.area = area(updatedFeature);
        this.props.onUpdateFeature(updatedFeature);
    }
    handleAdd(e) {
        const newFeature = e.features[0];
        newFeature.properties.name = '';
        rewind(newFeature, true);
        newFeature.properties.area = area(newFeature);
        this.props.onAddFeature(newFeature);
        this.drawControl.deleteAll();
    }
    handleDelete(id) {
        this.drawControl.delete(id.toString());
    }
    zoomToBounds(features) {
        const featureCollection = {
            type: 'FeatureCollection',
            features: features,
        };
        const area = bbox(featureCollection);
        this.mapRef.current.fitBounds(area, {
            padding: 25,
        });
    }
    render() {
        const featureCollection = {
            type: 'FeatureCollection',
            features: this.props.features,
        };
        return (React.createElement(React.Fragment, null,
            React.createElement(MapsContext.Consumer, null, (context) => (React.createElement(ResizeContainer, { className: Styles.mapContainer, onResize: () => { var _a; return (_a = this.mapRef.current) === null || _a === void 0 ? void 0 : _a.resize(); } },
                this.state.isLoading && (React.createElement(CircularProgress, { className: Styles.progress })),
                React.createElement(BaseMap, { id: "AreasOfInterestMap", initialViewState: initialViewState, mapStyle: context.selectedBasemap.style, ref: this.mapRef, onLoad: this.handleLoad },
                    React.createElement(Source, { type: "geojson", key: "source", data: featureCollection },
                        React.createElement(Layer, { key: "layer", type: "fill", paint: {
                                'fill-color': ['get', 'color'],
                                'fill-opacity': 0.5,
                                'fill-outline-color': '#999',
                            } })),
                    React.createElement(GeocoderControl, { position: "top-left" }),
                    React.createElement(NavigationControl, { position: "top-left", showZoom: true }))))),
            React.createElement(BasemapSelectorControl, { position: "top-right" })));
    }
}
