import calendarIcon from '@Icons/md/calendar.svg';
import layersIcon from '@Icons/md/layers.svg';
import mapIcon from '@Icons/md/map.svg';
import { BaseCard, MainContainer, Page, PageContent, } from '@orbica/component-sdk';
import { PrimaryButton } from '@orbica/component-sdk';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import cx from 'classnames';
import React from 'react';
import { Header } from '@Components/Header/Header';
import { Tabs } from '@Components/Tabs/Tabs';
import { Navigation } from '@Services/navigation/Navigation';
import Styles from './SplashView.scss';
import { ViewCard } from './components/ViewCard';
export const SplashView = () => {
    return (React.createElement(Page, null,
        React.createElement(Header, null,
            React.createElement(Tabs, null)),
        React.createElement(PageContent, null,
            React.createElement(MainContainer, { GridContainerProps: { className: Styles.container } },
                React.createElement("div", { className: Styles.contentContainer },
                    React.createElement("div", { className: cx(GridContentStyles.max, Styles.header) },
                        React.createElement("span", { className: TypographyStyles.display }, "Environmental Monitoring"),
                        React.createElement("span", { className: TypographyStyles.largeLight }, "Empowering you with advanced tools to monitor, analyse, and interpret environmental data seamlessly.")),
                    React.createElement(BaseCard, { className: Styles.cardContainer },
                        React.createElement("div", { className: Styles.patter },
                            React.createElement("span", null, "Harness the power of advanced technology to monitor and analyse the environment effortlessly."),
                            React.createElement("span", null, "Our platform is designed to provide you with comprehensive tools to manage, explore, and interpret environmental data with ease.")),
                        React.createElement(ViewCard, { title: "Schedule", icon: calendarIcon, description: 'Plan and automate your monitoring tasks seamlessly. Schedule jobs to run at your convenience, ensuring you never miss a critical observation.', href: Navigation.schedule.path }),
                        React.createElement(ViewCard, { title: "Explore", icon: mapIcon, description: 'Dive into the world of Sentinel-2 data. Navigate through imagery and detailed environmental data to gain valuable insights into the areas that matter to you.', href: Navigation.explore.preExplore.path }),
                        React.createElement(ViewCard, { title: "Results", icon: layersIcon, description: 'View and analyse your results in one place. Our intuitive interface allows you to explore your data, generate reports, and make informed decisions based on accurate, real-time information.', href: Navigation.explore.results.path }),
                        React.createElement("div", { className: Styles.docsContainer },
                            React.createElement(PrimaryButton, { href: "https://docs.orbica.com", target: "_blank", displayChevron: true }, "Read the Help Pages"))))))));
};
