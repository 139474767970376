import React, { useState } from 'react';
import { useGetJobsQuery } from '@Data/jobs/Api';
import { getBasicOrderBy } from '@Services/helpers/GqlRest';
import { JobsList } from '../JobsList/JobsList';
export const JobsController = (props) => {
    const [sorting, setSorting] = useState([
        { id: 'created_at', desc: true },
    ]);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState('');
    const { data, isLoading: areJobsLoading } = useGetJobsQuery({
        limit: limit,
        offset: offset,
        order_by: getBasicOrderBy(sorting),
        filter: filter,
    }, {
        pollingInterval: 5000,
        skipPollingIfUnfocused: true,
    });
    const { jobs, count } = data !== null && data !== void 0 ? data : { jobs: [], count: 0 };
    const handleSetFilter = (filter) => {
        setFilter(filter);
        setOffset(0);
    };
    return (React.createElement(JobsList, { onCreateJob: props.onCreateJob, onEditJob: props.onEditJob, onDeleteJob: props.onDeleteJob, jobs: jobs, isLoading: areJobsLoading, sorting: sorting, onSetSorting: setSorting, limit: limit, offset: offset, onSetLimit: (l) => setLimit(l), onSetOffset: (o) => setOffset(o), count: count, onSetFilter: (f) => handleSetFilter(f), filter: filter }));
};
