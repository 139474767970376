import LayoutStyles from '@Styles/layout.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { Switch } from '@orbica/component-sdk';
import React, { useState } from 'react';
import { EntityAttributeCard } from '@Components/Cards';
import { ToggleMonitoringStatusDialog } from '@Components/Dialogs/ToggleMonitoringStatusDialog';
import { SpectralIndexPill } from '@Components/EntityDetails/SpectralIndexPill';
import { ThemeAttributeCard } from '@Components/Themes/ThemeAttributeCard/ThemeAttributeCard';
import { getDateStringFromTimestamp } from '@Services/helpers/Dates';
import Styles from './JobDescription.scss';
export const JobDescription = ({ selectedJob, isLoading }) => {
    var _a, _b, _c;
    const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
    const { user } = useAuth0();
    const indexGridColumns = 2;
    (selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.job_spectral_indices.length) > 2 ? 4 : 2;
    const descriptionGridColumns = ((_a = selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.description) === null || _a === void 0 ? void 0 : _a.length) > 30 ? 4 : 2;
    const canEdit = !(selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.is_demo) && (selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.created_by_id) === ((_b = user === null || user === void 0 ? void 0 : user.user) === null || _b === void 0 ? void 0 : _b.id);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: LayoutStyles.entityDescriptionContainer },
            React.createElement(EntityAttributeCard, { isLoading: isLoading, title: "Provider", value: selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.platform.name }),
            React.createElement(EntityAttributeCard, { isLoading: isLoading, title: "Last Run", value: getDateStringFromTimestamp(selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.last_run_date) }),
            React.createElement(EntityAttributeCard, { isLoading: isLoading, title: "Next Run", value: getDateStringFromTimestamp(selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.next_run_date) }),
            React.createElement(EntityAttributeCard, { isLoading: isLoading, title: "Monitoring Interval", value: `Every ${selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.cadence} days` }),
            React.createElement(EntityAttributeCard, { isLoading: isLoading, title: "Monitoring Status", value: React.createElement(Switch, { onChange: () => setIsStatusDialogOpen(true), checked: selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.is_active, tooltip: canEdit ? 'Toggle monitoring status' : '', disabled: !canEdit }) }),
            React.createElement(EntityAttributeCard, { isLoading: isLoading, title: "Monitoring Time Range", value: `${getDateStringFromTimestamp(selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.start_date)} - ${getDateStringFromTimestamp(selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.end_date)}` }),
            (selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.theme.name) === 'custom' && (React.createElement(EntityAttributeCard, { isLoading: isLoading, title: `Monitoring ${(selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.job_spectral_indices.length) > 1
                    ? 'Indices'
                    : 'Index'}`, gridColumns: indexGridColumns, value: React.createElement("div", { className: Styles.indicesContainer }, selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.job_spectral_indices.map((index) => (React.createElement(SpectralIndexPill, { key: `${selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.id}${index.index_key}`, shortName: index.index_key })))) })),
            ((_c = selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.theme) === null || _c === void 0 ? void 0 : _c.name) !== 'custom' && (React.createElement(ThemeAttributeCard, { isLoading: isLoading, theme: selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.theme })),
            (selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.description) && (React.createElement(EntityAttributeCard, { isLoading: isLoading, title: "Description", gridColumns: descriptionGridColumns, value: React.createElement("span", { className: Styles.descriptionSpan, title: selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.description }, selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.description) }))),
        React.createElement(ToggleMonitoringStatusDialog, { isOpen: isStatusDialogOpen, onClose: () => setIsStatusDialogOpen(false), job: selectedJob })));
};
