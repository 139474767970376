import { Select } from '@orbica/component-sdk';
import { SelectOption } from '@orbica/component-sdk';
import { splitCamelCase } from '@orbica/component-sdk';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import { setSelectedAlgorithm } from '@Data/symbology/Api';
import { algorithmsState, selectedAlgorithmState, } from '@Data/symbology/Reducer';
export const AlgorithmSelect = () => {
    const selectedAlgorithm = useSelector(selectedAlgorithmState);
    const algorithms = useSelector(algorithmsState);
    const handleChange = (e, value) => {
        setSelectedAlgorithm(value);
    };
    return (!!algorithms && (React.createElement(Select, { containerClassName: GridContentStyles.max, value: selectedAlgorithm, placeholder: "Select Grouping", onChange: handleChange, label: "Grouping", renderValue: (algorithm) => splitCamelCase(algorithm), size: "small" }, algorithms.map((algorithm) => (React.createElement(SelectOption, { value: algorithm, key: `${algorithm}option` }, splitCamelCase(algorithm)))))));
};
