import { IconButton } from '@orbica/component-sdk';
import _isNil from 'lodash/isNil';
import React, { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { getControlContainer } from './helpers';
let hasLoaded = false;
export const CustomControl = (props) => {
    const controlContainer = getControlContainer(props.position);
    if (!_isNil(controlContainer) && !hasLoaded) {
        controlContainer.innerHTML = '';
        hasLoaded = true;
    }
    const html = useMemo(() => {
        return hasLoaded ? (React.createElement(IconButton, { IconProps: {
                icon: props.icon,
            }, onClick: props.onClick, backgroundColor: "#fff", className: "custom-maplibregl-control", tooltip: props.tooltip })) : null;
    }, [hasLoaded]);
    return !_isNil(controlContainer) && createPortal(html, controlContainer);
};
