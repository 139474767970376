import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css';
import bbox from '@turf/bbox';
import _debounce from 'lodash/debounce';
import _isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AttributionControl, Map as BaseMap, Layer, NavigationControl, Source, } from 'react-map-gl/maplibre';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { GeocoderMarker } from '@Components/ToolBar/GeocoderMarker';
import { layersMappedSelectedIndexState } from '@Data/layers/Reducer';
import { convertArrayFormat } from '@Data/layers/helpers';
import { setIsCreateJobDialogOpen, setMapDrawState, } from '@Data/mapToolbar/Api';
import { aoisFeatureCollectionState, deleteAoiIdState, drawState, geocoderLocationState, isCreateJobDialogOpenState, lineFeatureCollectionState, selectedAoiIdState, } from '@Data/mapToolbar/Reducer';
import { opacityState } from '@Data/symbology/Reducer';
import { NewZealandViewState, lngLatBoundsToBbox } from '@Services/helpers/Gis';
import { MapsContext } from '@Services/maps';
import { handleAdd, handleUpdate } from '@Views/Explore/views/helpers';
import { CreateJobDialog } from '@Views/Schedule/views/Jobs/components/Dialogs';
import Styles from './PreExploreMap.scss';
const initialViewState = NewZealandViewState;
export const PreExploreMap = (props) => {
    const mapRef = useRef(null);
    const mapsContext = useContext(MapsContext);
    const [renderIndex, setRenderIndex] = useState(0);
    const [drawControl, setDrawControl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const geocoderLocation = useSelector(geocoderLocationState);
    const [mapStyle, setMapStyle] = useState(null);
    const mapDrawState = useSelector(drawState);
    const aoisFeatureCollection = useSelector(aoisFeatureCollectionState);
    const lineFeatureCollection = useSelector(lineFeatureCollectionState);
    const isCreateJobDialogOpen = useSelector(isCreateJobDialogOpenState);
    const deleteAoiId = useSelector(deleteAoiIdState);
    const selectedAoiId = useSelector(selectedAoiIdState);
    const { ref } = useResizeDetector({
        onResize: () => {
            var _a;
            if (mapRef.current) {
                (_a = mapRef.current) === null || _a === void 0 ? void 0 : _a.resize();
            }
        },
    });
    const [colorMap, setColorMap] = useState(null);
    const opacity = useSelector(opacityState);
    const mappedSelectedIndex = useSelector(layersMappedSelectedIndexState);
    useEffect(() => {
        const convertedSelectedIndex = convertArrayFormat(mappedSelectedIndex);
        setColorMap(encodeURIComponent(JSON.stringify(convertedSelectedIndex)));
    }, [mappedSelectedIndex]);
    useEffect(() => {
        return () => {
            mapsContext.onSetZoomLevel(null);
            mapsContext.onSetBounds(null);
        };
    }, []);
    const handleSetZoomLevel = _debounce((e) => {
        if (e.type === 'load') {
            mapsContext.onSetZoomLevel(initialViewState.zoom);
        }
        else {
            mapsContext.onSetZoomLevel(e.viewState.zoom);
        }
        if (mapRef.current) {
            const lngLat = mapRef.current.getBounds();
            mapsContext.onSetBounds(lngLatBoundsToBbox(lngLat));
        }
    }, 1000);
    const sourceData = useMemo(() => {
        if (_isNil(props.collectionIds) ||
            props.collectionIds.length === 0 ||
            _isNil(props.selectedLayer)) {
            return null;
        }
        const sourceId = props.collectionIds.map((c) => c.id).join('');
        let layerUrls;
        if (props.selectedLayer.type === 'index') {
            if (!_isNil(props.selectedLayer.index)) {
                const formula = encodeURIComponent(props.selectedLayer.index.sentinel2a_formula);
                layerUrls = props.collectionIds.map((collectionId) => `https://${process.env.URL_PREFIX}titiler.${process.env.BASE_URL}/stac/tiles/WebMercatorQuad/{z}/{x}/{y}@1x.png?url=https%3A%2F%2Fearth-search.aws.element84.com%2Fv1%2Fcollections%2Fsentinel-2-c1-l2a%2Fitems%2F${collectionId.id}&bidx=1&assets=data&assets=cog&expression=${formula}&asset_bidx=data%7C1%2C2%2C3&asset_bidx=cog%7C1&asset_as_band=true&unscale=true&resampling=nearest&reproject=nearest&colormap=${colorMap}&return_mask=true`);
            }
        }
        else {
            if (!_isNil(props.selectedLayer.comparisonLayer)) {
                layerUrls = props.collectionIds.map((collectionId) => `https://${process.env.URL_PREFIX}titiler.${process.env.BASE_URL}/stac/tiles/WebMercatorQuad/{z}/{x}/{y}@1x.png?url=https%3A%2F%2Fearth-search.aws.element84.com%2Fv1%2Fcollections%2Fsentinel-2-c1-l2a%2Fitems%2F${collectionId.id}${props.selectedLayer.comparisonLayer.urlTemplate}`);
            }
        }
        if (!_isNil(layerUrls)) {
            setRenderIndex(renderIndex + 1);
            return {
                sourceId,
                layerUrls,
            };
        }
        return null;
    }, [props.collectionIds, props.selectedLayer, colorMap]);
    const handleLoad = () => {
        setIsLoading(false);
        const drawCtrl = new MapboxDraw({
            displayControlsDefault: false,
        });
        setDrawControl(drawCtrl);
        mapRef.current.addControl(drawCtrl, 'top-right');
        handleSetZoomLevel;
    };
    useEffect(() => {
        if (lineFeatureCollection &&
            lineFeatureCollection.features.length > 0) {
            const featureId = lineFeatureCollection.features[0]['id'];
            drawControl === null || drawControl === void 0 ? void 0 : drawControl.delete(featureId);
        }
    }, [lineFeatureCollection]);
    useEffect(() => {
        if (!_isNil(deleteAoiId) && !_isNil(drawControl)) {
            drawControl.delete(deleteAoiId);
        }
    }, [deleteAoiId]);
    useEffect(() => {
        if (!_isNil(drawControl)) {
            if (mapDrawState === 'delete_polygon') {
                drawControl.deleteAll();
                setMapDrawState();
            }
            else {
                drawControl.changeMode(mapDrawState);
            }
        }
    }, [mapDrawState, drawControl]);
    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.on('draw.create', (e) => handleAdd(e, aoisFeatureCollection, lineFeatureCollection));
            mapRef.current.on('draw.update', (e) => handleUpdate(e, aoisFeatureCollection));
        }
    }, [drawControl, aoisFeatureCollection]);
    const handleCloseDialog = () => {
        setIsCreateJobDialogOpen(false);
    };
    const zoomToBounds = (features) => {
        const featureCollection = {
            type: 'FeatureCollection',
            features: features,
        };
        const area = bbox(featureCollection);
        mapRef.current.fitBounds(area, {
            padding: 25,
        });
    };
    useEffect(() => {
        if (mapRef.current) {
            const aoi = aoisFeatureCollection === null || aoisFeatureCollection === void 0 ? void 0 : aoisFeatureCollection.features.filter((feature) => {
                return feature.id === selectedAoiId;
            });
            zoomToBounds([...aoi]);
        }
    }, [selectedAoiId]);
    useEffect(() => {
        if (mapRef.current && geocoderLocation) {
            mapRef.current.flyTo({
                center: geocoderLocation['center'],
                zoom: 15,
            });
        }
    }, [geocoderLocation]);
    useEffect(() => {
        setMapStyle(mapsContext.selectedBasemap.style);
    }, [mapsContext.selectedBasemap]);
    const customAttribution = useMemo(() => {
        if (_isNil(props.collectionIds) || props.collectionIds.length === 0)
            return null;
        const year = DateTime.fromISO(props.collectionIds[0].date).year;
        return `Contains modified Copernicus Sentinel data ${year} for Sentinel data`;
    }, [props.collectionIds]);
    return (React.createElement("div", { ref: ref, className: Styles.mapContainer },
        React.createElement(BaseMap, { mapStyle: mapStyle, ref: mapRef, onZoomEnd: handleSetZoomLevel, onLoad: handleLoad, attributionControl: false, initialViewState: initialViewState },
            React.createElement(AttributionControl, { customAttribution: customAttribution, key: customAttribution }),
            !_isNil(lineFeatureCollection) && (React.createElement(Source, { type: "geojson", data: lineFeatureCollection },
                React.createElement(Layer, { id: "line-string-layer", type: "line", paint: {
                        'line-color': 'red',
                        'line-width': 6,
                        'line-dasharray': [2, 0.5],
                    } }))),
            React.createElement(Source, { type: "geojson", id: "geojson-source", key: `geojson-source-${JSON.stringify(aoisFeatureCollection).length}`, data: aoisFeatureCollection },
                React.createElement(Layer, { id: "polygon-layer", type: "fill", beforeId: "line-string-layer", paint: {
                        'fill-outline-color': '#3bb2d0',
                        'fill-color': 'gray',
                        'fill-opacity': 0.1,
                    } }),
                React.createElement(Layer, { id: "polygon-outline-layer", type: "line", beforeId: "line-string-layer", paint: {
                        'line-color': '#3bb2d0',
                        'line-width': 3,
                    } })),
            !_isNil(sourceData) && (React.createElement(Source, { key: `${sourceData.sourceId}${renderIndex}`, id: sourceData.sourceId, type: "raster", tiles: sourceData.layerUrls },
                React.createElement(Layer, { type: "raster", paint: { 'raster-opacity': opacity }, beforeId: "polygon-layer" }))),
            React.createElement(NavigationControl, { position: "top-left", showZoom: true }),
            React.createElement(GeocoderMarker, null)),
        React.createElement(CreateJobDialog, { isOpen: isCreateJobDialogOpen, onClose: handleCloseDialog, areaOfInterestFeatures: aoisFeatureCollection === null || aoisFeatureCollection === void 0 ? void 0 : aoisFeatureCollection.features })));
};
