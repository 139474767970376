import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import cx from 'classnames';
import React from 'react';
import Styles from './UserMenu.scss';
const UserDetails = (props) => {
    return (React.createElement("div", { className: Styles.userDetailsContainer },
        React.createElement("img", { src: props.avatarUrl, alt: "auth0 avatar" }),
        React.createElement("div", { className: Styles.userDetailsDetailsContainer },
            React.createElement("span", { className: TypographyStyles.baseBold }, props.email),
            props.organization && (React.createElement("span", { className: cx(TypographyStyles.smallRegular, Styles.orgName) }, props.organization)))));
};
export default UserDetails;
