import { Skeleton } from '@mui/material';
import React from 'react';
import { ThemeInformationCard } from '@Components/Themes/ThemeInformationCard/ThemeInformationCard';
import Styles from './ThemeOptions.scss';
export const ThemeOptions = (props) => {
    var _a;
    const handleClick = (theme) => {
        props.onSelectTheme(theme);
    };
    if (props.isLoading) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Skeleton, { className: Styles.skeleton, height: 150, variant: "rounded" }),
            React.createElement(Skeleton, { className: Styles.skeleton, height: 150, variant: "rounded" }),
            React.createElement(Skeleton, { className: Styles.skeleton, height: 150, variant: "rounded" })));
    }
    return (_a = props.themes) === null || _a === void 0 ? void 0 : _a.map((theme) => (React.createElement(ThemeInformationCard, { theme: theme, key: theme.id, isSelected: props.theme === theme, onClick: () => handleClick(theme) })));
};
