var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Tooltip } from '@orbica/component-sdk';
import { geoMercator, geoPath } from 'd3-geo';
import { select } from 'd3-selection';
import _isNil from 'lodash/isNil';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
const DEFAULT_DIMENSION = 45;
const DEFAULT_COLOR = '#999';
const SvgContainer = styled('div')(({ $pointer }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    ${$pointer ? 'cursor: pointer;' : ''}
`);
export const AreaOfInterestOutline = (_a) => {
    var { color = DEFAULT_COLOR, dimensions = { height: DEFAULT_DIMENSION, width: DEFAULT_DIMENSION }, feature, onClick, tooltip } = _a, containerProps = __rest(_a, ["color", "dimensions", "feature", "onClick", "tooltip"]);
    const svgGRef = useRef(null);
    const svgRef = useRef(null);
    useEffect(() => {
        if (svgGRef.current && feature) {
            const projection = geoMercator().fitSize([dimensions.width, dimensions.height], feature);
            svgGRef.current.innerHTML = null;
            select(svgGRef.current)
                .append('path')
                .style('height', dimensions.height)
                .style('width', dimensions.width)
                .datum(feature)
                .attr('d', geoPath().projection(projection))
                .style('fill', color)
                .style('fill-opacity', '0.8')
                .style('stroke-width', '1')
                .style('stroke', DEFAULT_COLOR)
                .style('stroke-opacity', '1');
        }
    }, [feature, dimensions, svgGRef.current, color]);
    const output = (React.createElement(SvgContainer, Object.assign({}, containerProps, { "$pointer": !_isNil(onClick), onClick: onClick }),
        React.createElement("svg", { height: dimensions.height, width: dimensions.width, ref: svgRef },
            React.createElement("g", { ref: svgGRef }))));
    return tooltip ? React.createElement(Tooltip, { title: tooltip }, output) : output;
};
