var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import MaplibreGeocoder from '@maplibre/maplibre-gl-geocoder';
import * as React from 'react';
import { useState } from 'react';
import { Marker, useControl } from 'react-map-gl';
export default function GeocoderControl(props) {
    const [marker, setMarker] = useState(null);
    const GeoApi = {
        forwardGeocode: (config) => __awaiter(this, void 0, void 0, function* () {
            const features = [];
            try {
                const request = `https://nominatim.openstreetmap.org/search?q=${config.query}&format=geojson&polygon_geojson=1&addressdetails=1`;
                const response = yield fetch(request);
                const geojson = yield response.json();
                for (const feature of geojson.features) {
                    const center = [
                        feature.bbox[0] +
                            (feature.bbox[2] - feature.bbox[0]) / 2,
                        feature.bbox[1] +
                            (feature.bbox[3] - feature.bbox[1]) / 2,
                    ];
                    const point = {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: center,
                        },
                        place_name: feature.properties.display_name,
                        properties: feature.properties,
                        text: feature.properties.display_name,
                        place_type: ['place'],
                        center,
                    };
                    features.push(point);
                }
            }
            catch (e) {
                console.error(`Failed to forwardGeocode with error: ${e}`);
            }
            return {
                props,
                features,
            };
        }),
    };
    const geocoder = useControl(() => {
        const ctrl = new MaplibreGeocoder(GeoApi, {
            marker: false,
        });
        ctrl.on('loading', props.onLoading);
        ctrl.on('results', props.onResults);
        ctrl.on('result', (evt) => {
            var _a;
            props.onResult(evt);
            const { result } = evt;
            const location = result &&
                (result.center ||
                    (((_a = result.geometry) === null || _a === void 0 ? void 0 : _a.type) === 'Point' &&
                        result.geometry.coordinates));
            if (location && props.marker) {
                setMarker(React.createElement(Marker, { longitude: location[0], latitude: location[1] }));
            }
            else {
                setMarker(null);
            }
        });
        ctrl.on('error', props.onError);
        return ctrl;
    }, {
        position: props.position,
    });
    if (geocoder._map) {
        if (geocoder.getProximity() !== props.proximity &&
            props.proximity !== undefined) {
            geocoder.setProximity(props.proximity);
        }
        if (geocoder.getRenderFunction() !== props.render &&
            props.render !== undefined) {
            geocoder.setRenderFunction(props.render);
        }
        if (geocoder.getLanguage() !== props.language &&
            props.language !== undefined) {
            geocoder.setLanguage(props.language);
        }
        if (geocoder.getZoom() !== props.zoom && props.zoom !== undefined) {
            geocoder.setZoom(props.zoom);
        }
        if (geocoder.getFlyTo() !== props.flyTo && props.flyTo !== undefined) {
            geocoder.setFlyTo(props.flyTo);
        }
        if (geocoder.getPlaceholder() !== props.placeholder &&
            props.placeholder !== undefined) {
            geocoder.setPlaceholder(props.placeholder);
        }
        if (geocoder.getCountries() !== props.countries &&
            props.countries !== undefined) {
            geocoder.setCountries(props.countries);
        }
        if (geocoder.getTypes() !== props.types && props.types !== undefined) {
            geocoder.setTypes(props.types);
        }
        if (geocoder.getMinLength() !== props.minLength &&
            props.minLength !== undefined) {
            geocoder.setMinLength(props.minLength);
        }
        if (geocoder.getLimit() !== props.limit && props.limit !== undefined) {
            geocoder.setLimit(props.limit);
        }
        if (geocoder.getFilter() !== props.filter &&
            props.filter !== undefined) {
            geocoder.setFilter(props.filter);
        }
    }
    return marker;
}
const noop = () => {
};
GeocoderControl.defaultProps = {
    marker: false,
    onLoading: noop,
    onResults: noop,
    onResult: noop,
    onError: noop,
};
