import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import cx from 'classnames';
import React from 'react';
import styled from 'styled-components';
const StyledParagraph = styled('div') `
    white-space: pre-wrap;
`;
export const TitleAndDescription = (props) => {
    let description = props.description;
    if (description && Array.isArray(description)) {
        const descArray = description;
        description = descArray.map((d, i) => `${d}${i + 1 !== descArray.length ? '\n' : ''}`);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { className: cx(GridContentStyles.max, TypographyStyles.baseBold) }, props.title),
        description && (React.createElement(StyledParagraph, { className: cx(GridContentStyles.max, TypographyStyles.baseRegular) }, description))));
};
