import _isNil from 'lodash/isNil';
import React, { useContext, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl/maplibre';
import { useSelector } from 'react-redux';
import { runResultRasterLayersState } from '@Data/runResults/Reducer';
import { colorRangesBreaksState, opacityState } from '@Data/symbology/Reducer';
import { selectedTimestampIndexState } from '@Data/time/Reducer';
import { WEB_API_URL } from '@Services/redux';
import { ExploreResultsContext } from '@Views/Explore/views/Results/ResultsContext/ResultsContext';
export const RasterLayer = (props) => {
    const rasterLayers = useSelector(runResultRasterLayersState);
    const selectedTimestampIndex = useSelector(selectedTimestampIndexState);
    const resultsContext = useContext(ExploreResultsContext);
    const colorRangesBreaks = useSelector(colorRangesBreaksState);
    const opacity = useSelector(opacityState);
    const colorMap = useMemo(() => {
        const mappedValues = colorRangesBreaks.map((crb) => {
            return [[crb.min, crb.max], crb.color];
        });
        return encodeURIComponent(JSON.stringify(mappedValues));
    }, [colorRangesBreaks]);
    const cogSources = useMemo(() => {
        if (_isNil(rasterLayers))
            return null;
        const selectedRasterLayer = rasterLayers[selectedTimestampIndex];
        if (!colorMap || colorMap.length === 0 || !selectedRasterLayer)
            return null;
        return selectedRasterLayer.index_cog_urls.map((cog, i) => {
            const encodedCog = encodeURIComponent(cog);
            const url = `https://${process.env.URL_PREFIX}titiler.${process.env.BASE_URL}/cog/tiles/WebMercatorQuad/{z}/{x}/{y}.png?scale=1&url=${WEB_API_URL}/data_access/s3/${encodedCog}&bidx=1&expression=b1&unscale=false&resampling=nearest&reproject=nearest&nodata=-9999&return_mask=true&colormap=${colorMap}`;
            const key = `rasterLayer-${selectedTimestampIndex}${cog}${i}${colorMap}`;
            return (React.createElement(Source, { type: "raster", tiles: [url], id: key, key: key },
                React.createElement(Layer, { type: "raster", paint: { 'raster-opacity': opacity }, beforeId: props.afterLayerId })));
        });
    }, [selectedTimestampIndex, rasterLayers, colorMap, opacity]);
    return resultsContext.viewMode === 'raster' && cogSources;
};
