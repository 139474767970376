import { BaseCard } from '@orbica/component-sdk';
import Tokens from '@orbica/component-sdk/build/css/tokens.scss';
import styled from 'styled-components';
export const StyledLayerTreeContainer = styled('div') `
    grid-column: 1/-1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    padding: 1px;
`;
export const StyledThemeIndexCard = styled(BaseCard)(({ $selected }) => `
    grid-column: 1/-1;
    padding: 6px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    user-select: none;

    border: 1px solid ${$selected ? Tokens.primaryGreen : Tokens.borderGrey};

    outline: 1px solid ${$selected ? Tokens.primaryGreen : 'transparent'};

    &:hover {
        background-color: ${Tokens.defaultHover};
    }

    .title {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 8px;
    }
    .description {
        font-size: 10px;
    }
`);
