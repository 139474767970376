import LayoutStyles from '@Styles/layout.scss';
import { Tooltip } from '@orbica/component-sdk';
import React from 'react';
import { AreaOfInterestMap } from '@Components/AreaOfInterest/AreaOfInterestMap';
import { EntityAttributeCard } from '@Components/Cards';
import { AoiRunStatusPill } from '@Components/EntityDetails/AoiRunStatusPill';
import { AreaConversionTooltipTable } from '@Components/Tables';
import { convertAreaFromSqm } from '@Services/helpers/Area';
import { getDateStringFromTimestamp } from '@Services/helpers/Dates';
import Styles from './AoiRunResultDescription.scss';
export const AoiRunResultDescription = ({ selectedAoiRunResult, isLoading, baselineTimeRange, }) => {
    return (React.createElement("div", { className: LayoutStyles.entityDescriptionContainer },
        React.createElement(EntityAttributeCard, { isLoading: isLoading, contentHeight: "100%", value: React.createElement("div", { className: Styles.mapThumbnailContainer },
                React.createElement(AreaOfInterestMap, { feature: selectedAoiRunResult === null || selectedAoiRunResult === void 0 ? void 0 : selectedAoiRunResult.aoi_geom, name: selectedAoiRunResult === null || selectedAoiRunResult === void 0 ? void 0 : selectedAoiRunResult.aoi_name })) }),
        React.createElement(EntityAttributeCard, { isLoading: isLoading, title: "Area", value: React.createElement(Tooltip, { title: React.createElement(AreaConversionTooltipTable, { area: selectedAoiRunResult === null || selectedAoiRunResult === void 0 ? void 0 : selectedAoiRunResult.aoi_area }) },
                React.createElement("span", null, convertAreaFromSqm(selectedAoiRunResult === null || selectedAoiRunResult === void 0 ? void 0 : selectedAoiRunResult.aoi_area))) }),
        React.createElement(EntityAttributeCard, { isLoading: isLoading, title: "Status", value: React.createElement(AoiRunStatusPill, { status: selectedAoiRunResult === null || selectedAoiRunResult === void 0 ? void 0 : selectedAoiRunResult.aoi_run_status }) }),
        React.createElement(EntityAttributeCard, { isLoading: isLoading, title: "Run Date", value: getDateStringFromTimestamp(selectedAoiRunResult === null || selectedAoiRunResult === void 0 ? void 0 : selectedAoiRunResult.aoi_run_run_date) }),
        React.createElement(EntityAttributeCard, { isLoading: isLoading, title: "Latest Image Date", value: getDateStringFromTimestamp(selectedAoiRunResult === null || selectedAoiRunResult === void 0 ? void 0 : selectedAoiRunResult.acquisition_time) }),
        React.createElement(EntityAttributeCard, { isLoading: isLoading, title: "Baseline Date Range", value: baselineTimeRange })));
};
