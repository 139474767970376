import MapboxDraw from '@mapbox/mapbox-gl-draw';
import bbox from '@turf/bbox';
import _debounce from 'lodash/debounce';
import _isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import 'maplibre-gl/dist/maplibre-gl.css';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AttributionControl, Map as BaseMap, Layer, NavigationControl, Popup, Source, } from 'react-map-gl/maplibre';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { H3Layer } from '@Components/Map/H3Layer/H3Layer';
import { RasterLayer } from '@Components/Map/RasterLayer/RasterLayer';
import { TrueColorLayer } from '@Components/Map/TrueColorLayer/TrueColorLayer';
import { GeocoderMarker } from '@Components/ToolBar/GeocoderMarker';
import { setIsCreateJobDialogOpen, setMapDrawState, } from '@Data/mapToolbar/Api';
import { aoisFeatureCollectionState, deleteAoiIdState, drawState, geocoderLocationState, isCreateJobDialogOpenState, lineFeatureCollectionState, selectedAoiIdState, } from '@Data/mapToolbar/Reducer';
import { selectedTimestampState } from '@Data/time/Reducer';
import { NewZealandViewState } from '@Services/helpers/Gis';
import { MapsContext } from '@Services/maps';
import { handleAdd, handleUpdate } from '@Views/Explore/views/helpers';
import { CreateJobDialog } from '@Views/Schedule/views/Jobs/components/Dialogs';
import { ExploreResultsContext } from '../../ResultsContext/ResultsContext';
import Styles from './ResultsMap.scss';
const initialViewState = NewZealandViewState;
const AoiOutlineLayerId = 'aoiOutlineLayer';
export const ResultsMap = () => {
    var _a, _b;
    const { ref } = useResizeDetector({
        onResize: () => {
            var _a;
            (_a = mapRef.current) === null || _a === void 0 ? void 0 : _a.resize();
        },
    });
    const mapRef = useRef(null);
    const mapsContext = useContext(MapsContext);
    const resultsContext = useContext(ExploreResultsContext);
    const selectedTimestamp = useSelector(selectedTimestampState);
    const [drawControl, setDrawControl] = useState(null);
    const [popupInfo, setPopupInfo] = useState(null);
    const mapDrawState = useSelector(drawState);
    const geocoderLocation = useSelector(geocoderLocationState);
    const aoisFeatureCollection = useSelector(aoisFeatureCollectionState);
    const isCreateJobDialogOpen = useSelector(isCreateJobDialogOpenState);
    const lineFeatureCollection = useSelector(lineFeatureCollectionState);
    const selectedAoiId = useSelector(selectedAoiIdState);
    const deleteAoiId = useSelector(deleteAoiIdState);
    const handlePopupClose = () => {
        setPopupInfo(null);
    };
    useEffect(() => {
        var _a;
        if (mapRef.current && mapsContext.bounds) {
            (_a = mapRef.current) === null || _a === void 0 ? void 0 : _a.fitBounds(mapsContext.bounds, {
                padding: 200,
            });
        }
    }, [mapRef.current, mapsContext.bounds]);
    const handleMoveEnd = _debounce(() => {
        var _a;
        if (mapRef.current) {
            resultsContext.onLoadData();
            mapsContext.onSetZoomLevel((_a = mapRef.current) === null || _a === void 0 ? void 0 : _a.getZoom());
        }
    }, 500);
    let layers;
    try {
        layers = (_a = mapRef.current) === null || _a === void 0 ? void 0 : _a.getStyle().layers;
    }
    catch (e) {
    }
    const trueColorBeforeLayerId = useMemo(() => {
        var _a, _b;
        if (_isNil(mapRef.current) ||
            _isNil(layers) ||
            resultsContext.viewMode === 'h3') {
            const firstRasterLayer = layers === null || layers === void 0 ? void 0 : layers.find((l) => l.id === 'line-string-layer');
            return (_a = firstRasterLayer === null || firstRasterLayer === void 0 ? void 0 : firstRasterLayer.id) !== null && _a !== void 0 ? _a : null;
        }
        const firstRasterLayer = layers.find((l) => l.id === 'aoiOutlineLayer');
        return (_b = firstRasterLayer === null || firstRasterLayer === void 0 ? void 0 : firstRasterLayer.id) !== null && _b !== void 0 ? _b : null;
    }, [mapRef.current, layers, resultsContext.viewMode, selectedTimestamp]);
    const customAttribution = useMemo(() => {
        if (_isNil(selectedTimestamp))
            return null;
        const year = DateTime.fromSeconds(selectedTimestamp).year;
        return `Contains modified Copernicus Sentinel data ${year} for Sentinel data`;
    }, [selectedTimestamp]);
    useEffect(() => {
        if (mapRef.current && geocoderLocation) {
            mapRef.current.flyTo({
                center: geocoderLocation['center'],
                zoom: 15,
            });
        }
    }, [geocoderLocation]);
    const handleCloseDialog = () => {
        setIsCreateJobDialogOpen(false);
    };
    const handleLoad = () => {
        const drawCtrl = new MapboxDraw({
            displayControlsDefault: false,
        });
        setDrawControl(drawCtrl);
        mapRef.current.addControl(drawCtrl, 'top-right');
    };
    const zoomToBounds = (features) => {
        const featureCollection = {
            type: 'FeatureCollection',
            features: features,
        };
        const area = bbox(featureCollection);
        mapRef.current.fitBounds(area, {
            padding: 25,
        });
    };
    useEffect(() => {
        if (mapRef.current) {
            const aoi = aoisFeatureCollection.features.filter((feature) => {
                return feature.id === selectedAoiId;
            });
            zoomToBounds([...aoi]);
        }
    }, [selectedAoiId]);
    useEffect(() => {
        if (!_isNil(deleteAoiId) && !_isNil(drawControl)) {
            drawControl.delete(deleteAoiId);
        }
    }, [deleteAoiId]);
    useEffect(() => {
        if (!_isNil(drawControl)) {
            if (mapDrawState === 'delete_polygon') {
                drawControl.deleteAll();
                setMapDrawState();
            }
            else {
                drawControl.changeMode(mapDrawState);
            }
        }
    }, [mapDrawState, drawControl]);
    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.on('draw.create', (e) => handleAdd(e, aoisFeatureCollection, lineFeatureCollection));
            mapRef.current.on('draw.update', (e) => handleUpdate(e, aoisFeatureCollection));
        }
    }, [drawControl, aoisFeatureCollection]);
    useEffect(() => {
        if (lineFeatureCollection &&
            lineFeatureCollection.features.length > 0) {
            const featureId = lineFeatureCollection.features[0]['id'];
            drawControl === null || drawControl === void 0 ? void 0 : drawControl.delete(featureId);
        }
    }, [lineFeatureCollection]);
    return (React.createElement("div", { className: Styles.mapContainer, ref: ref },
        React.createElement(BaseMap, { mapStyle: (_b = mapsContext.selectedBasemap) === null || _b === void 0 ? void 0 : _b.style, ref: mapRef, initialViewState: initialViewState, onLoad: handleLoad, onMoveEnd: handleMoveEnd, attributionControl: false },
            React.createElement(AttributionControl, { customAttribution: customAttribution, key: customAttribution }),
            React.createElement(NavigationControl, { position: "top-left", showZoom: true, visualizePitch: true }),
            !_isNil(lineFeatureCollection) && (React.createElement(Source, { type: "geojson", data: lineFeatureCollection },
                React.createElement(Layer, { id: "line-string-layer", type: "line", paint: {
                        'line-color': 'red',
                        'line-width': 6,
                        'line-dasharray': [2, 0.5],
                    } }))),
            React.createElement(Source, { type: "geojson", id: "geojson-source", key: `geojson-source-${JSON.stringify(aoisFeatureCollection).length}`, data: aoisFeatureCollection },
                React.createElement(Layer, { id: "polygon-layer", type: "fill", beforeId: "line-string-layer", paint: {
                        'fill-outline-color': '#3bb2d0',
                        'fill-color': 'gray',
                        'fill-opacity': 0.1,
                    } }),
                React.createElement(Layer, { id: "polygon-outline-layer", type: "line", beforeId: "line-string-layer", paint: {
                        'line-color': '#3bb2d0',
                        'line-width': 3,
                    } })),
            React.createElement(Source, { data: resultsContext.selectedAoisFeatureCollection, type: "geojson" },
                React.createElement(Layer, { id: AoiOutlineLayerId, type: "line", paint: {
                        'line-color': mapsContext.getOutlineColor('hex'),
                        'line-width': 2,
                    }, beforeId: "line-string-layer" })),
            React.createElement(TrueColorLayer, { beforeLayerId: trueColorBeforeLayerId, key: `trueColor${selectedTimestamp}` }),
            React.createElement(H3Layer, { mapRef: mapRef.current, onSetPopupInfo: (info) => setPopupInfo(info), afterLayerId: "line-string-layer" }),
            React.createElement(RasterLayer, { afterLayerId: "line-string-layer" }),
            !_isNil(popupInfo) && (React.createElement(Popup, { latitude: popupInfo.latitude, longitude: popupInfo.longitude, style: { zIndex: 1000 }, closeOnClick: false, closeOnMove: false, onClose: handlePopupClose }, popupInfo.value)),
            React.createElement(GeocoderMarker, null)),
        React.createElement(CreateJobDialog, { isOpen: isCreateJobDialogOpen, onClose: handleCloseDialog })));
};
