var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ReactComponent as PlusIcon } from '@Icons/lg/plus.svg';
import { PrimaryButton } from '@orbica/component-sdk';
import React from 'react';
import { TitleWithActionContainer } from '@Components/Containers';
import { JobsTable } from './JobsTable';
export const JobsList = (_a) => {
    var { onCreateJob } = _a, jobsTableProps = __rest(_a, ["onCreateJob"]);
    return (React.createElement(React.Fragment, null,
        React.createElement(TitleWithActionContainer, { title: "Monitoring Jobs", actions: React.createElement(PrimaryButton, { onClick: onCreateJob, size: "large", StartIconProps: {
                    icon: React.createElement(PlusIcon, null),
                } }, "Create New Monitoring Job") }),
        React.createElement("div", null,
            React.createElement(JobsTable, Object.assign({}, jobsTableProps)))));
};
