import { PrimaryButton } from '@orbica/component-sdk';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { isTrialOrganizationState, OrganizationsActions } from '@Data/organizations/Reducer';
import { useGetPlanQuery } from '@Data/organizations/Api';
import { skipToken } from '@reduxjs/toolkit/query';
import { organzationsIdState, orgPurchaseURLState, organizationsAuth0IdState } from '@Data/organizations/Reducer';
const StyledButton = styled(PrimaryButton) `
    min-width: 140px;
`;
export const UpgradeSubscriptionButton = () => {
    const dispatch = useDispatch();
    const isTrialOrg = useSelector(isTrialOrganizationState);
    const orgId = useSelector(organzationsIdState);
    const purchaseURL = useSelector(orgPurchaseURLState);
    const auth0OrgId = useSelector(organizationsAuth0IdState);
    const { data: planData } = useGetPlanQuery(Boolean(auth0OrgId) ? auth0OrgId : skipToken, {
        pollingInterval: 5000,
        skipPollingIfUnfocused: true,
    });
    useEffect(() => {
        if (planData === null || planData === void 0 ? void 0 : planData.type) {
            dispatch(OrganizationsActions.setIsTrialOrganization(planData.type === 'TRIAL'));
        }
    }, [planData, dispatch]);
    return ((planData === null || planData === void 0 ? void 0 : planData.type) === 'TRIAL' && (React.createElement(StyledButton, { href: `https://www.orbica.com/pricing?${purchaseURL}&plan=${planData === null || planData === void 0 ? void 0 : planData.planName}`, target: "_blank", size: "small" }, "Upgrade Plan")));
};
