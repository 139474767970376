import { Slider } from '@orbica/component-sdk';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import { setSelectedBreaks } from '@Data/symbology/Api';
import { selectedDataClassState } from '@Data/symbology/Reducer';
export const ClassesSlider = () => {
    const selectedClass = useSelector(selectedDataClassState);
    const handleChange = (e, value) => {
        setSelectedBreaks(value);
    };
    return (React.createElement(Slider, { label: "Data Classes", value: selectedClass, min: 2, max: 10, onChange: handleChange, containerClassName: GridContentStyles.max, valueLabelFormat: (v) => v + 1, size: "small" }));
};
