import _isNil from 'lodash/isNil';
export function getBasicOrderBy(sorting) {
    if (_isNil(sorting) || sorting.length === 0) {
        return;
    }
    const sortingKey = sorting[0];
    const output = {};
    output[sortingKey.id] = sortingKey.desc ? 'desc' : 'asc';
    return output;
}
