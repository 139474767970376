var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { skipToken } from '@reduxjs/toolkit/query';
import area from '@turf/area';
import _cloneDeep from 'lodash/cloneDeep';
import _isNil from 'lodash/isNil';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React, { useEffect } from 'react';
import { useGetAoiBaselineCloudCoverQuery } from '@Data/aois/Api';
import { FeatureFlags } from '@Services/featureFlags/FeatureFlags';
import { AreaOfInterestListItem, } from './AreaOfInterestListItem';
function getBaselineDateQuery(baselineStartDate, baselineEndDate) {
    return `${baselineStartDate.toISO()}/${baselineEndDate.toISO()}`;
}
function createPayload(baselineStartDate, baselineEndDate, feature) {
    return {
        date_range: getBaselineDateQuery(baselineStartDate, baselineEndDate),
        geom: Object.assign(Object.assign({}, feature.geometry), { crs: {
                type: 'name',
                properties: { name: 'urn:ogc:def:crs:EPSG::4326' },
            } }),
        id: feature.id,
        area: area(feature.geometry),
    };
}
function setBaselineCloudCoverProperties(feature, valid_time_list, baselineStartDate, baselineEndDate, isValid, errorMessage) {
    const newProps = Object.assign(Object.assign({}, feature.properties), { baselineCloudCover: {
            baselineQuery: getBaselineDateQuery(baselineStartDate, baselineEndDate),
            valid_time_list: valid_time_list !== null && valid_time_list !== void 0 ? valid_time_list : null,
            isValid: isValid,
            errorMessage: errorMessage,
        }, area: area(feature.geometry) });
    const updatedFeature = _cloneDeep(feature);
    updatedFeature.properties = newProps;
    return updatedFeature;
}
export const CloudCoverAreaOfInterestListItem = (_a) => {
    var _b, _c;
    var { baselineStartDate, baselineEndDate, feature, onUpdateFeature } = _a, listItemProps = __rest(_a, ["baselineStartDate", "baselineEndDate", "feature", "onUpdateFeature"]);
    const isCloudCoverFlagEnabled = useFeatureFlagEnabled(FeatureFlags.checkAoiCloudCover);
    const isAoiLimitFlagEnabled = useFeatureFlagEnabled(FeatureFlags.aoiMaxAreaTimeframeLimit);
    const { data, isFetching, error } = useGetAoiBaselineCloudCoverQuery(isCloudCoverFlagEnabled
        ? createPayload(baselineStartDate, baselineEndDate, feature)
        : skipToken);
    useEffect(() => {
        if (!isFetching && !_isNil(data)) {
            let errorMessage;
            if (isCloudCoverFlagEnabled) {
                if (data.count === 0) {
                    errorMessage =
                        "No valid satellite images were found for this area in the given baseline date range. Try increasing your baseline's date range.";
                }
                if (isAoiLimitFlagEnabled && !data.is_valid) {
                    errorMessage =
                        baselineStartDate === baselineEndDate
                            ? 'Your Area of Interest is too large, try creating multiple smaller Areas of Interest.'
                            : "Either your Area of Interest is too large, or the length of your baseline is too long. Try decreasing your baseline's date range, or creating multiple smaller Areas of Interest.";
                }
            }
            const updatedFeature = setBaselineCloudCoverProperties(feature, data === null || data === void 0 ? void 0 : data.valid_time_list, baselineStartDate, baselineEndDate, data.is_valid, errorMessage);
            onUpdateFeature(updatedFeature);
        }
    }, [baselineStartDate, baselineEndDate, data, isFetching]);
    if (error) {
        const updatedFeature = setBaselineCloudCoverProperties(feature, null, baselineStartDate, baselineEndDate, false);
        onUpdateFeature(updatedFeature);
    }
    const errorMessage = isCloudCoverFlagEnabled &&
        (!_isNil(error) ||
            (!_isNil(data) &&
                !isFetching &&
                !((_b = feature.properties.baselineCloudCover) === null || _b === void 0 ? void 0 : _b.isValid)))
        ? (_c = feature.properties.baselineCloudCover) === null || _c === void 0 ? void 0 : _c.errorMessage
        : null;
    return (React.createElement(AreaOfInterestListItem, Object.assign({}, listItemProps, { feature: feature, isLoading: isFetching, errorMessage: errorMessage })));
};
