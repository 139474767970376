var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import _isNil from 'lodash/isNil';
import { usePostHog } from 'posthog-js/react';
import React, { useEffect, useState } from 'react';
import { LoadingLogo } from '@Components/LoadingIndicators/LoadingLogo';
import { initializeOrgData } from '@Data/organizations/Api';
import { TokenHandler } from './TokenHandler';
export const RequireAuth = (props) => {
    const { isLoading, getAccessTokenSilently, isAuthenticated, loginWithRedirect, user, getIdTokenClaims, } = useAuth0();
    const [, setVersion] = useState(0);
    const hasToken = TokenHandler.instance.hasToken();
    const posthog = usePostHog();
    const Component = withAuthenticationRequired(props.component);
    useEffect(() => {
        function getAndSetToken() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const token = yield getAccessTokenSilently();
                    TokenHandler.instance.setToken(token);
                    setVersion((v) => v + 1);
                }
                catch (_a) {
                    loginWithRedirect();
                }
            });
        }
        if (!isLoading && isAuthenticated && !hasToken) {
            getAndSetToken();
        }
    }, [isLoading, isAuthenticated, hasToken]);
    useEffect(() => {
        if (!_isNil(user) && !_isNil(posthog)) {
            posthog === null || posthog === void 0 ? void 0 : posthog.identify(user.email.toLowerCase(), {
                email: user.email.toLowerCase(),
            });
            getIdTokenClaims().then((tokenData) => {
                var _a;
                if (!_isNil(tokenData.organization)) {
                    const org = tokenData.organization;
                    const normalizedName = org.display_name
                        .normalize('NFKD')
                        .replace(/[\u0300-\u036F]/g, '');
                    posthog === null || posthog === void 0 ? void 0 : posthog.group('organization', normalizedName, org);
                    initializeOrgData(org.auth0_id, org.display_name, org.id, (_a = org.subscription) === null || _a === void 0 ? void 0 : _a.type);
                }
            });
        }
    }, [user, posthog]);
    if (isLoading) {
        return React.createElement(LoadingLogo, null);
    }
    if (!isAuthenticated) {
        window.location.pathname = '/signup';
    }
    if (!hasToken) {
        return React.createElement(LoadingLogo, null);
    }
    return React.createElement(Component, null);
};
export default RequireAuth;
