import { Dialog } from '@orbica/component-sdk';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import React from 'react';
import { EntityAttributeCard } from '@Components/Cards';
import Styles from './PreExploreDrawer.scss';
const SatelliteImageCard = (props) => {
    const detailsDiv = (React.createElement("div", { className: Styles.imageCardDetails },
        React.createElement("span", { className: TypographyStyles.smallRegular }, "Cloud cover "),
        React.createElement("span", { className: TypographyStyles.smallBold },
            props.cloudCover.toFixed(1),
            "%")));
    return (React.createElement("button", { onClick: props.onClick, className: Styles.satelliteImageCard },
        React.createElement(EntityAttributeCard, { title: DateTime.fromISO(props.date).toLocaleString(DateTime.DATE_FULL), value: detailsDiv })));
};
export const SatelliteImageSelectorDialog = (props) => {
    const handleSelectImage = (feature) => {
        props.onSelectImage([
            { id: feature.id, date: feature.properties.datetime },
        ]);
    };
    return (React.createElement(Dialog, { title: "Select a Date", open: !_isNil(props.collectionResponse), onClose: props.onClose },
        React.createElement("div", { className: cx(GridContentStyles.max, TypographyStyles.smallRegular, Styles.info) },
            React.createElement("span", null, "Select a date to view satellite imagery overlaid on the map."),
            React.createElement("span", null, "A lower number for cloud cover provides better insights.")),
        !_isNil(props.collectionResponse) && (React.createElement(React.Fragment, null, props.collectionResponse.features.map((feature) => (React.createElement(SatelliteImageCard, { key: `sic${feature.id}`, date: feature.properties.datetime, cloudCover: feature.properties['eo:cloud_cover'], onClick: () => handleSelectImage(feature) })))))));
};
