import { Slider } from '@orbica/component-sdk';
import _debounce from 'lodash/debounce';
import { DateTime } from 'luxon';
import React from 'react';
import { useSelector } from 'react-redux';
import { updateSelectedTimestamp } from '@Data/time/Api';
import { timestampsState } from '@Data/time/Reducer';
import Styles from './TimeseriesController.scss';
export const TimeseriesController = () => {
    var _a;
    const timestamps = useSelector(timestampsState);
    const marks = (_a = timestamps === null || timestamps === void 0 ? void 0 : timestamps.map((timestamp, i) => {
        return {
            value: i,
            label: i % 2 === 0
                ? DateTime.fromSeconds(timestamp).toLocaleString(DateTime.DATE_SHORT)
                : '',
            timestamp: timestamp,
        };
    })) !== null && _a !== void 0 ? _a : [];
    const handleChange = _debounce((markValue) => {
        const timestamp = marks.find((m) => m.value === markValue);
        updateSelectedTimestamp(timestamp.timestamp);
    }, 100);
    return (marks &&
        marks.length > 0 && (React.createElement("div", { className: Styles.timeseriesControllerContainer },
        React.createElement("div", { className: Styles.timeseriesController },
            React.createElement(Slider, { min: marks[0].value, max: marks[marks.length - 1].value, marks: marks, step: null, valueLabelFormat: (value) => DateTime.fromSeconds(marks[value].timestamp).toLocaleString(DateTime.DATE_MED), onChange: (e, v) => handleChange(v), containerClassName: Styles.slider })))));
};
