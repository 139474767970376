import { ReactComponent as LockIcon } from '@Icons/md/lock.svg';
import { ReactComponent as MenuIcon } from '@Icons/md/more-vertical.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { IconButton, Menu, MenuItem, SecondaryButton, Switch, Table, } from '@orbica/component-sdk';
import Tokens from '@orbica/component-sdk/build/css/tokens.scss';
import { skipToken } from '@reduxjs/toolkit/query';
import { createColumnHelper } from '@tanstack/react-table';
import _isNil from 'lodash/isNil';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToggleMonitoringStatusDialog } from '@Components/Dialogs/ToggleMonitoringStatusDialog';
import { AoiRunIndexResultPill } from '@Components/EntityDetails/AoiRunIndexResultPill';
import { ScheduledDateIndicator } from '@Components/EntityDetails/ScheduledDateIndicator';
import { useGetDemoJobsQuery } from '@Data/jobs/Api';
import { getDateStringFromTimestamp } from '@Services/helpers/Dates';
import { Navigation } from '@Services/navigation/Navigation';
import { JobDialogStage } from '../Dialogs';
import Styles from './JobsList.scss';
import { JobNameCell } from './components/JobNameCell';
const JobMenuContent = (props) => {
    if (props.isOwner) {
        return (React.createElement(React.Fragment, null,
            React.createElement(MenuItem, { onClick: props.onEditBaseInfo }, "Edit Job Description"),
            React.createElement(MenuItem, { onClick: props.onEditRecipients }, "Edit Notification Recipients"),
            React.createElement(MenuItem, { onClick: props.onDelete, color: Tokens.feedbackError }, "Delete Job")));
    }
    else {
        return React.createElement(MenuItem, { onClick: props.onEditRecipients }, "Share Job");
    }
};
export const JobsTable = (props) => {
    var _a;
    const navigate = useNavigate();
    const { user } = useAuth0();
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [selectedJob, setSelectedJob] = useState(null);
    const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
    const [displayDemoJobs, setDisplayDemoJobs] = useState(false);
    const { data: demoJobs, isFetching: areDemoJobsFetching } = useGetDemoJobsQuery(!displayDemoJobs ? skipToken : null);
    const handleOpenEditJobMenu = (e, job) => {
        e.stopPropagation();
        e.preventDefault();
        setMenuAnchorEl(e.currentTarget);
        setSelectedJob(job);
    };
    const handleCloseInteractiveElements = () => {
        setMenuAnchorEl(null);
        setIsStatusDialogOpen(false);
        setSelectedJob(null);
    };
    const handleToggleMonitoringStatus = (job) => {
        setSelectedJob(job);
        setIsStatusDialogOpen(true);
    };
    const handleOpenJobAois = (job) => {
        navigate(Navigation.schedule.aois.getUrl(job.id));
    };
    const handleEditJob = (stage) => {
        props.onEditJob(selectedJob, stage);
        handleCloseInteractiveElements();
    };
    const handleDeleteJob = () => {
        props.onDeleteJob(selectedJob);
    };
    const handleShareJob = () => {
    };
    const columnHelper = createColumnHelper();
    const columns = useMemo(() => {
        return [
            columnHelper.accessor('name', {
                header: 'Name',
                cell: (info) => React.createElement(JobNameCell, { job: info.row.original }),
            }),
            columnHelper.accessor('is_active', {
                header: 'Monitoring Status',
                cell: (info) => {
                    var _a;
                    const isActive = info.getValue();
                    const job = info.row.original;
                    const canEdit = !job.is_demo && job.created_by_id === ((_a = user === null || user === void 0 ? void 0 : user.user) === null || _a === void 0 ? void 0 : _a.id);
                    return (React.createElement(Switch, { onChange: () => handleToggleMonitoringStatus(info.row.original), checked: isActive, tooltip: canEdit ? 'Toggle monitoring status' : '', disablePropagation: true, disabled: !canEdit }));
                },
            }),
            columnHelper.accessor('aois_count', {
                header: 'No. of Areas',
            }),
            columnHelper.accessor('cadence', {
                header: 'Monitoring Interval',
                cell: (info) => {
                    const days = info.getValue();
                    return _isNil(info) ? '-' : `${days} days`;
                },
                enableSorting: false,
            }),
            columnHelper.accessor('next_run_date', {
                header: 'Next Run',
                cell: (info) => (React.createElement(ScheduledDateIndicator, { scheduledDate: info.getValue(), disabled: !info.row.original.is_active })),
            }),
            columnHelper.accessor('latest_result', {
                header: 'Latest Result',
                cell: (info) => {
                    const state = info.getValue();
                    if (_isNil(info.row.original.last_run_date)) {
                        return '-';
                    }
                    return React.createElement(AoiRunIndexResultPill, { resultValue: state });
                },
            }),
            columnHelper.accessor('created_at', {
                header: 'Created',
                cell: (info) => getDateStringFromTimestamp(info.getValue()),
            }),
            columnHelper.accessor('last_run_date', {
                header: 'Last Run',
                cell: (info) => getDateStringFromTimestamp(info.getValue()),
            }),
            columnHelper.accessor('id', {
                header: null,
                enableSorting: false,
                cell: (info) => {
                    const job = info.row.original;
                    if (job.is_demo) {
                        return (React.createElement("div", { className: Styles.lockIconContainer },
                            React.createElement(LockIcon, null)));
                    }
                    return (React.createElement(IconButton, { onClick: (e) => handleOpenEditJobMenu(e, info.row.original), IconProps: {
                            icon: React.createElement(MenuIcon, null),
                            attribute: 'fill',
                        }, as: "div", size: "small", id: `editBtn${info.getValue()}` }));
                },
                meta: {
                    style: {
                        width: '1%',
                    },
                },
            }),
        ];
    }, []);
    const handleToggleDemoJobs = () => {
        setDisplayDemoJobs(!displayDemoJobs);
    };
    const tableData = displayDemoJobs ? demoJobs : props.jobs;
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { columns: columns, data: tableData !== null && tableData !== void 0 ? tableData : [], onRowClick: handleOpenJobAois, loading: displayDemoJobs ? areDemoJobsFetching : props.isLoading, HeaderProps: {
                title: 'Schedule',
                onSetFilter: (filter) => {
                    props.onSetFilter(filter);
                },
                filter: props.filter,
                filters: (React.createElement(SecondaryButton, { size: "small", onClick: handleToggleDemoJobs }, `View ${displayDemoJobs ? 'Your' : 'Demo'} Jobs`)),
            }, SortingProps: !displayDemoJobs
                ?
                    {
                        sorting: props.sorting,
                        onSortingChange: props.onSetSorting,
                    }
                : undefined, PaginationProps: !displayDemoJobs
                ?
                    {
                        limit: props.limit,
                        count: props.count,
                        offset: props.offset,
                        onSetLimit: props.onSetLimit,
                        onSetOffset: props.onSetOffset,
                    }
                : undefined, noDataMessage: "Try creating a New Monitoring Job, or check out our Demo Jobs" }),
        React.createElement(Menu, { open: !_isNil(menuAnchorEl), anchorEl: menuAnchorEl, onClose: handleCloseInteractiveElements, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
            } },
            React.createElement(JobMenuContent, { isOwner: (selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.created_by_id) === ((_a = user === null || user === void 0 ? void 0 : user.user) === null || _a === void 0 ? void 0 : _a.id), onEditBaseInfo: () => handleEditJob(JobDialogStage.Description), onEditRecipients: () => handleEditJob(JobDialogStage.Notification), onDelete: handleDeleteJob })),
        React.createElement(ToggleMonitoringStatusDialog, { isOpen: isStatusDialogOpen, onClose: () => setIsStatusDialogOpen(false), job: selectedJob })));
};
