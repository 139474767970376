var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { TertiaryButton } from '@orbica/component-sdk';
import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import cx from 'classnames';
import React from 'react';
import Styles from './ResourceNotFound.scss';
export const ResourceNotFound = (_a) => {
    var { resourceName, className } = _a, divProps = __rest(_a, ["resourceName", "className"]);
    return (React.createElement("div", Object.assign({}, divProps, { className: cx(Styles.notFoundContainer, className) }),
        React.createElement("div", { className: Styles.innerContainer },
            React.createElement("span", { className: TypographyStyles.largeRegular },
                "Sorry, we couldn't find your ",
                resourceName,
                "."),
            React.createElement(TertiaryButton, { href: "/", size: "large" }, "Return home"))));
};
