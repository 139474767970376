var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Select, SelectOption, toTitleCase } from '@orbica/component-sdk';
import _isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { StyledThemeIndexCard } from '@Components/Themes/ThemeLayers/StyledComponents';
import { Domains, useGetSpectralIndices_WEBQuery } from '@Data/imagery/Api';
import { getIndexInfo } from '@Data/layers/Api';
import { StyledLayerTreeContainer } from './StyledLayerTreeComponents';
export const CustomIndexLayerTree = (props) => {
    var _a;
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [indexInfo, setIndexInfo] = useState(null);
    const { data: imageIndices, isFetching: areIndicesFetching } = useGetSpectralIndices_WEBQuery({
        domainName: selectedDomain,
        platformName: 'sentinel2a',
    });
    const handleSetIndex = (index) => __awaiter(void 0, void 0, void 0, function* () {
        props.onSetSpectralIndex(index, props.selectedTheme);
        if (!_isNil(index)) {
            const indexInfoValues = yield getIndexInfo(index.short_name);
            setIndexInfo(indexInfoValues);
        }
    });
    useEffect(() => {
        var _a;
        if (((_a = props.selectedLayer) === null || _a === void 0 ? void 0 : _a.type) === 'comparison') {
            setIndexInfo(null);
        }
    }, [props.selectedLayer]);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledLayerTreeContainer, null,
            React.createElement(Select, { value: toTitleCase(selectedDomain), placeholder: "Select a domain", label: "Domain", onChange: (e, v) => setSelectedDomain(v) }, Domains.map((domain) => (React.createElement(SelectOption, { value: domain, key: `opt${domain}` }, toTitleCase(domain))))),
            React.createElement(Select, { loading: areIndicesFetching, value: indexInfo && ((_a = props.selectedLayer) === null || _a === void 0 ? void 0 : _a.index), renderValue: (index) => index.short_name, placeholder: "Select an index", label: "Spectral Index", onChange: (e, v) => handleSetIndex(v), disabled: _isNil(imageIndices) || imageIndices.length === 0 }, imageIndices === null || imageIndices === void 0 ? void 0 : imageIndices.map((index) => (React.createElement(SelectOption, { value: index, key: `opt${index.short_name}` }, index.short_name))))),
        indexInfo && (React.createElement(StyledThemeIndexCard, { "$selected": true, key: `descriptionCard-${indexInfo.asi_short_name}` },
            React.createElement("span", { className: "title" }, indexInfo.asi_long_name),
            React.createElement("span", { className: "description" }, indexInfo.index_description)))));
};
