import _isNil from 'lodash/isNil';
export function getConvertedAreaString(area, unit, options) {
    var _a, _b;
    const displayUnit = (_a = options === null || options === void 0 ? void 0 : options.displayUnit) !== null && _a !== void 0 ? _a : true;
    const rounding = (_b = options === null || options === void 0 ? void 0 : options.rounding) !== null && _b !== void 0 ? _b : 2;
    let unitChar, formattedArea;
    switch (unit) {
        case 'sqm':
            unitChar = `m²`;
            formattedArea = area;
            break;
        case 'ha':
            unitChar = `ha`;
            formattedArea = area / 10000;
            break;
        case 'sqkm':
            unitChar = `km²`;
            formattedArea = area / 1000000;
            break;
    }
    return `${formattedArea.toLocaleString(undefined, {
        style: 'decimal',
        maximumFractionDigits: rounding,
    })} ${displayUnit ? unitChar : ''}`;
}
export function convertAreaFromSqm(area) {
    if (_isNil(area))
        return '-';
    if (area < 10000) {
        return getConvertedAreaString(area, 'sqm');
    }
    else if (area < 1000000) {
        return getConvertedAreaString(area, 'ha');
    }
    else {
        return getConvertedAreaString(area, 'sqkm');
    }
}
export function getAreaReferenceObject(area) {
    if (_isNil(area))
        return null;
    return {
        sqm: area,
        ha: area / 10000,
        sqKm: area / 1000000,
    };
}
