import { hexToRgba } from '@Services/helpers/Gis';
export function getSymbologyByValue(value, breaks, colors, opacity, output = 'rgba') {
    let theClass;
    for (let i = 0; i < breaks.length - 1; i += 1) {
        if (breaks[i] <= value && value <= breaks[i + 1]) {
            theClass = i;
            break;
        }
    }
    const theOpacity = output === 'rgba' ? opacity * 256 : opacity;
    return output === 'rgba'
        ? hexToRgba(colors[theClass], theOpacity)
        : colors[theClass];
}
