import { Slider, Switch } from '@orbica/component-sdk';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import _debounce from 'lodash/debounce';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { setExtrusionFactor, toggleExtrusion } from '@Data/symbology/Api';
import { extrudeState, extrusionFactorState } from '@Data/symbology/Reducer';
import { ExploreResultsContext } from '@Views/Explore/views/Results/ResultsContext/ResultsContext';
export const ExtrusionController = () => {
    const resultsContext = useContext(ExploreResultsContext);
    const extrude = useSelector(extrudeState);
    const extrusionFactor = useSelector(extrusionFactorState);
    const handleChange = _debounce((e, value) => setExtrusionFactor(value), 100);
    return (resultsContext.viewMode === 'h3' && (React.createElement(React.Fragment, null,
        React.createElement(Switch, { checked: extrude, onChange: toggleExtrusion, label: "View 3D", containerClassName: GridContentStyles.max }),
        extrude && (React.createElement(Slider, { label: "Extrusion Scale", min: 0, max: 10, step: 0.5, valueLabelFormat: null, containerClassName: GridContentStyles.max, defaultValue: extrusionFactor, onChange: handleChange })))));
};
