import { createSlice } from '@reduxjs/toolkit';
import { initialOrganizationsState } from './State';
const OrganizationsSlice = createSlice({
    name: 'Organizations',
    initialState: initialOrganizationsState,
    reducers: {
        initialize(state, action) {
            state.auth0Id = action.payload.auth0Id;
            state.displayName = action.payload.displayName;
            state.graphqlId = action.payload.graphqlId;
            state.type = action.payload.type;
            state.purchaseURL = action.payload.purchaseURL;
        },
        setIsTrialOrganization(state, action) {
            state.type = action.payload ? 'TRIAL' : 'PAID';
        },
    },
});
export const OrganizationsActions = OrganizationsSlice.actions;
export const organizationsAuth0IdState = (state) => state.organizations.auth0Id;
export const organizationsDisplayNameState = (state) => state.organizations.displayName;
export const organzationsIdState = (state) => state.organizations.graphqlId;
export const isTrialOrganizationState = (state) => state.organizations.type;
export const orgPurchaseURLState = (state) => state.organizations.purchaseURL;
export default OrganizationsSlice.reducer;
