import { axisBottom } from 'd3-axis';
import { format } from 'd3-format';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import _isNil from 'lodash/isNil';
import React, { useEffect, useMemo } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import Styles from './ComparisonResultChart.scss';
import { ComparisonResultLegend } from './ComparisonResultLegend';
export const ComparisonCheckedLine = (props) => {
    const { scale, value, height } = props;
    return (React.createElement("line", { x1: scale(value), x2: scale(value), y1: 0, y2: height / 2, strokeDasharray: '5,5', stroke: "grey" }));
};
const ComparisonChartArea = (props) => {
    const { scale, floor, ceiling, color } = props;
    const width = scale(ceiling) - scale(floor);
    return (React.createElement("rect", { x: scale(floor), width: width, height: '100%', fill: color, opacity: 0.5 }));
};
const ComparisonMarker = (props) => {
    const { width, height, color, cx, displayValue } = props;
    const bottomDirection = ['0,0', `${width},0`, `${width / 2},${height}`];
    return (React.createElement("svg", { className: Styles.svgLayout },
        React.createElement("polygon", { transform: `translate(${cx - width / 2},0)`, width: width, height: height, points: bottomDirection.join(' '), fill: color }),
        React.createElement("text", { className: Styles.svgText, transform: `translate(${cx - width / 2 - 6}, -${width / 2})` }, displayValue.toFixed(3))));
};
export const ComparisonResultChart = (props) => {
    const chartId = 'comparisonChart';
    const axisContainerId = 'axisContainer';
    const { upper, mean, lower, comparativeValue, min, max } = props;
    const { height, width, ref } = useResizeDetector({
        handleHeight: false,
        refreshMode: 'debounce',
        refreshRate: 1000,
    });
    const indentedWidth = width ? width * 0.98 : 0;
    const values = useMemo(() => {
        return {
            upper2Sd: (upper - mean) * 3 + mean >= max
                ? (upper - mean) * 3 + mean
                : max,
            lower2Sd: (lower - mean) * 3 + mean <= min
                ? (lower - mean) * 3 + mean
                : min,
        };
    }, [upper, mean, lower]);
    const scaleX = useMemo(() => {
        if (_isNil(width)) {
            return null;
        }
        return scaleLinear()
            .domain([values.lower2Sd, values.upper2Sd])
            .range([0, indentedWidth])
            .nice();
    }, [width]);
    useEffect(() => {
        if (!props.isLoading &&
            !_isNil(scaleX) &&
            !_isNil(document.getElementById(axisContainerId))) {
            select(document.getElementById(axisContainerId))
                .selectAll('g')
                .remove();
            select(document.getElementById(axisContainerId))
                .append('g')
                .call(axisBottom(scaleX)
                .tickSize(9)
                .tickValues([
                values.lower2Sd,
                lower,
                mean,
                upper,
                values.upper2Sd,
            ])
                .tickFormat(format('.2r')))
                .attr('transform', `translate(0, ${height / 2 + 2})`);
        }
    }, [props.isLoading, scaleX]);
    const chartHtml = useMemo(() => {
        return props.isLoading || _isNil(width) || _isNil(height) ? null : (React.createElement("svg", { width: width, height: height, id: axisContainerId },
            React.createElement("svg", { id: chartId, className: Styles.responsiveChart, width: indentedWidth, height: height / 2 },
                React.createElement(ComparisonChartArea, { floor: values.lower2Sd, ceiling: lower, scale: scaleX, color: "red" }),
                React.createElement(ComparisonChartArea, { floor: lower, ceiling: upper, scale: scaleX, color: "green" }),
                React.createElement(ComparisonChartArea, { floor: upper, ceiling: values.upper2Sd, scale: scaleX, color: "red" }),
                React.createElement(ComparisonCheckedLine, { scale: scaleX, value: mean, height: height }),
                React.createElement(ComparisonCheckedLine, { scale: scaleX, value: comparativeValue, height: height }))));
    }, [props.isLoading, scaleX]);
    const comparisonMarkerHtml = useMemo(() => {
        return props.isLoading || _isNil(width) || _isNil(height) ? null : (React.createElement(ComparisonMarker, { cx: scaleX(comparativeValue), color: "black", width: 20, height: 20, displayValue: comparativeValue }));
    }, [props.isLoading, scaleX]);
    return (React.createElement("div", { className: Styles.outer },
        React.createElement("div", { className: Styles.comparisonMarkerContainer }, comparisonMarkerHtml),
        React.createElement("div", { className: Styles.chartContainer, ref: ref }, chartHtml),
        React.createElement(ComparisonResultLegend, null)));
};
