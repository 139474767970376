var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ReactComponent as CloseIcon } from '@Icons/md/chevron-up.svg';
import { ReactComponent as InfoIcon } from '@Icons/md/info.svg';
import { Collapse } from '@mui/material';
import { BaseCard } from '@orbica/component-sdk';
import { IconButton } from '@orbica/component-sdk';
import { toTitleCase } from '@orbica/component-sdk';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import Styles from './AdditionalInfoCard.scss';
export const AdditionalInfoCard = (_a) => {
    var { information, children, title, icon, className, renderOpen, disableCollapse } = _a, cardProps = __rest(_a, ["information", "children", "title", "icon", "className", "renderOpen", "disableCollapse"]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(!!renderOpen);
    useEffect(() => {
        return () => setIsDropdownOpen(false);
    }, []);
    const handleToggleDropdown = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsDropdownOpen(!isDropdownOpen);
    };
    return (React.createElement(BaseCard, Object.assign({}, cardProps, { className: cx(className, Styles.additionalInfoCard) }),
        React.createElement("div", { className: Styles.content },
            React.createElement("div", { className: Styles.titleContainer },
                icon,
                React.createElement("span", { className: Styles.title }, toTitleCase(title))),
            React.createElement("div", { className: Styles.infoContainer }, children)),
        React.createElement("div", { className: Styles.collapseBtnContainer }, !disableCollapse && (React.createElement(IconButton, { onClick: handleToggleDropdown, IconProps: {
                icon: isDropdownOpen ? React.createElement(CloseIcon, null) : React.createElement(InfoIcon, null),
            } }))),
        React.createElement(Collapse, { in: isDropdownOpen, unmountOnExit: true },
            React.createElement("div", { className: Styles.additionalInfoContainer }, information))));
};
