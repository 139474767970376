export function lngLatBoundsToBbox(bounds) {
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    return [sw.lng, sw.lat, ne.lng, ne.lat];
}
export const NewZealandViewState = {
    latitude: -40.91862946817475,
    longitude: 173.1541969957492,
    zoom: 5,
};
export function lngLatBoundsToPolygon(bounds) {
    const sw = bounds.getSouthWest();
    const nw = bounds.getNorthWest();
    const ne = bounds.getNorthEast();
    const se = bounds.getSouthEast();
    const coordinates = [
        [sw.toArray(), nw.toArray(), ne.toArray(), se.toArray(), sw.toArray()],
    ];
    return {
        type: 'Polygon',
        crs: {
            type: 'name',
            properties: { name: 'urn:ogc:def:crs:EPSG::4326' },
        },
        coordinates: coordinates,
    };
}
export function hexToRgba(hex, opacity = 255) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16),
            opacity,
        ]
        : null;
}
