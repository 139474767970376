import React from 'react';
import Styles from './ComparisonResultChart.scss';
export const ComparisonResultLegend = () => {
    return (React.createElement("div", { className: Styles.legend },
        React.createElement("div", { className: Styles.legendOption },
            React.createElement("div", { className: Styles.legendOk }),
            React.createElement("span", null, "Within One Std. Dev")),
        React.createElement("div", { className: Styles.legendOption },
            React.createElement("div", { className: Styles.legendError }),
            React.createElement("span", null, "Outside One Std. Dev"))));
};
