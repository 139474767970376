import _isNil from 'lodash/isNil';
import { getSpectralIndicesLabelsByTheme } from '@Services/helpers/Themes';
export function transformBaseAoi(baseAoi) {
    var _a, _b;
    let runResults = [];
    if (!_isNil((_a = baseAoi.aoi_runs[0]) === null || _a === void 0 ? void 0 : _a.aoi_run_results)) {
        runResults = getSpectralIndicesLabelsByTheme((_b = baseAoi === null || baseAoi === void 0 ? void 0 : baseAoi.job) === null || _b === void 0 ? void 0 : _b.theme, baseAoi.aoi_runs[0].aoi_run_results, ['value']);
    }
    return Object.assign(Object.assign({}, baseAoi), { aoi_run_results: runResults });
}
export function transformDetailedAoi(aoi) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    if (_isNil(aoi)) {
        return null;
    }
    return Object.assign(Object.assign({}, aoi), { spectral_indices: (_b = (_a = aoi.job) === null || _a === void 0 ? void 0 : _a.job_spectral_indices) !== null && _b !== void 0 ? _b : null, platform_name: (_e = (_d = (_c = aoi.job) === null || _c === void 0 ? void 0 : _c.platform) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : null, cadence: (_g = (_f = aoi.job) === null || _f === void 0 ? void 0 : _f.cadences[0].days) !== null && _g !== void 0 ? _g : null, next_run_date: (_l = (_k = (_j = (_h = aoi.aoi_runs_aggregate) === null || _h === void 0 ? void 0 : _h.aggregate) === null || _j === void 0 ? void 0 : _j.next_run_date) === null || _k === void 0 ? void 0 : _k.date) !== null && _l !== void 0 ? _l : null, theme: (_o = (_m = aoi.job) === null || _m === void 0 ? void 0 : _m.theme) !== null && _o !== void 0 ? _o : null });
}
export function transformGetJobAoisResponse(response) {
    return {
        aois: response.aoi.map((a) => transformBaseAoi(a)),
        count: response.aoi_aggregate.aggregate.count,
    };
}
