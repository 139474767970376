import { BaseCard } from '@orbica/component-sdk';
import React from 'react';
import Styles from './ViewCard.scss';
export const ViewCard = (props) => {
    return (React.createElement(BaseCard, { className: Styles.card, href: props.href },
        React.createElement("div", { className: Styles.title },
            React.createElement("span", null, props.title),
            React.createElement("img", { src: props.icon, alt: `${props.title} icon`, className: Styles.icon })),
        React.createElement("div", { className: Styles.description },
            React.createElement("span", null, props.description))));
};
