import { Skeleton } from '@mui/material';
import { Checkbox, Dialog, Select, SelectOption, Slider, } from '@orbica/component-sdk';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import { skipToken } from '@reduxjs/toolkit/query';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetAoisQuery } from '@Data/aois/Api';
import { useGetJobsQuery, useGetRunResultJobDetailsQuery, } from '@Data/jobs/Api';
import { getAoisFromSearchParams, getDateRangeFromSearchParams, getDateRangeString, getIndicesFromSearchParams, } from '../helpers';
import { IndexSelector } from './IndexSelector';
import { StyledSelectedOptionContainer } from './StyledComponents';
const DateRangeControl = (props) => {
    var _a, _b, _c, _d;
    if (_isNil(props.marks) || _isNil(props.dateRange)) {
        return (React.createElement("span", { className: cx(GridContentStyles.max, TypographyStyles.baseLight) }, "No results were found for this job, only Historic Baseline Data is available."));
    }
    const lowValue = !_isNil(props.dateRange)
        ? (_b = (_a = props.marks) === null || _a === void 0 ? void 0 : _a.find((m) => m.timestamp === props.dateRange[0])) === null || _b === void 0 ? void 0 : _b.value
        : null;
    const highValue = !_isNil(props.dateRange)
        ? (_d = (_c = props.marks) === null || _c === void 0 ? void 0 : _c.find((m) => m.timestamp === props.dateRange[1])) === null || _d === void 0 ? void 0 : _d.value
        : null;
    return (React.createElement(Slider, { disabled: props.disabled, min: props.marks[0].value, max: props.marks[props.marks.length - 1].value, value: [lowValue, highValue], containerClassName: GridContentStyles.max, valueLabelFormat: (value) => DateTime.fromMillis(props.marks[value].timestamp).toLocaleString(DateTime.DATE_MED), label: props.label, marks: props.marks, onChange: (e, v) => props.onChange(v), step: null }));
};
const RenderAois = ({ aois }) => {
    return (React.createElement(StyledSelectedOptionContainer, null, aois.map((aoi) => aoi.name).join(', ')));
};
export const DataFiltersDialog = (props) => {
    var _a, _b;
    const [searchParams, setSearchParams] = useSearchParams();
    const { data: jobsResponse, isFetching: areJobsFetching } = useGetJobsQuery();
    const [selectedJob, setSelectedJob] = useState(null);
    useEffect(() => {
        const searchJobId = searchParams.get('jobId');
        if (!areJobsFetching && !_isNil(jobsResponse) && searchJobId) {
            const job = jobsResponse.jobs.find((j) => j.id === searchJobId);
            setSelectedJob(job);
        }
    }, [jobsResponse, areJobsFetching]);
    const handleSetSelectedJob = (e, v) => {
        setSearchParams({});
        setSelectedJob(v);
    };
    const { data: detailedJob, isFetching: isDetailedJobFetching } = useGetRunResultJobDetailsQuery(!_isNil(selectedJob) ? selectedJob.id : skipToken);
    const [selectedIndices, setSelectedIndices] = useState([]);
    const handleSetSelectedIndices = (indices) => {
        setSelectedIndices(indices);
    };
    useEffect(() => {
        if (isDetailedJobFetching) {
            setSelectedIndices([]);
            setDateRange(null);
            setViewBaselineData(false);
        }
        else if (!_isNil(detailedJob)) {
            const indices = getIndicesFromSearchParams(detailedJob, searchParams);
            setSelectedIndices(indices);
            const dateRange = getDateRangeFromSearchParams(detailedJob, searchParams);
            if (!dateRange) {
                setViewBaselineData(true);
            }
            else {
                setDateRange(dateRange);
            }
        }
    }, [isDetailedJobFetching, detailedJob]);
    const { data: aoisResponse, isFetching: areAoisFetching } = useGetAoisQuery(!_isNil(selectedJob) ? { jobId: selectedJob.id } : skipToken);
    const [selectedAois, setSelectedAois] = useState([]);
    useEffect(() => {
        if (areAoisFetching) {
            setSelectedAois([]);
        }
        else if (!_isNil(aoisResponse)) {
            const aois = getAoisFromSearchParams(aoisResponse.aois, searchParams);
            setSelectedAois(aois);
        }
    }, [areAoisFetching, aoisResponse]);
    const [dateRange, setDateRange] = useState(null);
    const [viewBaselineData, setViewBaselineData] = useState(!_isNil(searchParams.get('baseline')));
    const marks = useMemo(() => {
        if (isDetailedJobFetching || _isNil(detailedJob)) {
            return null;
        }
        return detailedJob.acquisition_times.map((t, i) => ({
            value: i,
            timestamp: DateTime.fromISO(t).toMillis(),
        }));
    }, [detailedJob, isDetailedJobFetching]);
    const handleSetDateRange = (dateRange) => {
        const startTimestamp = marks.find((m) => m.value === dateRange[0]).timestamp;
        const endTimestamp = marks.find((m) => m.value === dateRange[1]).timestamp;
        setDateRange([startTimestamp, endTimestamp]);
    };
    const getDateRangeTitle = () => {
        if (_isNil(dateRange) || viewBaselineData) {
            return '';
        }
        return `(${getDateRangeString({ dateRange: dateRange })})`;
    };
    const handleSubmit = () => {
        const filters = {
            job: selectedJob,
            areasOfInterest: selectedAois,
            indices: selectedIndices,
        };
        if (viewBaselineData) {
            filters.isBaseline = true;
        }
        else {
            filters.startDate = DateTime.fromMillis(dateRange[0]);
            filters.endDate = DateTime.fromMillis(dateRange[1]);
        }
        props.onSetFilters(filters);
        props.onClose();
    };
    const canSubmit = !_isNil(selectedJob) &&
        selectedAois.length > 0 &&
        selectedIndices.length > 0 &&
        (viewBaselineData || (!viewBaselineData && !_isNil(dateRange)));
    const dateSelectionHtml = isDetailedJobFetching ? (React.createElement(Skeleton, { className: GridContentStyles.max, variant: "rounded", height: 50 })) : (React.createElement(React.Fragment, null,
        React.createElement(DateRangeControl, { marks: marks, dateRange: dateRange, disabled: _isNil(detailedJob) || viewBaselineData, label: `Date Range ${getDateRangeTitle()}`, onChange: handleSetDateRange }),
        React.createElement(Checkbox, { label: "View Historic Baseline Data", checked: viewBaselineData, onChange: () => setViewBaselineData(!viewBaselineData), containerClassName: GridContentStyles.medium, disabled: _isNil(detailedJob) })));
    return (React.createElement(Dialog, { title: 'Set Filters', open: props.isOpen, onClose: props.onClose, FooterProps: {
            onSubmit: handleSubmit,
            submitText: 'Fetch Results',
            onCancel: props.onClose,
            isSubmitDisabled: !canSubmit,
        } },
        React.createElement(Select, { value: selectedJob, onChange: handleSetSelectedJob, label: "Job", placeholder: "Select Job", loading: areJobsFetching, renderValue: (job) => job.name, containerClassName: GridContentStyles.max }, (_a = jobsResponse === null || jobsResponse === void 0 ? void 0 : jobsResponse.jobs) === null || _a === void 0 ? void 0 : _a.map((job) => (React.createElement(SelectOption, { value: job, key: job.id }, job.name)))),
        React.createElement(IndexSelector, { themeName: detailedJob === null || detailedJob === void 0 ? void 0 : detailedJob.themeName, indices: detailedJob === null || detailedJob === void 0 ? void 0 : detailedJob.job_spectral_indices, selectedIndices: selectedIndices, onSetSelectedIndices: handleSetSelectedIndices, isLoading: isDetailedJobFetching, isDisabled: _isNil(detailedJob) }),
        React.createElement(Select, { value: selectedAois, onChange: (e, v) => setSelectedAois(v), label: "Areas of Interest", placeholder: "Select Areas of Interest", loading: areAoisFetching, renderValue: (aois) => React.createElement(RenderAois, { aois: aois }), containerClassName: GridContentStyles.max, multiple: true, disabled: _isNil(aoisResponse) }, (_b = aoisResponse === null || aoisResponse === void 0 ? void 0 : aoisResponse.aois) === null || _b === void 0 ? void 0 : _b.map((aoi) => (React.createElement(SelectOption, { value: aoi, key: `option${aoi.id}` }, aoi.name)))),
        !_isNil(detailedJob) && dateSelectionHtml));
};
