import { store } from '@Services/redux';
import { TimeActions } from './Reducer';
import { getUniqueTimestamps } from './helpers';
export function initializePlaybackData(metadata) {
    let epochTimestamps = getUniqueTimestamps(metadata);
    epochTimestamps = epochTimestamps.sort((a, b) => a - b);
    store.dispatch(TimeActions.setTimestamps(epochTimestamps));
}
export function updateSelectedTimestamp(timestamp) {
    store.dispatch(TimeActions.setSelectedTimestamp(timestamp));
}
