import _groupBy from 'lodash/groupBy';
export function getUniqueIndices(metadata) {
    const groupedDates = _groupBy(metadata, 'spectral_index');
    return Object.keys(groupedDates);
}
export const roundToDigits = (value, digits) => {
    value = value * Math.pow(10, digits);
    value = Math.round(value);
    value = value / Math.pow(10, digits);
    if (value === 0)
        value = 0;
    return value;
};
export const getIntervals = (min, max, count, end = roundToDigits(min + (max - min) / count, 2)) => {
    if (count < 1) {
        return [];
    }
    return [{ min: min, max: end }, ...getIntervals(end, max, count - 1)];
};
export const combineIntervalsWithColors = (intervals, colors) => {
    return intervals.map((interval, index) => (Object.assign(Object.assign({}, interval), { color: colors[index], id: Math.random().toString(10) })));
};
export const convertArrayFormat = (inputArray) => {
    const outputArray = inputArray === null || inputArray === void 0 ? void 0 : inputArray.map((item) => [
        [item.min, item.max],
        item.color,
    ]);
    return outputArray;
};
