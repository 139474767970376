var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Pill } from '@orbica/component-sdk';
import React from 'react';
import { AoiRunResultValue } from '@Data/aoiRuns/Types';
export const AoiRunIndexResultPill = (_a) => {
    var { resultValue, size = 'medium' } = _a, props = __rest(_a, ["resultValue", "size"]);
    let state, text;
    switch (resultValue) {
        case AoiRunResultValue.NO_CHANGE:
            state = 'success';
            text = 'No Change';
            break;
        case AoiRunResultValue.EXCEEDED:
            state = 'error';
            text = 'Exceeded';
            break;
        case AoiRunResultValue.NO_RESULT:
            state = 'primary';
            text = 'No Result';
            break;
    }
    return (React.createElement(Pill, { state: state, type: "chip", size: size, as: props.as }, text));
};
