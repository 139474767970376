var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEditJobMutation, useUpdateJobUsersMutation } from '@Data/jobs/Api';
import { baseApi } from '@Services/redux';
import { CreateJobDialog, DeleteJobDialog, JobDialogStage, } from './components/Dialogs';
import { EditJobDialog } from './components/Dialogs/EditJobDialog';
import { JobsController } from './components/JobsController/JobsController';
export const JobsScheduleView = () => {
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [selectedJobStage, setSelectedJobStage] = useState(null);
    const [editJob] = useEditJobMutation();
    const [updateJobUsers] = useUpdateJobUsersMutation();
    const { user } = useAuth0();
    const dispatch = useDispatch();
    const handleCloseDialog = () => {
        dispatch(baseApi.internalActions.onFocus());
        setIsCreateDialogOpen(false);
        setIsEditDialogOpen(false);
        setSelectedJob(null);
        setSelectedJobStage(null);
        setIsDeleteDialogOpen(false);
    };
    const handleOpenEditJob = (job, jobStage) => {
        setSelectedJob(job);
        setSelectedJobStage(jobStage);
        setIsEditDialogOpen(true);
    };
    const handleEditJob = (editedJob, stage) => __awaiter(void 0, void 0, void 0, function* () {
        if (stage === JobDialogStage.Description) {
            const updateJob = {
                id: editedJob.id,
                name: editedJob.name,
                description: editedJob.description,
                is_active: editedJob.is_active,
            };
            yield editJob(updateJob);
        }
        else {
            const payload = {
                job_id: editedJob.id,
                email_addresses: editedJob.job_user_emails,
                auth0_org_id: user.org_id,
            };
            yield updateJobUsers(payload);
        }
    });
    const handleCreateJob = () => {
        dispatch(baseApi.internalActions.onFocusLost());
        setIsCreateDialogOpen(true);
    };
    const handleOpenDeleteJob = (job) => {
        setSelectedJob(job);
        setIsDeleteDialogOpen(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(JobsController, { onCreateJob: handleCreateJob, onEditJob: handleOpenEditJob, onDeleteJob: handleOpenDeleteJob }),
        React.createElement(CreateJobDialog, { isOpen: isCreateDialogOpen, onClose: handleCloseDialog }),
        React.createElement(EditJobDialog, { isOpen: isEditDialogOpen, onClose: handleCloseDialog, selectedJob: selectedJob, stage: selectedJobStage, onEdit: handleEditJob }),
        React.createElement(DeleteJobDialog, { isOpen: isDeleteDialogOpen, onClose: handleCloseDialog, selectedJob: selectedJob })));
};
