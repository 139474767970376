var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CircularProgress } from '@mui/material';
import { H3HexagonLayer } from 'deck.gl/typed';
import { cellToLatLng } from 'h3-js';
import _isNil from 'lodash/isNil';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { cancelH3Requests, getH3Data } from '@Data/h3/Api';
import { h3DataLoadingState, mapExtrusionState } from '@Data/h3/Reducer';
import { drawState } from '@Data/mapToolbar/Reducer';
import { rawMetadataState } from '@Data/runResults/Reducer';
import { colorRangesState, extrudeState, extrusionConstantState, opacityState, selectedSymbologyState, } from '@Data/symbology/Reducer';
import { getSymbologyByValue } from '@Data/symbology/helpers';
import { selectedTimestampIndexState } from '@Data/time/Reducer';
import { MapsContext } from '@Services/maps';
import { ExploreResultsContext } from '@Views/Explore/views/Results/ResultsContext/ResultsContext';
import DeckGlOverlay from '../Controls/DeckGlOverlay';
import Styles from './H3Layer.scss';
export const H3Layer = (props) => {
    const [isHovering, setIsHovering] = useState(false);
    const [h3Data, setH3Data] = useState([]);
    const mapDrawState = useSelector(drawState);
    const metadata = useSelector(rawMetadataState);
    useEffect(() => {
        if (!_isNil(metadata)) {
            setH3Data([]);
        }
    }, [metadata]);
    const selectedTimestampIndex = useSelector(selectedTimestampIndexState);
    const dataLoadingState = useSelector(h3DataLoadingState);
    const symbology = useSelector(selectedSymbologyState);
    const colorRanges = useSelector(colorRangesState);
    const opacity = useSelector(opacityState);
    const extrusionFactor = useSelector(mapExtrusionState);
    const isExtruded = useSelector(extrudeState);
    const extrusionConstant = useSelector(extrusionConstantState);
    const mapsContext = useContext(MapsContext);
    const resultsContext = useContext(ExploreResultsContext);
    const resolutionByZoomLevel = useMemo(() => {
        if (_isNil(mapsContext.zoomLevel))
            return null;
        if (mapsContext.zoomLevel < 8.5) {
            return 7;
        }
        else if (mapsContext.zoomLevel < 11) {
            return 8;
        }
        else if (mapsContext.zoomLevel < 14.5) {
            return 9;
        }
        else if (mapsContext.zoomLevel < 17) {
            return 10;
        }
        else {
            return 11;
        }
    }, [mapsContext.zoomLevel]);
    function getData() {
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield getH3Data(props.mapRef.getBounds(), resolutionByZoomLevel, metadata);
            if (!_isNil(data) && data.cells.length > 0) {
                setH3Data((prevData) => [...prevData, data]);
            }
        });
    }
    useEffect(() => {
        if (resultsContext.viewMode === 'h3' &&
            !_isNil(props.mapRef) &&
            !_isNil(metadata) &&
            !_isNil(resolutionByZoomLevel)) {
            getData();
        }
    }, [
        resultsContext.loadDataListener,
        resultsContext.viewMode,
        props.mapRef,
        metadata,
        resolutionByZoomLevel,
    ]);
    const handleCellClick = (data) => {
        if (mapDrawState === 'simple_select') {
            const cellCenter = cellToLatLng(data.object.id);
            props.onSetPopupInfo({
                latitude: cellCenter[0],
                longitude: cellCenter[1],
                value: data.object.values[selectedTimestampIndex],
            });
        }
    };
    const getCellColor = (value) => {
        if (!symbology || !colorRanges)
            return null;
        if (value === undefined)
            return [0, 0, 0, 0];
        return getSymbologyByValue(value, symbology, colorRanges, opacity);
    };
    const getElevation = (cell) => {
        return cell.values[selectedTimestampIndex] + extrusionConstant;
    };
    const getHexagon = (cell) => cell.id;
    const h3Layers = useMemo(() => {
        return h3Data.map((data) => {
            return new H3HexagonLayer({
                id: data.id.toString(),
                data: data.cells,
                visible: resultsContext.viewMode === 'h3' &&
                    data.resolution === resolutionByZoomLevel,
                getHexagon: getHexagon,
                getElevation: getElevation,
                pickable: true,
                onClick: handleCellClick,
                getFillColor: (d) => getCellColor(d.values[selectedTimestampIndex]),
                autoHighlight: true,
                highlightColor: [255, 0, 0, 100],
                beforeId: props.afterLayerId,
                elevationScale: extrusionFactor,
                extruded: isExtruded,
                stroked: true,
                getStroke: [255, 255, 255, 100],
                updateTriggers: {
                    getFillColor: [
                        selectedTimestampIndex,
                        symbology,
                        colorRanges,
                        opacity,
                    ],
                    getElevation: [selectedTimestampIndex, extrusionConstant],
                    getVisibility: [
                        resolutionByZoomLevel,
                        resultsContext.viewMode,
                    ],
                    getElevationScale: extrusionFactor,
                    getExtruded: isExtruded,
                },
            });
        });
    }, [
        h3Data,
        mapDrawState,
        selectedTimestampIndex,
        resultsContext.viewMode,
        symbology,
        colorRanges,
        opacity,
        resolutionByZoomLevel,
        extrusionFactor,
        isExtruded,
        extrusionConstant,
    ]);
    useEffect(() => {
        if (resultsContext.viewMode === 'raster') {
            cancelH3Requests();
        }
    }, [resultsContext.viewMode]);
    const getCursor = ({ isDragging, mapDrawState, isHovering }) => {
        if (isDragging) {
            return 'grabbing';
        }
        else if (mapDrawState === 'draw_line_string' ||
            mapDrawState === 'draw_polygon') {
            return 'crosshair';
        }
        else if (isHovering) {
            return 'pointer';
        }
        else {
            return 'grab';
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DeckGlOverlay, { interleaved: true, layers: [...h3Layers], onHover: ({ object }) => setIsHovering(Boolean(object)), getCursor: ({ isDragging }) => getCursor({ isDragging, mapDrawState, isHovering }) }),
        ['fetching', 'loading'].includes(dataLoadingState) && (React.createElement("div", { className: Styles.dataLoadingContainer },
            React.createElement(CircularProgress, { sx: {
                    color: mapsContext.getOutlineColor('hex'),
                } })))));
};
