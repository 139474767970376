import _isNil from 'lodash/isNil';
import React, { useMemo } from 'react';
import { Layer, Source } from 'react-map-gl/maplibre';
import { useSelector } from 'react-redux';
import { layersViewTrueColorState } from '@Data/layers/Reducer';
import { runResultTrueColorLayersState } from '@Data/runResults/Reducer';
import { selectedTimestampState } from '@Data/time/Reducer';
import { WEB_API_URL } from '@Services/redux';
export const TrueColorLayer = (props) => {
    const trueColorLayers = useSelector(runResultTrueColorLayersState);
    const selectedTimestamp = useSelector(selectedTimestampState);
    const viewTrueColor = useSelector(layersViewTrueColorState);
    const trueColorSources = useMemo(() => {
        if (_isNil(trueColorLayers) || _isNil(selectedTimestamp))
            return null;
        const selectedTrueColorLayer = trueColorLayers[selectedTimestamp];
        if (_isNil(selectedTrueColorLayer))
            return null;
        return selectedTrueColorLayer.rgb_cog_urls.map((cog, i) => {
            const encodedCog = encodeURIComponent(cog.url);
            const url = `https://${process.env.URL_PREFIX}titiler.${process.env.BASE_URL}/cog/tiles/WebMercatorQuad/{z}/{x}/{y}.png?scale=1&url=${WEB_API_URL}/data_access/s3/${encodedCog}&bidx=1&bidx=2&bidx=3&expression=b1;b2;b3&unscale=false&resampling=nearest&reproject=nearest&nodata=-9999&rescale=${cog.b1}&rescale=${cog.b2}&rescale=${cog.b3}&return_mask=true`;
            const key = `${selectedTimestamp}${cog}${i}`;
            return (React.createElement(Source, { type: "raster", tiles: [url], id: key, key: key },
                React.createElement(Layer, { type: "raster", beforeId: props.beforeLayerId })));
        });
    }, [selectedTimestamp, trueColorLayers, props.beforeLayerId]);
    return viewTrueColor && trueColorSources;
};
