import { Tooltip } from '@orbica/component-sdk';
import ColorStyles from '@orbica/component-sdk/build/css/colors.scss';
import _isNil from 'lodash/isNil';
import { DateTime, Duration } from 'luxon';
import React from 'react';
import styled from 'styled-components';
function getStatusCircleColor(state) {
    switch (state) {
        case 'active':
            return ColorStyles.feedbackSuccess400;
        case 'disabled':
            return ColorStyles.feedbackError400;
        case 'none':
            return ColorStyles.greyscale300;
    }
}
const StyledDateContainer = styled('div')(({ $state }) => `
    display: flex;
    align-items: center;
    width: fit-content;

    .status-circle {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: ${getStatusCircleColor($state)};
        margin-right: 8px;
    }
`);
function getTimeUntilNextScheduledDate(scheduledDate) {
    if (!scheduledDate)
        return null;
    const today = DateTime.now().startOf('day');
    if (scheduledDate < today)
        return null;
    const diff = scheduledDate.diff(today, ['days']).toObject();
    diff.days = Math.round(diff.days);
    return Duration.fromObject(diff).toHuman();
}
export const ScheduledDateIndicator = ({ scheduledDate, disabled, }) => {
    let state;
    if (_isNil(scheduledDate) || scheduledDate === '') {
        state = 'none';
    }
    else {
        state = disabled ? 'disabled' : 'active';
    }
    let formattedDate, scheduledDateTime;
    if (!_isNil(scheduledDate) && scheduledDate !== '') {
        scheduledDateTime = DateTime.fromISO(scheduledDate);
        formattedDate = getTimeUntilNextScheduledDate(scheduledDateTime);
    }
    if (_isNil(formattedDate)) {
        state = 'none';
    }
    let output = (React.createElement(StyledDateContainer, { "$state": state },
        React.createElement("div", { className: "status-circle" }),
        React.createElement("span", null, formattedDate !== null && formattedDate !== void 0 ? formattedDate : 'N/A')));
    if (!_isNil(formattedDate)) {
        output = (React.createElement(Tooltip, { title: scheduledDateTime.toLocaleString(DateTime.DATE_MED) }, output));
    }
    return output;
};
