import logo from '@Images/logo.svg';
import React from 'react';
import styled, { keyframes } from 'styled-components';
const fade = keyframes `
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
`;
const StyledContainer = styled('div') `
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
`;
const StyledLogo = styled('img') `
    animation: ${fade} 2.5s ease-in-out infinite;
    width: 300px;
    height: auto;
`;
export const LoadingLogo = () => {
    return (React.createElement(StyledContainer, null,
        React.createElement(StyledLogo, { src: logo, alt: "Orbica Logo" })));
};
