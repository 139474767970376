import { ReactComponent as DataLineIcon } from '@Icons/md/data-line.svg';
import { ReactComponent as DataPolygonIcon } from '@Icons/md/data-polygon.svg';
import { ReactComponent as MapIcon } from '@Icons/md/map.svg';
import { ReactComponent as SearchIcon } from '@Icons/md/search.svg';
import { MainContainer, StaticDrawer, Toolbar } from '@orbica/component-sdk';
import React, { useContext, useEffect, useState } from 'react';
import _isNil from 'lodash/isNil';
import { TimeseriesController } from '@Components/Timeseries/TimeseriesController';
import { AddressSearchSelector } from '@Components/ToolBar/AddressSearchSelector';
import { BaseMapSelector } from '@Components/ToolBar/BaseMapSelector';
import { DrawLineSelector } from '@Components/ToolBar/DrawLineSelector';
import { DrawPolygonSelector } from '@Components/ToolBar/DrawPolygonSelector';
import { clearAllCachedData } from '@Data/h3/Api';
import { resetMapToolbarStates, setMapDrawState } from '@Data/mapToolbar/Api';
import { MapsContext } from '@Services/maps';
import ExploreStyles from '../../ExploreView.scss';
import { ExploreResultsContext, ExploreResultsContextProvider, } from './ResultsContext/ResultsContext';
import { DataFiltersAccordion } from './components/DataFilters/DataFiltersAccordion';
import { IndexLayerSelector } from './components/IndexLayerSelector/IndexLayerSelector';
import { ResultsMap } from './components/ResultsMap/ResultsMap';
import { SymbologyAccordion } from './components/SymbologyAccordion/SymbologyAccordion';
export const ExploreResultsView = () => {
    const mapsContext = useContext(MapsContext);
    const resultsContext = useContext(ExploreResultsContext);
    const [jobFilterValue, setJobFilterValue] = useState(null);
    const handleJobFilterValue = (data) => {
        setJobFilterValue(data);
    };
    const handleToolbarClose = () => {
        setMapDrawState();
    };
    useEffect(() => {
        return () => {
            clearAllCachedData();
            mapsContext.onSetBounds(null);
            resultsContext.onSetSelectedAoisFeatureCollection(null);
            mapsContext.onSetZoomLevel(null);
            resetMapToolbarStates();
        };
    }, []);
    return (React.createElement(ExploreResultsContextProvider, null,
        React.createElement(StaticDrawer, { fullWidth: true, className: ExploreStyles.accordionDrawerContainer },
            React.createElement(DataFiltersAccordion, { onSendData: handleJobFilterValue }),
            _isNil(jobFilterValue) ? (React.createElement("div", { className: ExploreStyles.emptyState },
                React.createElement("div", null,
                    React.createElement("p", null, "One of the most powerful applications of the Environmental Monitoring Platform is the ability to compare results with other datasets to aid in making informed decisions. The Results Tab is where it all comes together. Where all the Jobs, AOIs, runs and results can be viewed in a simple page and viewed in space and time."),
                    React.createElement("p", null, "When you click on the Set filters button it pops up a window which allows you to select:"),
                    React.createElement("ul", null,
                        React.createElement("li", null, "Job. This includes both the jobs created by you, and jobs created by others that you get notifications for."),
                        React.createElement("li", null, "Measures. All measures associated with the selected job."),
                        React.createElement("li", null, "AOI. One or multiple AOIs can be selected.")),
                    React.createElement("p", null, "Additionally, if the baseline has been set to include historic data, you have the option of viewing:"),
                    React.createElement("ul", null,
                        React.createElement("li", null, "Imagery acquired and processed after the Start Date."),
                        React.createElement("li", null, "Historical data acquired and processed during the baseline period."),
                        React.createElement("li", null, "Click on the checkbox to view historical data only.")),
                    React.createElement("p", null, "The layout of the result page is pretty similar to that seen when you Explore imagery. Most of the menus are there, albeit with some extra features, but there is also a few new tools to use."),
                    React.createElement("ul", null,
                        React.createElement("li", null, "Job. This area shows some Job level descriptions and the AOIs selected for viewing."),
                        React.createElement("li", null, "Symbology. Allows you to customize the color ramp of the selected measure. It also allows you to choose what type of classification scheme to be used. We will come back to this menu in a bit."),
                        React.createElement("li", null, "Measures. Allows you to select which measure to display. Note that when compared to the explore page, the SCL, true, and false color layers are missing."),
                        React.createElement("li", null, "Map zoom controls. You can set the map zoom and bearing (direction) here."),
                        React.createElement("li", null, "Map window. Shows the basemap and layer outputs."),
                        React.createElement("li", null, "Map tools. This panel contains a number of tools for use on the map:")),
                    React.createElement("p", null, "Timeline. This slider allows you to move forward and backward in time while loading data. If Show historical imagery is selected in the filter, the date range will reflect that of the baseline. If unchecked, dates will be later than the start date."),
                    React.createElement("p", null,
                        "To learn more check out the ",
                        React.createElement("a", { href: "https://docs.orbica.com/docs/Documentation/Results%20Tab/results%20tab/", target: "_blank" }, "User Guide"),
                        " ")))) : (React.createElement(React.Fragment, null,
                React.createElement(IndexLayerSelector, null),
                React.createElement(SymbologyAccordion, null)))),
        React.createElement(MainContainer, { hasDrawer: true, fullWidth: true, fillScreen: true, GridContainerProps: {
                className: ExploreStyles.mainContainer,
            } },
            React.createElement(ResultsMap, null),
            React.createElement(TimeseriesController, null)),
        React.createElement(Toolbar, { onClose: handleToolbarClose, items: [
                {
                    title: 'Basemaps',
                    IconProps: { icon: React.createElement(MapIcon, null) },
                    content: React.createElement(BaseMapSelector, null),
                    id: 'basemapSelector',
                },
                {
                    title: 'Address Search',
                    IconProps: { icon: React.createElement(SearchIcon, null) },
                    content: React.createElement(AddressSearchSelector, null),
                    id: 'addressSearch',
                },
                {
                    title: 'Measure Distance',
                    IconProps: { icon: React.createElement(DataLineIcon, null) },
                    onActivate: () => setMapDrawState(),
                    onDeactivate: handleToolbarClose,
                    id: 'measureDistance',
                    content: React.createElement(DrawLineSelector, null),
                },
                {
                    title: 'Draw Polygon',
                    IconProps: { icon: React.createElement(DataPolygonIcon, null) },
                    onDeactivate: handleToolbarClose,
                    onActivate: () => setMapDrawState(),
                    content: React.createElement(DrawPolygonSelector, null),
                    id: 'drawPolygon',
                },
            ] })));
};
