import info from '@Images/dist_noalpha.png';
import { DateRangePicker, Dialog, TertiaryButton, } from '@orbica/component-sdk';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import _isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { OutlinedSelectableContainer, } from '@Components/Containers';
import { SENTINEL_2_MIN_DATE } from '@Data/imagery/helpers';
import { TitleAndDescription } from './TitleAndDescription';
var BaselineOption;
(function (BaselineOption) {
    BaselineOption[BaselineOption["LATEST_IMAGE"] = 0] = "LATEST_IMAGE";
    BaselineOption[BaselineOption["ALL_DATA"] = 1] = "ALL_DATA";
    BaselineOption[BaselineOption["CUSTOM_DATE_RANGE"] = 2] = "CUSTOM_DATE_RANGE";
})(BaselineOption || (BaselineOption = {}));
const StyledBaselineDescription = styled('div') `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    ul {
        margin-top: 0;
    }

    img {
        width: 100%;
        height: auto;
    }
`;
const StyledBaselineOptionContainer = styled('div') `
    margin-top: 24px;

    span {
        grid-column: 1/-1;
    }
`;
export const BASELINE_MAX_DATE = DateTime.now().startOf('day');
export const BaselineStage = (props) => {
    const [isGraphicOpen, setIsGraphicOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);
    const [hasLoaded, setHasLoaded] = useState(false);
    useEffect(() => {
        if (!hasLoaded) {
            if (_isNil(selectedOption) &&
                !_isNil(props.startDate) &&
                !_isNil(props.endDate)) {
                if (props.startDate === props.endDate &&
                    props.startDate === BASELINE_MAX_DATE) {
                    setSelectedOption(BaselineOption.LATEST_IMAGE);
                }
                else {
                    setCustomStartDate(props.startDate);
                    setCustomEndDate(props.endDate);
                    setSelectedOption(BaselineOption.CUSTOM_DATE_RANGE);
                }
            }
            else {
                handleSelectPredefinedOption(BaselineOption.LATEST_IMAGE);
            }
            setHasLoaded(true);
        }
    }, [props.startDate, props.endDate, selectedOption, hasLoaded]);
    const handleSelectPredefinedOption = (option) => {
        setSelectedOption(option);
        if (option === BaselineOption.CUSTOM_DATE_RANGE) {
            props.onSetStartDate(customStartDate);
            props.onSetEndDate(customEndDate);
        }
        else {
            props.onSetEndDate(BASELINE_MAX_DATE);
            if (option === BaselineOption.LATEST_IMAGE) {
                props.onSetStartDate(BASELINE_MAX_DATE);
            }
            else {
                props.onSetStartDate(SENTINEL_2_MIN_DATE);
            }
        }
    };
    const handleSetCustomDate = (value, period) => {
        setSelectedOption(BaselineOption.CUSTOM_DATE_RANGE);
        if (period === 'start') {
            setCustomStartDate(value);
            props.onSetStartDate(value);
        }
        else {
            setCustomEndDate(value);
            props.onSetEndDate(value);
        }
    };
    const description = (React.createElement(StyledBaselineDescription, null,
        "By comparing new images to this baseline, you will be notified of significant change outside of the defined threshold (set at one standard deviation, \u00B1 1 SD).",
        React.createElement(TertiaryButton, { onClick: () => setIsGraphicOpen(true), "$removePadding": true, size: "small" }, "Learn more")));
    return (React.createElement(React.Fragment, null,
        React.createElement(TitleAndDescription, { title: "Set your baseline to measure change over time.", description: description }),
        React.createElement(OutlinedSelectableContainer, { isSelected: selectedOption === BaselineOption.LATEST_IMAGE, title: "Latest Image", onChange: () => handleSelectPredefinedOption(BaselineOption.LATEST_IMAGE), description: [
                'Use the most recent image to calculate the baseline. This option is recommended for monitoring changes from today onwards.',
            ] }),
        React.createElement(OutlinedSelectableContainer, { isSelected: selectedOption === BaselineOption.CUSTOM_DATE_RANGE, title: "Historical Range", onChange: () => handleSelectPredefinedOption(BaselineOption.CUSTOM_DATE_RANGE), description: [
                'Use historic images within a custom date range to calculate the baseline. By setting a custom start and end date, you can monitor changes against a specific time period.',
            ] },
            React.createElement(DateRangePicker, { startDate: customStartDate, endDate: customEndDate, onChangeStartDate: (date) => handleSetCustomDate(date, 'start'), onChangeEndDate: (date) => handleSetCustomDate(date, 'end'), minDate: SENTINEL_2_MIN_DATE, maxDate: BASELINE_MAX_DATE, StartDateProps: {
                    containerClassName: GridContentStyles.mediumSmall,
                    error: !_isNil(customStartDate) &&
                        customStartDate.isValid &&
                        customStartDate < SENTINEL_2_MIN_DATE,
                    errorMessage: 'Invalid start date',
                }, EndDateProps: {
                    containerClassName: GridContentStyles.mediumSmall,
                    error: !_isNil(customEndDate) &&
                        customEndDate.isValid &&
                        customEndDate > BASELINE_MAX_DATE,
                    errorMessage: 'Invalid end date',
                } })),
        React.createElement(Dialog, { open: isGraphicOpen, onClose: () => setIsGraphicOpen(false), grow: true, BodyProps: {
                style: {
                    width: '60vw',
                },
            } },
            React.createElement("img", { src: info, alt: "normal distribution graphic", style: { gridColumn: '1/-1', width: '100%' } }))));
};
