var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ReactComponent as UserIcon } from '@Icons/lg/user.svg';
import { ReactComponent as CopyIcon } from '@Icons/md/copy.svg';
import { ReactComponent as HelpIcon } from '@Icons/md/help.svg';
import { ReactComponent as LogoutIcon } from '@Icons/md/log-out.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { Badge, IconButton, Menu, MenuItem } from '@orbica/component-sdk';
import { skipToken } from '@reduxjs/toolkit/query';
import _isNil from 'lodash/isNil';
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { useGetCreditInformationQuery } from '@Data/organizations/Api';
import { organizationsAuth0IdState } from '@Data/organizations/Reducer';
import { getOrganizationCreditStatus } from '@Data/organizations/helpers';
import { TokenHandler } from '@Services/authentication/TokenHandler';
import { FeatureFlags } from '@Services/featureFlags/FeatureFlags';
import { OrganizationCreditInformation } from './OrganizationCreditInformation';
import UserDetails from './UserDetails';
import Styles from './UserMenu.scss';
export const UserMenu = () => {
    var _a;
    const [isOpen, setIsOpen] = useState(false);
    const iconButtonContainerId = 'UserMenuBtnContainer';
    const { width, ref } = useResizeDetector();
    const { logout, user } = useAuth0();
    const isDevAuthTokenFlagEnabled = useFeatureFlagEnabled(FeatureFlags.DEV_authToken);
    const posthog = usePostHog();
    const auth0OrgId = useSelector(organizationsAuth0IdState);
    const { data: creditInfo } = useGetCreditInformationQuery(auth0OrgId !== null && auth0OrgId !== void 0 ? auth0OrgId : skipToken, {
        pollingInterval: 5000,
        skipPollingIfUnfocused: true,
    });
    const handleLogout = () => {
        setIsOpen(false);
        posthog === null || posthog === void 0 ? void 0 : posthog.reset();
        logout({
            logoutParams: {
                returnTo: `${process.env.ROOT_URL}/login`,
            },
        });
    };
    const handleDocsClick = () => {
        window.open('https://docs.orbica.com', '_blank');
    };
    const handleCopyAuthToken = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isDevAuthTokenFlagEnabled) {
            yield navigator.clipboard.writeText(TokenHandler.instance.getToken());
            setIsOpen(false);
        }
    });
    const creditStatus = getOrganizationCreditStatus(creditInfo === null || creditInfo === void 0 ? void 0 : creditInfo.org_required_stu, creditInfo === null || creditInfo === void 0 ? void 0 : creditInfo.org_available_stu);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: iconButtonContainerId },
            React.createElement(Badge, { badgeContent: "!", invisible: _isNil(creditStatus) || creditStatus === 'success', state: creditStatus, position: "bottom" },
                React.createElement(IconButton, { className: Styles.userMenu, IconProps: { icon: React.createElement(UserIcon, null), attribute: 'fill' }, onClick: () => setIsOpen(true), mode: "dark", size: "small" }))),
        React.createElement(Menu, { open: isOpen, anchorEl: document.getElementById(iconButtonContainerId), onClose: () => setIsOpen(false), anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
            }, disableMenuPadding: true },
            React.createElement(UserDetails, { email: user.email, avatarUrl: user.picture, organization: (_a = user.organization) === null || _a === void 0 ? void 0 : _a.display_name }),
            React.createElement(OrganizationCreditInformation, Object.assign({}, creditInfo)),
            React.createElement("div", { className: Styles.menuItemsContainer, ref: ref },
                isDevAuthTokenFlagEnabled && (React.createElement(MenuItem, { onClick: handleCopyAuthToken },
                    React.createElement("div", { className: Styles.menuItemContainer },
                        React.createElement("div", null,
                            React.createElement(CopyIcon, null)),
                        "Copy auth token"))),
                React.createElement(MenuItem, { onClick: handleDocsClick },
                    React.createElement("div", { className: Styles.menuItemContainer },
                        React.createElement("div", null,
                            React.createElement(HelpIcon, null)),
                        "Documentation")),
                React.createElement(MenuItem, { onClick: handleLogout },
                    React.createElement("div", { className: Styles.menuItemContainer },
                        React.createElement("div", null,
                            React.createElement(LogoutIcon, null)),
                        "Sign out")),
                width && (React.createElement("div", { className: Styles.releaseNotesBtn, title: process.env.VERSION, style: {
                        width: width,
                    } }, process.env.VERSION))))));
};
