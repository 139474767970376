import { Tooltip, toTitleCase } from '@orbica/component-sdk';
import _isNil from 'lodash/isNil';
import React from 'react';
import styled from 'styled-components';
import { getThemeIcon } from '@Data/imagery/helpers';
const StyledNameContainer = styled('div') `
    display: flex;
    align-items: center;

    img {
        width: 32px;
        height: 32px;
        margin-right: 12px;
    }
`;
export const JobNameCell = ({ job }) => {
    let nameHtml = React.createElement("span", null, job === null || job === void 0 ? void 0 : job.name);
    const desc = job === null || job === void 0 ? void 0 : job.description;
    if (!_isNil(desc) && desc !== '') {
        nameHtml = React.createElement(Tooltip, { title: desc }, nameHtml);
    }
    return (React.createElement(StyledNameContainer, null,
        React.createElement(Tooltip, { title: toTitleCase(job === null || job === void 0 ? void 0 : job.themeName) },
            React.createElement("img", { src: getThemeIcon(job === null || job === void 0 ? void 0 : job.themeName), alt: "topic type icon" })),
        nameHtml));
};
