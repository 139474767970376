import { ReactComponent as EditIcon } from '@Icons/md/edit.svg';
import { ReactComponent as ExportIcon } from '@Icons/md/export.svg';
import { ReactComponent as TrashIcon } from '@Icons/md/trash-2.svg';
import { IconButton, PrimaryButton } from '@orbica/component-sdk';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import _cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AreaOfInterestListItem } from '@Components/AreaOfInterest/AreaOfInterestListItem';
import { setAoisFeatureCollection, setDeleteAoiId, setIsCreateJobDialogOpen, setMapDrawState, setSelectedAoiId, } from '@Data/mapToolbar/Api';
import { aoisFeatureCollectionState } from '@Data/mapToolbar/Reducer';
import { Navigation } from '@Services/navigation/Navigation';
import { exportGeojson } from '@Views/Explore/views/helpers';
function getQualitativeSymbology(index) {
    const brews = [
        '#8dd3c7',
        '#ffffb3',
        '#bebada',
        '#fb8072',
        '#80b1d3',
        '#fdb462',
        '#b3de69',
        '#fccde5',
        '#d9d9d9',
        '#bc80bd',
        '#ccebc5',
        '#ffed6f',
    ];
    const moddedIndex = index % brews.length;
    return brews[moddedIndex];
}
const ModalContainer = styled.div `
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
    column-gap: 12px;
    grid-column: 1 / -1;
`;
export const DrawPolygonSelector = () => {
    const [symbolizedFeatures, setSymbolizedFeatures] = useState(null);
    const aoisFeatureCollection = useSelector(aoisFeatureCollectionState);
    useEffect(() => {
        const filteredFeatures = aoisFeatureCollection.features.map((aoi, i) => (Object.assign(Object.assign({}, aoi), { properties: Object.assign(Object.assign({}, aoi.properties), { color: getQualitativeSymbology(i) }) })));
        setSymbolizedFeatures(filteredFeatures);
    }, [aoisFeatureCollection]);
    const handleButtonClick = () => {
        setMapDrawState('draw_polygon');
    };
    const handleUpdateFeature = (updatedFeature) => {
        const clonedFeatures = _cloneDeep(aoisFeatureCollection === null || aoisFeatureCollection === void 0 ? void 0 : aoisFeatureCollection.features);
        const foundFeatureIndex = clonedFeatures.findIndex((f) => f.id === updatedFeature.id);
        clonedFeatures[foundFeatureIndex] = updatedFeature;
        setAoisFeatureCollection({
            type: 'FeatureCollection',
            features: clonedFeatures,
        });
    };
    const handleDeleteFeature = (aoiId) => {
        const leftFeatures = aoisFeatureCollection.features.filter((item) => item.id !== aoiId);
        setDeleteAoiId(aoiId);
        setAoisFeatureCollection({
            type: 'FeatureCollection',
            features: leftFeatures,
        });
    };
    const handleCreateJobClick = () => {
        setIsCreateJobDialogOpen(true);
    };
    const handleExportGeojsonClick = () => {
        exportGeojson(aoisFeatureCollection);
    };
    const handleDeleteDrawClick = () => {
        setMapDrawState('delete_polygon');
        setAoisFeatureCollection({
            type: 'FeatureCollection',
            features: [],
        });
    };
    const handleselectedAoiId = (aoiId) => {
        setSelectedAoiId(aoiId);
    };
    const columns = location.pathname === Navigation.explore.preExplore.path ? 3 : 2;
    return (React.createElement(React.Fragment, null,
        React.createElement(PrimaryButton, { onClick: handleButtonClick, StartIconProps: {
                icon: React.createElement(EditIcon, null),
            }, className: GridContentStyles.max }, "Add polygon"),
        (aoisFeatureCollection === null || aoisFeatureCollection === void 0 ? void 0 : aoisFeatureCollection.features.length) > 0 && (React.createElement(ModalContainer, { columns: columns },
            location.pathname ===
                Navigation.explore.preExplore.path && (React.createElement(IconButton, { IconProps: {
                    icon: React.createElement(EditIcon, null),
                }, disabled: (aoisFeatureCollection === null || aoisFeatureCollection === void 0 ? void 0 : aoisFeatureCollection.features.length) === 0, onClick: handleCreateJobClick, tooltip: "Create job", size: "small", className: "icon-btn" })),
            React.createElement(IconButton, { IconProps: {
                    icon: React.createElement(ExportIcon, null),
                }, disabled: (aoisFeatureCollection === null || aoisFeatureCollection === void 0 ? void 0 : aoisFeatureCollection.features.length) === 0, onClick: handleExportGeojsonClick, tooltip: "Export all features as geojson", size: "small", className: "icon-btn" }),
            React.createElement(IconButton, { IconProps: {
                    icon: React.createElement(TrashIcon, null),
                }, disabled: (aoisFeatureCollection === null || aoisFeatureCollection === void 0 ? void 0 : aoisFeatureCollection.features.length) === 0, onClick: handleDeleteDrawClick, tooltip: "Delete all features", size: "small", className: "icon-btn" }))),
        React.createElement("div", { className: GridContentStyles.max }, symbolizedFeatures === null || symbolizedFeatures === void 0 ? void 0 : symbolizedFeatures.map((aoi) => (React.createElement(AreaOfInterestListItem, { feature: aoi, key: aoi.id, color: aoi.properties.color, onZoomToBounds: () => handleselectedAoiId(aoi.id), onUpdate: handleUpdateFeature, onDelete: () => handleDeleteFeature(aoi.id) }))))));
};
