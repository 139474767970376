var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import { skipToken } from '@reduxjs/toolkit/query';
import { usePostHog } from 'posthog-js/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useGetIndexInfoQuery } from '@Data/imagery/Api';
import { useGetComparisonResultQuery } from '@Data/runResults/Api';
import { downloadzipFileFromS3 } from '@Data/runResults/Api';
import { Analytics } from '@Services/analytics/Events';
import { getDateStringFromTimestamp } from '@Services/helpers/Dates';
import { downloadFileToLocal, extractFilenameFromPath, } from '@Services/helpers/Files';
import { createToast } from '@Services/notifications/NotificationService';
import { WEB_API_URL } from '@Services/redux';
import { ImageCarousel } from './components/ImageCarousel/ImageCarousel';
import { IndexInfoTable } from './components/IndexInfoTable/IndexInfoTable';
import { Stats } from './components/Stats/Stats';
const StyledContainer = styled('div') `
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: ${GridContentStyles.columnGap};

    .half {
        margin-top: 10px;
        min-width: calc(50% - (${GridContentStyles.columnGap} / 2));
        max-width: calc(50% - (${GridContentStyles.columnGap} / 2));
        overflow: hidden;
    }
`;
export const ComparisonResult = (props) => {
    var _a;
    const { aoiRunResultId } = useParams();
    const posthog = usePostHog();
    const { data: comparisonResult, isFetching } = useGetComparisonResultQuery(aoiRunResultId !== null && aoiRunResultId !== void 0 ? aoiRunResultId : skipToken);
    const { data: spectralIndexInfo } = useGetIndexInfoQuery((_a = comparisonResult === null || comparisonResult === void 0 ? void 0 : comparisonResult.spectral_index) !== null && _a !== void 0 ? _a : skipToken);
    const onDownloadClick = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (!comparisonResult) {
                return null;
            }
            const fileUrl = yield downloadzipFileFromS3(comparisonResult === null || comparisonResult === void 0 ? void 0 : comparisonResult.result_zip_url);
            const fileName = extractFilenameFromPath(comparisonResult === null || comparisonResult === void 0 ? void 0 : comparisonResult.result_zip_url);
            downloadFileToLocal(fileName, fileUrl);
            posthog === null || posthog === void 0 ? void 0 : posthog.capture(Analytics.JobResults.DownloadedZip, {
                aoiRunResultId: aoiRunResultId,
            });
        }
        catch (error) {
            console.error('Error downloading file:', error);
        }
    });
    const onExportPdf = () => __awaiter(void 0, void 0, void 0, function* () {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(`${WEB_API_URL}/export_report/export_pdf?aoi_run_result_id=${aoiRunResultId}`, requestOptions)
            .then((res) => {
            return res.blob();
        })
            .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const fileName = extractFilenameFromPath(comparisonResult === null || comparisonResult === void 0 ? void 0 : comparisonResult.result_zip_url.split('.zip')[0]);
            a.download = `${fileName}.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            posthog === null || posthog === void 0 ? void 0 : posthog.capture(Analytics.JobResults.DownloadedPdf, {
                aoiRunResultId: aoiRunResultId,
            });
        })
            .catch((err) => {
            createToast('Exporting PDF failed.', 'error');
            return Promise.reject({
                Error: 'Something Went Wrong with exporting PDF',
                err,
            });
        });
    });
    return (React.createElement(StyledContainer, null,
        React.createElement("div", { className: "half" },
            React.createElement(ImageCarousel, { acquisition_time: getDateStringFromTimestamp(props.imageAcquisitionTime), baseline_time_range: `
                        ${getDateStringFromTimestamp(props.baselineStartDate)} -
                        ${getDateStringFromTimestamp(props.baselineEndDate)}
                    `, isLoading: isFetching, current: comparisonResult === null || comparisonResult === void 0 ? void 0 : comparisonResult.monitoring_image_url, baseline: comparisonResult === null || comparisonResult === void 0 ? void 0 : comparisonResult.baseline_image_url, baseline_gif: comparisonResult === null || comparisonResult === void 0 ? void 0 : comparisonResult.baseline_gif_url, change: comparisonResult === null || comparisonResult === void 0 ? void 0 : comparisonResult.diff_disp_img_url, rgbCog: comparisonResult === null || comparisonResult === void 0 ? void 0 : comparisonResult.monitoring_image_rgb_url })),
        React.createElement("div", { className: "half" },
            React.createElement(Stats, { isLoading: isFetching, mean: comparisonResult === null || comparisonResult === void 0 ? void 0 : comparisonResult.bl_value, upper: comparisonResult === null || comparisonResult === void 0 ? void 0 : comparisonResult.upper, lower: comparisonResult === null || comparisonResult === void 0 ? void 0 : comparisonResult.lower, comparativeValue: comparisonResult === null || comparisonResult === void 0 ? void 0 : comparisonResult.sl_value, min: comparisonResult === null || comparisonResult === void 0 ? void 0 : comparisonResult.aoi_run_result.min, max: comparisonResult === null || comparisonResult === void 0 ? void 0 : comparisonResult.aoi_run_result.max, onDownloadClick: onDownloadClick, onExportPdf: onExportPdf }),
            spectralIndexInfo && (React.createElement(IndexInfoTable, { title: comparisonResult === null || comparisonResult === void 0 ? void 0 : comparisonResult.spectral_index, indexInfo: spectralIndexInfo })))));
};
