import { ReactComponent as PlusIcon } from '@Icons/lg/plus.svg';
import { Collapse } from '@mui/material';
import { SecondaryButton, TertiaryButton, TextInput, isEmailAddressValid, } from '@orbica/component-sdk';
import GridStyles from '@orbica/component-sdk/build/css/grid.scss';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import _cloneDeep from 'lodash/cloneDeep';
import _isNil from 'lodash/isNil';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { NotificationRecipient } from './NotificationStage';
import { TitleAndDescription } from './TitleAndDescription';
const StyledTertiaryButton = styled(TertiaryButton) `
    justify-content: flex-start;
    padding: 0;
`;
export const ShareJobStage = (props) => {
    var _a, _b;
    const [invalidEmailError, setInvalidEmailError] = useState(null);
    const emailInputRef = useRef(null);
    const handleDeleteRecipient = (recipient) => {
        debugger;
        const clonedRecipients = _cloneDeep(props.notificationRecipients);
        const index = clonedRecipients.findIndex((r) => r === recipient);
        clonedRecipients.splice(index, 1);
        props.onUpdateRecipients(clonedRecipients);
    };
    const handleAddRecipient = (input) => {
        const theEmailAddress = input !== null && input !== void 0 ? input : emailInputRef.current.value;
        if (!isEmailAddressValid(theEmailAddress)) {
            setInvalidEmailError('Please enter a valid email address');
        }
        else if (props.notificationRecipients
            .map((r) => r.toLowerCase())
            .includes(theEmailAddress.toLowerCase())) {
            setInvalidEmailError('This recipient has already been added');
        }
        else {
            const clonedRecipients = _cloneDeep(props.notificationRecipients);
            clonedRecipients.push(theEmailAddress);
            props.onUpdateRecipients(clonedRecipients);
            if (_isNil(input)) {
                emailInputRef.current.value = '';
                setInvalidEmailError(null);
            }
        }
    };
    const newEmails = (_b = (_a = props.notificationRecipients) === null || _a === void 0 ? void 0 : _a.filter((email) => { var _a; return !((_a = props.existingEmails) === null || _a === void 0 ? void 0 : _a.includes(email)); })) !== null && _b !== void 0 ? _b : [];
    return (React.createElement(React.Fragment, null,
        React.createElement(TitleAndDescription, { title: "Add email addresses" }),
        React.createElement(TextInput, { ref: emailInputRef, placeholder: "Add a new email address", containerClassName: GridContentStyles.medium, error: !_isNil(invalidEmailError), errorMessage: invalidEmailError, onEnter: handleAddRecipient, autoFocus: true }),
        React.createElement(SecondaryButton, { onClick: () => handleAddRecipient(), className: GridContentStyles.small, style: { alignSelf: 'start' }, StartIconProps: {
                icon: React.createElement(PlusIcon, null),
                attribute: 'fill',
            } }, "Add Email Address"),
        React.createElement(Collapse, { in: !_isNil(props.notificationRecipients) &&
                props.notificationRecipients.length > 0, className: GridContentStyles.max }, newEmails && newEmails.length > 0 && (React.createElement("div", { className: GridStyles.dialogGrid }, newEmails.map((recipient) => (React.createElement(NotificationRecipient, { email: recipient, key: recipient, onDelete: () => handleDeleteRecipient(recipient) }))))))));
};
