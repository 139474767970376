import { Page, PageContent } from '@orbica/component-sdk';
import React from 'react';
import { Header } from '@Components/Header/Header';
import { Tabs } from '@Components/Tabs/Tabs';
import { PreExploreView } from './views/PreExplore/PreExploreView';
import { ExploreResultsView } from './views/Results/ExploreResultsView';
export const ExploreView = () => {
    return (React.createElement(Page, null,
        React.createElement(Header, null,
            React.createElement(Tabs, { currentView: "explore" })),
        React.createElement(PageContent, null,
            React.createElement(PreExploreView, null))));
};
export const ResultsView = () => {
    return (React.createElement(Page, null,
        React.createElement(Header, null,
            React.createElement(Tabs, { currentView: "results" })),
        React.createElement(PageContent, null,
            React.createElement(ExploreResultsView, null))));
};
