// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maplibregl-ctrl-geocoder {
    font-weight: 400 !important;
    font-size: 16px !important;
    font-family: 'Nunito Sans' !important;
    line-height: 140% !important;

    input {
        padding: 8px 32px !important;
    }

    svg {
        fill: #536a74 !important;
    }
}

.custom-maplibregl-control {
    margin: 10px;
    pointer-events: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/Map/Controls/GeocoderControl.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,0BAA0B;IAC1B,qCAAqC;IACrC,4BAA4B;;IAE5B;QACI,4BAA4B;IAChC;;IAEA;QACI,wBAAwB;IAC5B;AACJ;;AAEA;IACI,YAAY;IACZ,oBAAoB;AACxB","sourcesContent":[".maplibregl-ctrl-geocoder {\n    font-weight: 400 !important;\n    font-size: 16px !important;\n    font-family: 'Nunito Sans' !important;\n    line-height: 140% !important;\n\n    input {\n        padding: 8px 32px !important;\n    }\n\n    svg {\n        fill: #536a74 !important;\n    }\n}\n\n.custom-maplibregl-control {\n    margin: 10px;\n    pointer-events: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
