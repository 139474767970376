import { ReactComponent as CloseIcon } from '@Icons/sm/x.svg';
import { Collapse } from '@mui/material';
import { Checkbox, IconButton, Label, SearchInput, Select, SelectOption, toTitleCase, } from '@orbica/component-sdk';
import GridStyles from '@orbica/component-sdk/build/css/grid.scss';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import { skipToken } from '@reduxjs/toolkit/query';
import cx from 'classnames';
import _cloneDeep from 'lodash/cloneDeep';
import _debounce from 'lodash/debounce';
import _isNil from 'lodash/isNil';
import React, { useState } from 'react';
import styled from 'styled-components';
import { SecondaryInfoContainer } from '@Components/Containers';
import { SpectralIndexPill } from '@Components/EntityDetails/SpectralIndexPill';
import { Domains, useGetIndicesByDomainQuery } from '@Data/imagery/Api';
import { createToast } from '@Services/notifications/NotificationService';
const StyledLabelContainer = styled('div') `
    display: grid;
    column-gap: ${GridStyles.columnGap};
    row-gap: ${GridStyles.rowGap};
    grid-template-columns: 1fr;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;
const StyledIndicesContainer = styled(StyledLabelContainer) `
    margin-top: 24px;
`;
const StyledIndicesDescription = styled('div') `
    display: flex;
    flex-direction: column;

    .learnMore {
        display: flex;
        align-items: baseline;
        a {
            margin-right: 3px;
        }
    }
`;
const StyledSelectedIndicesContainer = styled('div') `
    display: flex;
    gap: 4px;
    margin-top: 2px;
`;
export const IndicesByDomain = (props) => {
    var _a, _b;
    const { data: imageIndices, isFetching: areIndicesFetching } = useGetIndicesByDomainQuery(props.domain ? props.domain : skipToken);
    const handleDomainChange = (event, domain) => {
        props.onSetDomain(domain);
    };
    const [filter, setFilter] = useState('');
    const [filterKey, setFilterKey] = useState(Math.random().toString(16));
    const handleFilterChange = _debounce((e) => setFilter(e.target.value), 500);
    const handleClearFilter = () => {
        setFilter('');
        setFilterKey(Math.random().toString(16));
    };
    const handleToggleIndex = (index) => {
        const clonedIndices = _cloneDeep(props.imageryIndices);
        const existingIndex = clonedIndices.findIndex((i) => i.asi_short_name === index.asi_short_name);
        if (existingIndex > -1) {
            clonedIndices.splice(existingIndex, 1);
        }
        else if (clonedIndices.length < 5) {
            clonedIndices.push(index);
        }
        else {
            createToast('You can only select up to 5 indices', 'info');
        }
        props.onUpdateImageryIndices(clonedIndices);
    };
    const filteredIndices = imageIndices === null || imageIndices === void 0 ? void 0 : imageIndices.filter((index) => {
        const escapedSearchTerm = filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(escapedSearchTerm, 'i');
        return (regex.test(index.asi_long_name) || regex.test(index.asi_short_name));
    });
    const isIndexChecked = (index) => {
        const indexIds = props.imageryIndices.map((i) => i.asi_short_name);
        return indexIds.includes(index.asi_short_name);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Select, { label: "What topic are you interested in?", onChange: handleDomainChange, value: props.domain, renderValue: (domain) => toTitleCase(domain), placeholder: "Select a Topic", containerClassName: GridContentStyles.mediumSmall, required: true }, Domains.map((d) => (React.createElement(SelectOption, { value: d, key: d }, toTitleCase(d))))),
        React.createElement(Collapse, { in: !_isNil(imageIndices) && !areIndicesFetching, mountOnEnter: true, unmountOnExit: true, className: GridContentStyles.max },
            React.createElement(SecondaryInfoContainer, { className: cx(GridContentStyles.max, GridContentStyles.dialogBodyContent) },
                React.createElement("div", { className: GridContentStyles.max },
                    React.createElement(StyledLabelContainer, null,
                        React.createElement(Label, { label: "Select one to five indices", required: true }),
                        React.createElement(SearchInput, { key: filterKey, size: "small", hideButton: true, placeholder: "Filter indices", onChange: handleFilterChange, endAdornment: React.createElement(IconButton, { size: "small", IconProps: { icon: React.createElement(CloseIcon, null) }, disablePadding: true, style: {
                                    display: filter === '' ? 'none' : 'flex',
                                }, onClick: handleClearFilter }) })),
                    ((_a = props.imageryIndices) === null || _a === void 0 ? void 0 : _a.length) > 0 && (React.createElement(StyledSelectedIndicesContainer, null, (_b = props.imageryIndices) === null || _b === void 0 ? void 0 : _b.map((i) => (React.createElement(SpectralIndexPill, { shortName: i.asi_short_name, onClick: () => handleToggleIndex(i), key: i.id }))))),
                    React.createElement(StyledIndicesContainer, null, filteredIndices === null || filteredIndices === void 0 ? void 0 : filteredIndices.map((index) => (React.createElement(Checkbox, { checked: isIndexChecked(index), label: index.asi_short_name, message: index.asi_long_name, onChange: (_) => handleToggleIndex(index), key: index.asi_short_name, secondaryInfo: index.asi_long_name })))))))));
};
