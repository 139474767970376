import { ReactComponent as EditIcon } from '@Icons/md/edit.svg';
import { ReactComponent as TrashIcon } from '@Icons/md/trash-2.svg';
import { PrimaryButton, SecondaryButton } from '@orbica/component-sdk';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import { feature } from '@turf/helpers';
import turfLength from '@turf/length';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { setLineFeatureCollection, setMapDrawState, } from '@Data/mapToolbar/Api';
import { lineFeatureCollectionState } from '@Data/mapToolbar/Reducer';
const DrawerContainer = styled('div')({
    gridColumn: '1/-1',
});
export const DrawLineSelector = () => {
    const [lineDistance, setLineDistance] = useState(null);
    const lineFeatureCollection = useSelector(lineFeatureCollectionState);
    const handleButtonClick = () => {
        setMapDrawState('draw_line_string');
    };
    useEffect(() => {
        if ((lineFeatureCollection === null || lineFeatureCollection === void 0 ? void 0 : lineFeatureCollection.features.length) > 0) {
            const newGeometry = lineFeatureCollection === null || lineFeatureCollection === void 0 ? void 0 : lineFeatureCollection.features[0].geometry;
            const newFeature = feature(newGeometry);
            const length = turfLength(newFeature).toFixed(6);
            setLineDistance(`Total distance: ${length} km`);
        }
    }, [lineFeatureCollection]);
    const handleDleteLineString = () => {
        setLineDistance(null);
        setLineFeatureCollection({
            type: 'FeatureCollection',
            features: [],
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PrimaryButton, { onClick: handleButtonClick, StartIconProps: {
                icon: React.createElement(EditIcon, null),
            }, className: GridContentStyles.max }, "Measure line"),
        (lineFeatureCollection === null || lineFeatureCollection === void 0 ? void 0 : lineFeatureCollection.features.length) > 0 && (React.createElement(SecondaryButton, { onClick: handleDleteLineString, StartIconProps: {
                icon: React.createElement(TrashIcon, null),
            }, className: GridContentStyles.max }, "Delete line")),
        React.createElement(DrawerContainer, null, lineDistance)));
};
