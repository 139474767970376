import React from 'react';
import { StyledThemeIndexCard } from '@Components/Themes/ThemeLayers/StyledComponents';
import { Sentinel2ComparisonLayers } from '@Data/imagery/helpers';
import Styles from '../PreExploreDrawer.scss';
import { StyledLayerTreeContainer } from './StyledLayerTreeComponents';
export const ComparisonLayers = (props) => {
    var _a, _b;
    const handleSelectComparisonLayer = (layer) => {
        props.onSetComparisonLayer(layer);
    };
    const selectedComparisonLayerType = ((_a = props.selectedLayer) === null || _a === void 0 ? void 0 : _a.type) === 'comparison'
        ? (_b = props.selectedLayer) === null || _b === void 0 ? void 0 : _b.comparisonLayer.type
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: Styles.divider }),
        React.createElement(StyledLayerTreeContainer, null, Sentinel2ComparisonLayers.map((layer) => (React.createElement(StyledThemeIndexCard, { "$selected": selectedComparisonLayerType === layer.type, onClick: () => handleSelectComparisonLayer(layer), key: `ticard${layer.type}` },
            React.createElement("span", { className: "title" }, layer.label),
            React.createElement("span", { className: "description" }, layer.description)))))));
};
