var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Pill } from '@orbica/component-sdk';
import React from 'react';
import { AoiRunStatus } from '@Data/aoiRuns/Types';
export const AoiRunStatusPill = (_a) => {
    var { status: resultValue, size = 'medium' } = _a, props = __rest(_a, ["status", "size"]);
    let state, text;
    switch (resultValue) {
        case AoiRunStatus.RESCHEDULED:
            state = 'primary';
            text = 'Rescheduled';
            break;
        case AoiRunStatus.COMPLETED:
            state = 'success';
            text = 'Completed';
            break;
        case AoiRunStatus.SCHEDULED:
            state = 'warning';
            text = 'Scheduled';
            break;
        case AoiRunStatus.IN_PROGRESS:
            state = 'info';
            text = 'In Progress';
            break;
        case AoiRunStatus.ERROR:
            state = 'error';
            text = 'Error';
            break;
        case AoiRunStatus.NO_RESULT:
            state = 'cancelled';
            text = 'No Result';
            break;
    }
    return (React.createElement(Pill, { state: state, type: "chip", size: size, as: props.as }, text));
};
