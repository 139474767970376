import { Skeleton } from '@mui/material';
import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import React from 'react';
import styled from 'styled-components';
const StyledTitleContainer = styled('div') `
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .titleContainer {
        display: flex;
    }

    .actions {
        display: flex;
        align-items: center;
        gap: 24px;
    }
`;
export const TitleWithActionContainer = (props) => {
    let title;
    if (props.isLoading) {
        title = React.createElement(Skeleton, { variant: "text", height: 36.4, width: 270 });
    }
    else {
        title =
            typeof props.title === 'string' ? (React.createElement("span", null, props.title)) : (React.createElement("div", { className: "titleContainer" }, props.title));
    }
    return (React.createElement(StyledTitleContainer, { className: TypographyStyles.heading2Bold },
        title,
        React.createElement("div", { className: "actions" }, props.actions)));
};
