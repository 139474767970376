import { Select, SelectOption } from '@orbica/component-sdk';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import React from 'react';
import { SpectralIndexPill } from '@Components/EntityDetails/SpectralIndexPill';
import { StyledSelectedOptionContainer } from './StyledComponents';
const RenderIndices = ({ indices, themeName }) => {
    const innerHtml = !themeName || themeName === 'custom'
        ? indices.map((i) => (React.createElement(SpectralIndexPill, { shortName: i.index_key, key: `selected${i.index_key}`, as: "span" })))
        : indices.map((i) => i.label).join(', ');
    return (React.createElement(StyledSelectedOptionContainer, null, innerHtml));
};
const ThemeIndexSelectOption = (props) => {
    const innerHtml = props.themeName !== 'custom' ? (props.index.label) : (React.createElement(SpectralIndexPill, { as: "span", shortName: props.index.index_key, disabled: props.disabled }));
    return React.createElement(SelectOption, { value: props.index }, innerHtml);
};
export const IndexSelector = ({ selectedIndices, indices, themeName, onSetSelectedIndices, isLoading, isDisabled, }) => {
    const label = themeName !== 'custom' ? 'Measures' : 'Spectral Indices';
    return (React.createElement(Select, { value: selectedIndices, onChange: (e, v) => onSetSelectedIndices(v), label: label, placeholder: `Select ${label}`, loading: isLoading, renderValue: (indices) => (React.createElement(RenderIndices, { indices: indices, themeName: themeName })), containerClassName: GridContentStyles.max, multiple: true, disableOptionStyling: themeName === 'custom', disabled: isDisabled }, indices === null || indices === void 0 ? void 0 : indices.map((index) => (React.createElement(ThemeIndexSelectOption, { key: `themeIndexOption${index.label}`, index: index, disabled: !selectedIndices.includes(index), themeName: themeName })))));
};
