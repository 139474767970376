import { ReactComponent as ZoomInIcon } from '@Icons/sm/zoom-in.svg';
import { FormControl } from '@orbica/component-sdk';
import { IconButton } from '@orbica/component-sdk';
import ColorStyles from '@orbica/component-sdk/build/css/colors.scss';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import bbox from '@turf/bbox';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { AreaOfInterestOutline } from '@Components/AreaOfInterest/AreaOfInterestOutline';
import { MapsContext } from '@Services/maps';
import { getDateRangeString } from './helpers';
const StyledContainer = styled('div') `
    align-items: center;
    display: grid;
    grid-template-columns: 25px 1fr 25px;
    column-gap: 12px;
`;
const AoiFilterButton = ({ aoi, onClick }) => {
    const handleClick = () => {
        onClick(aoi);
    };
    return (React.createElement(StyledContainer, null,
        React.createElement(AreaOfInterestOutline, { feature: aoi.aoi_geom, dimensions: {
                height: 25,
                width: 25,
            }, color: ColorStyles.feedbackSuccess200 }),
        React.createElement("span", { className: TypographyStyles.smallRegular }, aoi.name),
        React.createElement(IconButton, { IconProps: { icon: React.createElement(ZoomInIcon, null) }, size: "small", tooltip: "Zoom to Area of Interest", onClick: handleClick })));
};
export const SelectedFilters = (props) => {
    if (!props.filters)
        return null;
    const mapsContext = useContext(MapsContext);
    const { job, areasOfInterest, startDate, endDate, isBaseline } = props.filters;
    const handleAoiClick = (aoi) => {
        const bounds = bbox(aoi.aoi_geom);
        mapsContext.onSetBounds(bounds);
    };
    const dateHtml = isBaseline
        ? 'Historic Baseline Data'
        : getDateRangeString({ startDate, endDate });
    const handleZoomToCombinedBounds = () => {
        props.onZoomToCombinedBounds(areasOfInterest);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FormControl, { label: "Job", action: React.createElement(IconButton, { IconProps: { icon: React.createElement(ZoomInIcon, null) }, size: "small", tooltip: "Zoom to Job", onClick: handleZoomToCombinedBounds }), containerClassName: GridContentStyles.max, size: "small" },
            React.createElement("span", { className: TypographyStyles.baseRegular }, job.name)),
        React.createElement(FormControl, { label: "Areas of Interest", containerClassName: GridContentStyles.max, size: "small" },
            React.createElement("div", { style: {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                } }, areasOfInterest.map((aoi) => (React.createElement(AoiFilterButton, { key: aoi.id, aoi: aoi, onClick: handleAoiClick }))))),
        React.createElement(FormControl, { label: "Date Range", containerClassName: GridContentStyles.max, size: "small" },
            React.createElement("span", { className: TypographyStyles.smallRegular }, dateHtml))));
};
