var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ClientError, request } from 'graphql-request';
import { TokenHandler } from '@Services/authentication/TokenHandler';
import { GRAPHQL_URL } from '@Services/redux';
export const graphqlBaseQuery = ({ baseUrl }) => ({ body }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield request(baseUrl, body, null, {
            Authorization: `Bearer ${TokenHandler.instance.getToken()}`,
        });
        return { data: result };
    }
    catch (error) {
        if (error instanceof ClientError) {
            return {
                error: { status: error.response.status, data: error },
            };
        }
        return { error: { status: 500, data: error } };
    }
});
export function graphqlRequest(body, baseUrl = GRAPHQL_URL) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const result = yield request(baseUrl, body, null, {
                Authorization: `Bearer ${TokenHandler.instance.getToken()}`,
            });
            return { data: result, status: 200 };
        }
        catch (error) {
            if (error instanceof ClientError) {
                return {
                    error: { status: error.response.status, data: error },
                };
            }
            return { error: { status: 500, data: error } };
        }
    });
}
