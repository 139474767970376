export function getOrganizationCreditStatus(required, available) {
    if (!required && !available)
        return null;
    const ratio = required / available;
    if (ratio > 0.75) {
        return 'error';
    }
    else if (ratio > 0.5) {
        return 'warning';
    }
    return 'success';
}
