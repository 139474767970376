import { DrawerAccordion, FormControl, RadioButton, Switch, } from '@orbica/component-sdk';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import _isNil from 'lodash/isNil';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ThemeIndexCard } from '@Components/Themes/ThemeLayers/ThemeIndexCard';
import { setSelectedLayerIndex, toggleViewTrueColor } from '@Data/layers/Api';
import { layersIndicesState, layersSelectedIndexState, layersThemeNameState, layersViewTrueColorState, } from '@Data/layers/Reducer';
import { runResultTrueColorLayersState } from '@Data/runResults/Reducer';
import { ExploreResultsContext, } from '../../ResultsContext/ResultsContext';
import Styles from './IndexLayerSelector.scss';
const LayerRadioSelector = () => {
    const resultsContext = useContext(ExploreResultsContext);
    const handleChange = (mode) => {
        resultsContext.onSetViewMode(mode);
    };
    return (React.createElement(FormControl, { label: "View mode", containerClassName: GridContentStyles.max },
        React.createElement("div", { className: Styles.viewModeContainer },
            React.createElement(RadioButton, { checked: resultsContext.viewMode === 'raster', label: "Raster", onChange: () => handleChange('raster') }),
            React.createElement(RadioButton, { checked: resultsContext.viewMode === 'h3', label: "H3", onChange: () => handleChange('h3') }))));
};
export const IndexLayerSelector = () => {
    const layerIndices = useSelector(layersIndicesState);
    const selectedIndex = useSelector(layersSelectedIndexState);
    const viewTrueColor = useSelector(layersViewTrueColorState);
    const trueColorLayers = useSelector(runResultTrueColorLayersState);
    const themeName = useSelector(layersThemeNameState);
    const title = useMemo(() => {
        if (_isNil(themeName))
            return '';
        return themeName === 'custom' ? 'Spectral Indices' : 'Measures';
    }, [themeName]);
    const themeIndices = useMemo(() => {
        if (_isNil(layerIndices) || layerIndices.length === 0) {
            return null;
        }
        return layerIndices === null || layerIndices === void 0 ? void 0 : layerIndices.map((index) => {
            var _a;
            return (React.createElement(ThemeIndexCard, { name: index.label, description: (_a = index.description) !== null && _a !== void 0 ? _a : '', isSelected: selectedIndex === index, onClick: () => setSelectedLayerIndex(index), key: index.spectral_index }));
        });
    }, [layerIndices, selectedIndex, themeName]);
    if (!layerIndices)
        return null;
    return (React.createElement(DrawerAccordion, { title: title, defaultExpanded: true },
        React.createElement("div", { className: Styles.layerSelectorContainer }, themeIndices),
        !_isNil(trueColorLayers) && (React.createElement(Switch, { checked: viewTrueColor, onChange: toggleViewTrueColor, label: "View True Colour", containerClassName: GridContentStyles.max }))));
};
