var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Dialog } from '@orbica/component-sdk';
import ColorStyles from '@orbica/component-sdk/build/css/colors.scss';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import Tokens from '@orbica/component-sdk/build/css/tokens.scss';
import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import cx from 'classnames';
import React from 'react';
import { useArchiveJobMutation } from '@Data/jobs/Api';
import { createToast } from '@Services/notifications/NotificationService';
export const DeleteJobDialog = (props) => {
    var _a;
    const [archiveJob, { isLoading }] = useArchiveJobMutation();
    const handleDelete = () => __awaiter(void 0, void 0, void 0, function* () {
        yield archiveJob(props.selectedJob.id);
        createToast('Job deleted', 'success');
        props.onClose();
    });
    return (React.createElement(Dialog, { open: props.isOpen, onClose: props.onClose, disableBackdropClick: true, title: "Delete Job", FooterProps: {
            onSubmit: handleDelete,
            isSubmitting: isLoading,
            submitText: 'Delete Job',
            submittingText: 'Deleting Job',
            SubmitButtonProps: {
                backgroundColor: Tokens.feedbackError,
                textColor: '#fff',
                hoverColor: ColorStyles.feedbackError700,
                activeColor: ColorStyles.feedbackError700,
            },
        } },
        React.createElement("span", { className: cx(GridContentStyles.max, TypographyStyles.largeRegular) },
            "Are you sure you want to delete ", (_a = props.selectedJob) === null || _a === void 0 ? void 0 :
            _a.name,
            "?")));
};
