import { Skeleton } from '@mui/material';
import { Tooltip } from '@orbica/component-sdk';
import React from 'react';
import { getTitleFromImageType, } from './ImageCarousel';
export const Thumbnail = (props) => {
    if (props.imageState === null) {
        return null;
    }
    return props.imageState !== false && !props.isLoading ? (React.createElement(Tooltip, { title: getTitleFromImageType(props.type) },
        React.createElement("img", { src: props.imageState, className: "thumbnail", onClick: () => props.onClick(props.imageState, props.type) }))) : (React.createElement(Skeleton, { width: '100%', style: { aspectRatio: '1.3/1' }, className: "thumbnail", variant: "rectangular" }));
};
