import { ColorBrewerOptions } from './Types';
export const initialSymbologyState = {
    rawData: null,
    minMaxIndices: null,
    algorithms: null,
    selectedAlgorithm: null,
    selectedDataClass: null,
    selectedColorBrew: ColorBrewerOptions[0],
    opacity: 1,
    extrude: false,
    extrusionFactor: 2,
};
