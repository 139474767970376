import { ReactComponent as SettingsIcon } from '@Icons/md/settings.svg';
import { ReactComponent as CloseIcon } from '@Icons/md/x.svg';
import { FormControl, IconButton } from '@orbica/component-sdk';
import GridStyles from '@orbica/component-sdk/build/css/grid.scss';
import _isNil from 'lodash/isNil';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { layersMappedSelectedIndexState } from '@Data/layers/Reducer';
import { colorRangesBreaksState } from '@Data/symbology/Reducer';
import { AlgorithmSelect } from '../AlgorithmSelect/AlgorithmSelect';
import { ClassesSlider } from '../ClassesSlider/ClassesSlider';
import { ColorBrewerSelect } from '../ColorBrewerSelect/ColorBrewerSelect';
import { sclLegendOptions } from './Constants';
import Styles from './Legend.scss';
const LegendController = (props) => {
    return (React.createElement("div", { className: GridStyles.fullWidthDrawerGrid },
        React.createElement(ColorBrewerSelect, null),
        _isNil(props.type) && (React.createElement(React.Fragment, null,
            React.createElement(ClassesSlider, null),
            React.createElement(AlgorithmSelect, null)))));
};
const LegendRangeOption = ({ option, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: Styles.optionColorContainer },
            React.createElement("div", { className: Styles.optionColor, style: { backgroundColor: option.color } })),
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: Styles.min },
                React.createElement("span", null, option.min)),
            React.createElement("span", null, "\u2013"),
            React.createElement("div", null,
                React.createElement("span", null, option.max)))));
};
const SclLegendRangeOption = ({ option, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: Styles.optionColorContainer },
            React.createElement("div", { className: Styles.optionColor, style: {
                    backgroundColor: option.color,
                } })),
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: Styles.min },
                React.createElement("span", null, option.name)),
            React.createElement("span", null),
            React.createElement("div", null,
                React.createElement("span", null)))));
};
const LegendComponent = (props) => {
    if (_isNil(props.type)) {
        const colorRangesBreaks = useSelector(colorRangesBreaksState);
        const legendOptions = useMemo(() => {
            const output = [];
            for (let i = colorRangesBreaks.length - 1; i >= 0; i -= 1) {
                const crb = colorRangesBreaks[i];
                output.push(Object.assign(Object.assign({}, crb), { min: +crb.min.toFixed(3), max: +crb.max.toFixed(3) }));
            }
            return output;
        }, [colorRangesBreaks]);
        return (React.createElement("div", { className: Styles.legendOptions }, legendOptions === null || legendOptions === void 0 ? void 0 : legendOptions.map((option) => (React.createElement(LegendRangeOption, { option: option, key: option.id })))));
    }
    else if (props.selectedLayer.type === 'comparison' &&
        props.selectedLayer.comparisonLayer.type === 'scl') {
        return (React.createElement("div", { className: Styles.scllegendOptions }, sclLegendOptions === null || sclLegendOptions === void 0 ? void 0 : sclLegendOptions.map((option) => (React.createElement(SclLegendRangeOption, { option: option, key: option.id })))));
    }
    else if (props.selectedLayer.type === 'index') {
        const mappedIndexInfo = useSelector(layersMappedSelectedIndexState);
        const reversedIndexInfo = useMemo(() => {
            if (!mappedIndexInfo)
                return null;
            const output = [];
            for (let i = mappedIndexInfo.length - 1; i >= 0; i -= 1) {
                output.push(mappedIndexInfo[i]);
            }
            return output;
        }, [mappedIndexInfo]);
        return (React.createElement("div", { className: Styles.legendOptions }, reversedIndexInfo === null || reversedIndexInfo === void 0 ? void 0 : reversedIndexInfo.map((option) => (React.createElement(LegendRangeOption, { option: option, key: option.id })))));
    }
};
export const Legend = (props) => {
    var _a, _b;
    const [isExpanded, setIsExpanded] = useState(false);
    const settingsIcon = isExpanded ? React.createElement(CloseIcon, null) : React.createElement(SettingsIcon, null);
    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };
    const innerHtml = isExpanded ? (React.createElement(LegendController, { type: props.type })) : (React.createElement(LegendComponent, { type: props.type, selectedLayer: props.selectedLayer }));
    const title = isExpanded ? 'Styling Options' : 'Legend';
    const tooltip = isExpanded ? 'Close' : 'Set Styling Options';
    useEffect(() => {
        setIsExpanded(false);
    }, [props.selectedLayer]);
    const ResultsLegendForm = () => {
        return (React.createElement(FormControl, { label: title, containerClassName: Styles.legendContainer, action: React.createElement(IconButton, { IconProps: { icon: settingsIcon }, onClick: toggleExpanded, tooltip: tooltip }) }, innerHtml));
    };
    return _isNil(props.type) ? (React.createElement(ResultsLegendForm, null)) : ((((_a = props.selectedLayer) === null || _a === void 0 ? void 0 : _a.type) === 'index' ||
        ((_b = props.selectedLayer) === null || _b === void 0 ? void 0 : _b.comparisonLayer.type) === 'scl') && (React.createElement(FormControl, { label: title, containerClassName: Styles.legendContainer, action: props.selectedLayer.type === 'index' ? (React.createElement(IconButton, { IconProps: { icon: settingsIcon }, onClick: toggleExpanded, tooltip: tooltip })) : null }, innerHtml)));
};
