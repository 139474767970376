export const sclLegendOptions = [
    {
        color: '#FF0000',
        name: 'Saturated or defective',
        id: Math.random().toString(10),
    },
    {
        color: '#2E2E2E',
        name: 'Dark area pixels',
        id: Math.random().toString(10),
    },
    {
        color: '#643200',
        name: 'Cloud shadows',
        id: Math.random().toString(10),
    },
    {
        color: '#008000',
        name: 'Vegetation',
        id: Math.random().toString(10),
    },
    {
        color: '#FFE65A',
        name: 'Not vegetated',
        id: Math.random().toString(10),
    },
    {
        color: '#0000FF',
        name: 'Water',
        id: Math.random().toString(10),
    },
    {
        color: '#818181',
        name: 'Unclassified',
        id: Math.random().toString(10),
    },
    {
        color: '#C1C1C1',
        name: 'Cloud medium probability',
        id: Math.random().toString(10),
    },
    {
        color: '#FFFFFF',
        name: 'Cloud high probability',
        id: Math.random().toString(10),
    },
    {
        color: '#64C8FF',
        name: 'Thin cirrus',
        id: Math.random().toString(10),
    },
    {
        color: '#FF96FF',
        name: 'Snow',
        id: Math.random().toString(10),
    },
];
