import _isNil from 'lodash/isNil';
import { getAllKeys } from './GlobalKeys';
export function clearGlobalVariable(variable, callback) {
    window[variable] = null;
    if (callback) {
        callback();
    }
}
export function clearAllGlobalVariables(callback) {
    const keys = getAllKeys();
    keys.forEach((key) => (window[key] = null));
    if (callback) {
        callback();
    }
}
export function setGlobalVariable(key, value, callback) {
    window[key] = value;
    if (callback) {
        callback();
    }
}
export function hasGlobalValue(key) {
    return !_isNil(window[key]);
}
export function getGlobalVariable(key) {
    return window[key];
}
