import logo from '@Images/logo.svg';
import { MainContainer, Page, PageContent } from '@orbica/component-sdk';
import React from 'react';
import styled from 'styled-components';
const StyledTsAndCsContainer = styled('div') `
    grid-column: 1/-1;
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            height: 60px;
            width: auto;
        }
    }

    .indent {
        padding-left: 1rem;
    }
`;
const StyledMainContainer = styled(MainContainer) `
    max-height: 100vh;
`;
export const TermsAndConditionsDialog = () => {
    return (React.createElement(Page, null,
        React.createElement(PageContent, null,
            React.createElement(StyledMainContainer, null,
                React.createElement(StyledTsAndCsContainer, null,
                    React.createElement("div", { className: "header" },
                        React.createElement("h1", null, "Orbica EM Version 1 Release Agreement"),
                        React.createElement("img", { src: logo, alt: "Orbica logo" })),
                    React.createElement("div", { className: "indent" },
                        React.createElement("p", null, "This Proof of Concept Alpha Release Agreement is made and entered into by:"),
                        React.createElement("p", null,
                            React.createElement("b", null, "You"),
                            " (the User), and ",
                            React.createElement("b", null, "Orbica Ltd"),
                            ", with offices at 130 Lichfield Street, Christchurch Central, Christchurch 8011 New Zealand are sometimes collectively referred to as the \"Parties\" and singularly as a \"Party\"."),
                        React.createElement("p", null,
                            React.createElement("b", null, "Evaluation."),
                            " This Agreement sets forth the terms and conditions of Orbica's limited license of its Orbica EM Software (the \"Software\") to the User for use for the purpose of determining the usability, desirability, functionality, and compatibility of the Software with the User's applications and operations environment (the \"Evaluation Purpose\")."),
                        React.createElement("p", null,
                            React.createElement("b", null, "Evaluation Period."),
                            " The evaluation shall commence upon the Effective Date and continue for a period of 30 days (\"Evaluation Period\")."),
                        React.createElement("p", null,
                            React.createElement("b", null, "Software Access."),
                            " Orbica hereby grants to you, the User, a non-exclusive, non-sublicensable, non-assignable, royalty-free, and worldwide license to access and use the Software solely for the User's internal business operations in accordance with the terms of this agreement."),
                        React.createElement("p", null,
                            React.createElement("b", null, "Termination on Notice."),
                            " Either party may terminate this agreement for any reason by giving one (1) business days' notice via email to only the parties chosen email address. See Contact info page **"),
                        React.createElement("p", null,
                            React.createElement("b", null, "Representations."),
                            " Orbica warrants that we have all necessary authority to enter into this agreement and license the Software, and to the best of its knowledge, the Software does not infringe any intellectual property rights of any third party.")),
                    React.createElement("b", null, "No Warranty"),
                    React.createElement("div", { className: "indent" },
                        React.createElement("p", null,
                            React.createElement("b", null, "\"As-Is\"."),
                            " The Software and the data provided under the",
                            ' ',
                            React.createElement("a", { href: "https://sentinels.copernicus.eu/documents/247904/690755/Sentinel_Data_Legal_Notice", target: "_blank" }, "European Space Agency's Copernicus Programme"),
                            ' ',
                            "is provided \"as is,\" with all faults, defects, bugs, and errors. This is delivered via Element84's sentinel APIs."),
                        React.createElement("p", null,
                            React.createElement("b", null, "No Warranty."),
                            " Unless otherwise listed in this agreement, Orbica does not make any warranty regarding the Software or Data, and Orbica disclaims to the extent authorized by law all other warranties, whether express or implied, including any implied warranties of merchantability or fitness for a particular purpose.")),
                    React.createElement("b", null, "Ownership"),
                    React.createElement("div", { className: "indent" },
                        React.createElement("p", null,
                            React.createElement("b", null, "Licensor Ownership."),
                            " Orbica owns and retains all right, title, and interest, including all intellectual property rights, in and to the Software and all technologies related thereto, including any and all algorithms or processes developed by Orbica and its subsidiaries and all derivatives, modifications, or improvements of or to any of the foregoing made by or for Orbica whether or not created or developed in connection with the Services. The data shown through Orbica EM is Sentinel Hub data provided under the Creative Commons Attribution 4.0 International. (CC BY 4.0) and provided on an \"as is\" basis."),
                        React.createElement("p", null,
                            React.createElement("b", null, "Derivative Works."),
                            " The User hereby grants to Orbica a non-exclusive, non-transferable, worldwide, royalty-free, fully paid-up, perpetual, and irrevocable right and license to use data derived from analysis of the Software in aggregated or de-identified form for the purposes of providing and improving Orbica's products and services."),
                        React.createElement("p", null,
                            React.createElement("b", null, "Feedback."),
                            " If the User provides any feedback, comments, and suggestions to Orbica regarding the Software during the Evaluation Period (\"Feedback\"), such Feedback shall expressly exclude all of the User's Confidential Information contained therein. Notwithstanding the foregoing, the User shall have no obligation to provide Feedback to Orbica and for the avoidance of doubt, the User is providing the Feedback \"as is\" without warranty of any kind. Orbica's use of the Feedback shall be at Orbica's sole and exclusive risk, and the User will have no liability whatsoever in connection with the Feedback."),
                        React.createElement("p", null,
                            React.createElement("b", null, "Statistical Information."),
                            " Orbica may anonymously compile statistical information related to the performance of the Software for purposes of improving the Software, but only if such information does not identify the data as the User's or otherwise include the User's name."),
                        React.createElement("p", null,
                            React.createElement("b", null, "Confidentiality."),
                            " The parties hereto have entered into a Mutual Non-Disclosure Agreement (\"NDA\"), which is incorporated herein by reference, and the Parties agree to keep all information about this agreement confidential under terms of the NDA, including but not limited to the Software, issues and defects discovered, workarounds, fixes, resolutions, performance capabilities, data, tools, and methods. Both Parties agree to keep their relationship, this agreement, and the name of the other Party strictly confidential until both parties mutually agree to make this relationship and/or this agreement public.")),
                    React.createElement("b", null, "General Provisions"),
                    React.createElement("div", { className: "indent" },
                        React.createElement("p", null,
                            React.createElement("b", null, "Entire Agreement."),
                            " This agreement represents the entire understanding between the parties with respect to its subject matter and supersedes any previous communication or agreements that may exist."),
                        React.createElement("p", null,
                            React.createElement("b", null, "Assignment."),
                            " This Agreement may not be assigned by either party without the consent of the other party.")),
                    React.createElement("b", null, "Notices"),
                    React.createElement("div", { className: "indent" },
                        React.createElement("p", null,
                            React.createElement("b", null, "Method of Notice."),
                            " The parties shall give all notices and communications between the parties in writing by electronic mail to the party's address specified in this agreement, for the purposes of this section, the User's address that they used to sign up to the Software, is by default their specified address for communications. Orbica's specified address is",
                            ' ',
                            React.createElement("a", { href: "mailto:support@orbica.com" }, "support@orbica.com"),
                            ", any other communications or notices sent to any other @orbica.com address is not an acceptable method of notice and will therefore not be receipted."),
                        React.createElement("p", null,
                            React.createElement("b", null, "Receipt of Notice."),
                            " A notice given under this agreement will be effective on the other party's receipt of it."),
                        React.createElement("p", null,
                            React.createElement("b", null, "Governing Law."),
                            " This agreement shall be governed, construed, and enforced in accordance with the laws of New Zealand.")))))));
};
