var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Skeleton } from '@mui/material';
import { BaseCard } from '@orbica/component-sdk';
import React from 'react';
import styled from 'styled-components';
import Styles from './EntityAttributeCard.scss';
const StyledDiv = styled(BaseCard)(({ $columns, $rows }) => `
    grid-column: span ${$columns};
    grid-row: span ${$rows};
`);
export const EntityAttributeCard = React.forwardRef(function EntityAttributeCard(_a, ref) {
    var { gridColumns = 2, gridRows = 1, contentHeight = 'auto' } = _a, props = __rest(_a, ["gridColumns", "gridRows", "contentHeight"]);
    const content = props.isLoading ? (React.createElement(Skeleton, { variant: "rectangular", height: 22, width: '66%' })) : (props.value);
    return (React.createElement(StyledDiv, { className: Styles.attributeCardContainer, "$columns": gridColumns, "$rows": gridRows, ref: ref },
        props.title && (React.createElement("div", { className: Styles.titleContainer },
            React.createElement("span", null, props.title))),
        React.createElement("div", { className: Styles.contentContainer, style: {
                height: contentHeight,
            } }, content)));
});
