var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axiosInstance from '@Services/axios/AxiosClient';
import { BASE_API_URL, WEB_API_URL, baseApi, webApi } from '@Services/redux';
const imageryBaseApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getImageryPlatforms: build.query({
            query: () => {
                return {
                    url: 'get_platforms',
                    params: { in_use: true },
                };
            },
            transformResponse: (gqlResponse) => gqlResponse.platform,
        }),
        getIndexInfo: build.query({
            query: (index_name) => {
                return {
                    url: 'get_index_info',
                    params: { asi_short_name: index_name },
                };
            },
            transformResponse: (spectral_index_object) => {
                var _a;
                return (_a = spectral_index_object['spectral_index'][0]) !== null && _a !== void 0 ? _a : null;
            },
        }),
        getThemes: build.query({
            query: () => {
                return {
                    url: 'get_themes',
                };
            },
            transformResponse: (gqlResponse) => gqlResponse.theme,
        }),
        getIndicesByDomain: build.query({
            query: (domain) => {
                return {
                    url: `get_index_by_domain/${domain}`,
                };
            },
            transformResponse: (gqlResponse) => gqlResponse.spectral_index,
        }),
    }),
});
export const imageryWebApi = webApi.injectEndpoints({
    endpoints: (build) => ({
        getSpectralIndices_WEB: build.query({
            query: (params) => {
                return {
                    url: 'indices/get',
                    params: {
                        platform: params.platformName,
                        domain: params.domainName,
                    },
                };
            },
            transformResponse: (indicesObject) => {
                return Object.values(indicesObject);
            },
        }),
    }),
});
export function getSentinelCollectionImages(startDate, endDate, bbox) {
    return __awaiter(this, void 0, void 0, function* () {
        const startStr = startDate.toJSDate().toISOString();
        const endStr = endDate.toJSDate().toISOString();
        return yield axiosInstance.get(`https://earth-search.aws.element84.com/v1/collections/sentinel-2-c1-l2a/items?limit=12&datetime=${startStr}/${endStr}&bbox=${bbox.toString()}`);
    });
}
export const { useGetSpectralIndices_WEBQuery } = imageryWebApi;
export const { useGetImageryPlatformsQuery, useGetIndexInfoQuery, useGetThemesQuery, useGetIndicesByDomainQuery, } = imageryBaseApi;
export const Domains = ['vegetation', 'water', 'burn', 'soil', 'urban', 'snow'];
export function getWebSpectralIndexFromThemeIndex(themeIndex) {
    return __awaiter(this, void 0, void 0, function* () {
        const indicesByDomain = yield axiosInstance.get(`${WEB_API_URL}/indices/get?platform=sentinel2a&domain=${themeIndex.spectral_index.domain}`);
        const index = indicesByDomain[themeIndex.spectral_index.asi_short_name];
        return index;
    });
}
export function getAugmentedThemes() {
    return __awaiter(this, void 0, void 0, function* () {
        const [themesResponse, webApiIndexInfo] = yield Promise.all([
            axiosInstance.get(`${BASE_API_URL}/get_themes`),
            axiosInstance.get(`${WEB_API_URL}/indices/get?platform=sentinel2a`),
        ]);
        const themes = themesResponse['theme'];
        const mappedThemes = themes.map((theme) => (Object.assign(Object.assign({}, theme), { theme_spectral_indices: theme.theme_spectral_indices.map((themeIndex) => (Object.assign(Object.assign({}, themeIndex), { spectral_index: Object.assign(Object.assign({}, themeIndex.spectral_index), webApiIndexInfo[themeIndex.spectral_index.asi_short_name]) }))) })));
        return mappedThemes;
    });
}
