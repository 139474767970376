import { Table } from '@orbica/component-sdk';
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import Styles from './IndexInfoTable.scss';
export function IndexInfoTable({ title, indexInfo }) {
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor('high_value_desc', {
            header: 'High Value',
            enableSorting: false,
        }),
        columnHelper.accessor('low_value_desc', {
            header: 'Low Value',
            enableSorting: false,
        }),
        columnHelper.accessor('change_value_desc', {
            header: 'Change Value',
            enableSorting: false,
        }),
    ];
    return (React.createElement("div", { className: Styles.infoArea },
        React.createElement("p", { className: Styles.infoName },
            indexInfo.asi_long_name,
            " (",
            title,
            ")"),
        React.createElement("p", { className: Styles.infoDescription }, indexInfo.index_description),
        React.createElement(Table, { columns: columns, data: [indexInfo] })));
}
