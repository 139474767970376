export const GlobalKeys = {
    CancelTokens: 'cancelTokens',
    QueriedPolygonsByResolution: 'polygonsByResolution',
};
export const getAllKeys = () => {
    const output = [];
    for (const key in GlobalKeys) {
        if (Object.prototype.hasOwnProperty.call(GlobalKeys, key)) {
            output.push(key);
        }
    }
    return output;
};
