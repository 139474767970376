import React from 'react';
import { createRoot } from 'react-dom/client';
import { worker } from '@Services/mocks/worker';
import { App } from './App';
const container = document.getElementById('root');
const root = createRoot(container);
if (process.env.USE_MOCK === 'true') {
    worker.start().then(render);
}
else {
    render();
}
function render() {
    root.render(React.createElement(App, null));
}
