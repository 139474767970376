import _isNil from 'lodash/isNil';
import React from 'react';
import { getAreaReferenceObject } from '@Services/helpers/Area';
export const AreaConversionTooltipTable = (props) => {
    if (_isNil(props.area))
        return null;
    const reference = getAreaReferenceObject(props.area);
    return (React.createElement("table", null,
        React.createElement("tbody", null,
            React.createElement("tr", null,
                React.createElement("td", null, "square metres"),
                React.createElement("td", null, reference.sqm.toFixed(1))),
            React.createElement("tr", null,
                React.createElement("td", null, "hectares"),
                React.createElement("td", null, reference.ha.toFixed(1))),
            React.createElement("tr", null,
                React.createElement("td", null, "square kilometres"),
                React.createElement("td", null, reference.sqKm.toFixed(1))))));
};
