import { ReactComponent as PlusIcon } from '@Icons/lg/plus.svg';
import { ReactComponent as TrashIcon } from '@Icons/md/trash-2.svg';
import { ReactComponent as XsPlusIcon } from '@Icons/xs/plus.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { Collapse } from '@mui/material';
import { IconButton, SecondaryButton, TertiaryButton, TextInput, isEmailAddressValid, } from '@orbica/component-sdk';
import ColorStyles from '@orbica/component-sdk/build/css/colors.scss';
import Flex from '@orbica/component-sdk/build/css/flex.scss';
import GridStyles from '@orbica/component-sdk/build/css/grid.scss';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import cx from 'classnames';
import _cloneDeep from 'lodash/cloneDeep';
import _isNil from 'lodash/isNil';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { TitleAndDescription } from './TitleAndDescription';
const StyledTertiaryButton = styled(TertiaryButton) `
    justify-content: flex-start;
    padding: 0;
`;
export const NotificationRecipient = (props) => {
    return (React.createElement("div", { className: cx(Flex.flex, Flex.justifyBetween, Flex.alignItemsCenter, GridContentStyles.mediumSmall) },
        React.createElement("span", null, props.email),
        React.createElement(IconButton, { onClick: props.onDelete, IconProps: {
                icon: React.createElement(TrashIcon, null),
                attribute: 'fill',
                color: ColorStyles.feedbackError400,
            }, size: "small", tooltip: "Remove recipient" })));
};
export const NotificationStage = (props) => {
    var _a;
    const { user } = useAuth0();
    const [invalidEmailError, setInvalidEmailError] = useState(null);
    const emailInputRef = useRef(null);
    const handleDeleteRecipient = (recipient) => {
        const clonedRecipients = _cloneDeep(props.notificationRecipients);
        const index = clonedRecipients.findIndex((r) => r === recipient);
        clonedRecipients.splice(index, 1);
        props.onUpdateRecipients(clonedRecipients);
    };
    const handleAddRecipient = (input) => {
        const theEmailAddress = input !== null && input !== void 0 ? input : emailInputRef.current.value;
        if (!isEmailAddressValid(theEmailAddress)) {
            setInvalidEmailError('Please enter a valid email address');
        }
        else if (props.notificationRecipients
            .map((r) => r.toLowerCase())
            .includes(theEmailAddress.toLowerCase())) {
            setInvalidEmailError('This recipient has already been added');
        }
        else {
            const clonedRecipients = _cloneDeep(props.notificationRecipients);
            clonedRecipients.push(theEmailAddress);
            props.onUpdateRecipients(clonedRecipients);
            if (_isNil(input)) {
                emailInputRef.current.value = '';
                setInvalidEmailError(null);
            }
        }
    };
    const canAddSelf = !_isNil(user) && !((_a = props.notificationRecipients) === null || _a === void 0 ? void 0 : _a.includes(user.email));
    return (React.createElement(React.Fragment, null,
        React.createElement(TitleAndDescription, { title: "Add recipients to receive email notifications when a change is detected.", description: [
                `Notifications are sent every time a change is detected in your area${props.aoiCount > 1 ? 's' : ''} that exceeds one standard deviation from the baseline value.`,
            ] }),
        React.createElement(TextInput, { ref: emailInputRef, placeholder: "Add a new email recipient", containerClassName: GridContentStyles.medium, error: !_isNil(invalidEmailError), errorMessage: invalidEmailError, onEnter: handleAddRecipient, autoFocus: true }),
        React.createElement(SecondaryButton, { onClick: () => handleAddRecipient(), className: GridContentStyles.small, style: { alignSelf: 'start' }, StartIconProps: {
                icon: React.createElement(PlusIcon, null),
                attribute: 'fill',
            } }, "Add Recipient"),
        React.createElement(Collapse, { in: canAddSelf, className: GridContentStyles.max, mountOnEnter: true, unmountOnExit: true, style: {
                marginTop: '-8px',
            } },
            React.createElement(StyledTertiaryButton, { size: "small", StartIconProps: {
                    icon: React.createElement(XsPlusIcon, null),
                    attribute: 'fill',
                }, onClick: () => handleAddRecipient(user.email) }, "Add me")),
        React.createElement(Collapse, { in: !_isNil(props.notificationRecipients) &&
                props.notificationRecipients.length > 0, className: GridContentStyles.max }, props.notificationRecipients &&
            props.notificationRecipients.length > 0 && (React.createElement("div", { className: GridStyles.dialogGrid },
            React.createElement("span", { className: cx(TypographyStyles.smallBold, GridContentStyles.max) }, "Recipients"),
            props.notificationRecipients.map((recipient) => (React.createElement(NotificationRecipient, { email: recipient, key: recipient, onDelete: () => handleDeleteRecipient(recipient) }))))))));
};
