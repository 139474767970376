var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { TokenHandler } from '@Services/authentication/TokenHandler';
export const AXIOS_CANCEL_ERROR_CODE = -999;
const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((config) => {
    config.headers.set('Authorization', `Bearer ${TokenHandler.instance.getToken()}`);
    return config;
}, (error) => {
    return Promise.reject(error);
});
axiosInstance.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    var _a, _b;
    if (error.code === 'ERR_CANCELED') {
        return Promise.resolve();
    }
    else if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 400 && ((_b = error.response) === null || _b === void 0 ? void 0 : _b.data)) {
        if (typeof error.response.data === 'object' &&
            Object.hasOwn(error.response.data, 'code') &&
            error.response.data.code === 'invalid-jwt') {
            window.location.href = '/login';
        }
    }
    return Promise.reject(error);
});
export const axiosBaseQuery = ({ baseUrl } = { baseUrl: '' }) => ({ url, method, data, params, headers }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    try {
        const fullUrl = baseUrl !== '' ? baseUrl + '/' + url : url;
        const result = yield axiosInstance({
            url: fullUrl,
            method,
            data,
            params,
            headers,
        });
        return { data: result };
    }
    catch (axiosError) {
        const err = axiosError;
        return {
            error: {
                status: (_a = err.response) === null || _a === void 0 ? void 0 : _a.status,
                data: ((_b = err.response) === null || _b === void 0 ? void 0 : _b.data) || err.message,
            },
        };
    }
});
export default axiosInstance;
