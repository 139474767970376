import React from 'react';
import { Marker } from 'react-map-gl/maplibre';
import { useSelector } from 'react-redux';
import { geocoderLocationState } from '@Data/mapToolbar/Reducer';
import Styles from './GeocoderMarker.scss';
export const GeocoderMarker = () => {
    const geocoderLocation = useSelector(geocoderLocationState);
    return (React.createElement(React.Fragment, null, geocoderLocation && (React.createElement(Marker, { longitude: geocoderLocation['center'][0], latitude: geocoderLocation['center'][1] },
        React.createElement("div", { className: Styles.circle })))));
};
