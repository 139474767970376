import { useAuth0 } from '@auth0/auth0-react';
import { PrimaryButton, SecondaryButton, TertiaryButton, } from '@orbica/component-sdk';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Styles from '../LoginView.scss';
export const Splash = () => {
    const [isRedirecting, setIsRedirecting] = useState(false);
    const { loginWithRedirect } = useAuth0();
    const navigate = useNavigate();
    const handleRedirectToLogon = () => {
        setIsRedirecting(true);
        loginWithRedirect();
    };
    const handleRedirectToSignUp = () => {
        navigate('/signup');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: Styles.h2Container },
            React.createElement("span", { className: Styles.h2 }, "Welcome to the Orbica"),
            React.createElement("span", { className: Styles.h2 }, "GeoAI Platform")),
        React.createElement("div", { className: Styles.btnContainer },
            React.createElement(PrimaryButton, { className: Styles.btnLogIn, onClick: handleRedirectToLogon, isLoading: isRedirecting, loadingText: 'Redirecting' }, "Login"),
            React.createElement(SecondaryButton, { displayChevron: true, className: Styles.btnSignUp, onClick: handleRedirectToSignUp }, "Sign up")),
        React.createElement("div", { className: Styles.chatContainer },
            React.createElement("span", { className: Styles.letsChat }, "New to Orbica?"),
            React.createElement(TertiaryButton, { textColor: Styles.brandGreen, "$removePadding": true, className: Styles.btnLetsChat, href: "https://www.orbica.com/contact", target: "_blank" }, "Let's chat"))));
};
