import { createSlice } from '@reduxjs/toolkit';
import { initialLayersState } from './State';
const LayersSlice = createSlice({
    name: 'Layers',
    initialState: initialLayersState,
    reducers: {
        reset() {
            return initialLayersState;
        },
        setIndices(state, action) {
            state.indices = action.payload;
        },
        setSelectedIndex(state, action) {
            state.selectedIndex = action.payload;
        },
        toggleViewTrueColor(state) {
            state.viewTrueColor = !state.viewTrueColor;
        },
        setThemeName(state, action) {
            state.themeName = action.payload;
        },
        setMappedSelectedIndex(state, action) {
            state.mappedSelectedIndex = action.payload;
        },
    },
});
export const LayersActions = LayersSlice.actions;
export const layersIndicesState = (state) => state.layers.indices;
export const layersSelectedIndexState = (state) => state.layers.selectedIndex;
export const layersViewTrueColorState = (state) => state.layers.viewTrueColor;
export const layersThemeNameState = (state) => state.layers.themeName;
export const layersMappedSelectedIndexState = (state) => state.layers.mappedSelectedIndex;
export default LayersSlice.reducer;
