import { createSlice } from '@reduxjs/toolkit';
import { initialMapToolbarState } from './State';
const MapToolbarSlice = createSlice({
    name: 'MapToolbar',
    initialState: initialMapToolbarState,
    reducers: {
        reset() {
            return initialMapToolbarState;
        },
        setMapDrawState(state, action) {
            state.drawState = action.payload;
        },
        setGeocoderLocationState(state, action) {
            state.geocoderLocation = action.payload;
        },
        setLineFeatureCollectionState(state, action) {
            state.lineFeatureCollection = action.payload;
        },
        setAoisFeatureCollectionState(state, action) {
            state.aoisFeatureCollection = action.payload;
        },
        setIsCreateJobDialogOpenState(state, action) {
            state.isCreateJobDialogOpen = action.payload;
        },
        setDeleteAoiIdState(state, action) {
            state.deleteAoiId = action.payload;
        },
        setSelectedAoiIdState(state, action) {
            state.selectedAoiId = action.payload;
        },
    },
});
export const MapToolbarActions = MapToolbarSlice.actions;
export const drawState = (state) => state.mapToolbar.drawState;
export const geocoderLocationState = (state) => state.mapToolbar.geocoderLocation;
export const lineFeatureCollectionState = (state) => state.mapToolbar.lineFeatureCollection;
export const aoisFeatureCollectionState = (state) => state.mapToolbar.aoisFeatureCollection;
export const isCreateJobDialogOpenState = (state) => state.mapToolbar.isCreateJobDialogOpen;
export const deleteAoiIdState = (state) => state.mapToolbar.deleteAoiId;
export const selectedAoiIdState = (state) => state.mapToolbar.selectedAoiId;
export default MapToolbarSlice.reducer;
