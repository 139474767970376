import { ReactComponent as ZoomToBoundsIcon } from '@Icons/md/target.svg';
import { Skeleton } from '@mui/material';
import ColorStyles from '@orbica/component-sdk/build/css/colors.scss';
import bbox from '@turf/bbox';
import React, { useContext, useId, useRef, useState } from 'react';
import { Map as BaseMap, Layer, Source, } from 'react-map-gl/maplibre';
import { useResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';
import { BasemapSelectorControl } from '@Components/Map/Controls/BasemapSelectorControl';
import { CustomControl } from '@Components/Map/Controls/CustomControl';
import { LightMaptilerBasemapOption, MapsContext, } from '@Services/maps';
const StyledAoiThumbnailContainer = styled('div')(({ $interactive }) => `
    display: flex;
    width: 100%;
    height: 100%;
    cursor: ${$interactive ? 'default' : 'pointer'};
`);
export const AreaOfInterestMap = ({ feature, type = 'thumbnail', name, }) => {
    const mapRef = useRef(null);
    const mapsContext = useContext(MapsContext);
    const { ref, width, height } = useResizeDetector({
        onResize: () => {
            var _a;
            if (hasLoaded) {
                (_a = mapRef.current) === null || _a === void 0 ? void 0 : _a.resize();
                fitBounds();
            }
        },
    });
    const [hasLoaded, setHasLoaded] = useState(false);
    const fitBounds = () => {
        var _a;
        const padding = height ? height / 8 : window.innerHeight / 10;
        const duration = type === 'thumbnail' ? 0 : 100;
        const area = bbox(feature);
        (_a = mapRef.current) === null || _a === void 0 ? void 0 : _a.fitBounds(area, {
            padding: padding,
            duration: duration,
        });
    };
    const handleLoad = () => {
        fitBounds();
        setHasLoaded(true);
    };
    const handleOpenAoiDialog = (e) => {
        if (type === 'thumbnail') {
            e.preventDefault();
            e.stopPropagation();
            mapsContext.onOpenAoiMap(feature, name);
        }
    };
    const isInteractive = type === 'dialog';
    const mapStyle = type === 'thumbnail'
        ? LightMaptilerBasemapOption
        : mapsContext.selectedBasemap;
    return (React.createElement(StyledAoiThumbnailContainer, { ref: ref, onClick: handleOpenAoiDialog, "$interactive": isInteractive, title: isInteractive ? null : 'Expand map' },
        !hasLoaded && (React.createElement(Skeleton, { height: height, width: width, style: {
                position: 'absolute',
            }, variant: "rectangular" })),
        React.createElement(BaseMap, { mapStyle: mapStyle.style, ref: mapRef, onLoad: handleLoad, attributionControl: false, dragPan: isInteractive, scrollZoom: isInteractive, interactive: isInteractive, style: { visibility: hasLoaded ? 'visible' : 'hidden' } },
            React.createElement(Source, { type: "geojson", key: useId(), data: feature },
                React.createElement(Layer, { type: "line", paint: {
                        'line-color': mapsContext.getOutlineColor('hex'),
                        'line-width': 2,
                    } }),
                React.createElement(Layer, { key: "layer", type: "fill", paint: {
                        'fill-color': ColorStyles.greyscale700,
                        'fill-opacity': 0.5,
                    } }))),
        type === 'dialog' && (React.createElement(React.Fragment, null,
            React.createElement(BasemapSelectorControl, { position: "top-right" }),
            React.createElement(CustomControl, { position: "top-left", icon: React.createElement(ZoomToBoundsIcon, null), onClick: fitBounds, tooltip: "Zoom to bounds" })))));
};
