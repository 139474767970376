import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { store } from '@Services/redux';
import { SymbologyActions } from './Reducer';
export function initializeSymbology(metadataResponse) {
    if (!_isNil(metadataResponse.classification) &&
        !_isNil(metadataResponse.min_max_indices) &&
        !_isEmpty(metadataResponse.classification))
        try {
            const firstEntry = Object.entries(metadataResponse.classification)[0];
            const firstEntryBreaks = Object.entries(firstEntry[1]);
            const algorithms = Object.keys(firstEntryBreaks[0][1]);
            const initialAlgorithm = algorithms[0];
            const initialDataClass = 10;
            store.dispatch(SymbologyActions.initialize({
                algorithms,
                initialDataClass,
                initialAlgorithm,
                rawData: metadataResponse.classification,
                minMaxIndices: metadataResponse.min_max_indices,
            }));
        }
        catch (e) {
            console.error(e);
        }
}
export function setColorBrewOption(option) {
    store.dispatch(SymbologyActions.setSelectedColorBrew(option));
}
export function setSelectedBreaks(breaks) {
    store.dispatch(SymbologyActions.setSelectedClass(breaks));
}
export function setSelectedAlgorithm(algorithm) {
    store.dispatch(SymbologyActions.setSelectedAlgorithm(algorithm));
}
export function setOpacity(opacity) {
    store.dispatch(SymbologyActions.setOpacity(opacity));
}
export function resetSymbology() {
    store.dispatch(SymbologyActions.reset());
}
export function toggleExtrusion() {
    store.dispatch(SymbologyActions.toggleExtrusion());
}
export function setExtrusionFactor(factor) {
    store.dispatch(SymbologyActions.setExtrusionFactor(factor));
}
