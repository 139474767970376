import { Dialog } from '@orbica/component-sdk';
import ColorStyles from '@orbica/component-sdk/build/css/colors.scss';
import _isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { AreaOfInterestMap } from '@Components/AreaOfInterest/AreaOfInterestMap';
import { hexToRgba } from '@Services/helpers/Gis';
import { SatelliteMaptilerBasemapOption } from './Basemaps';
import { MapsContext } from './MapsContext';
export const MapsProvider = (props) => {
    var _a;
    const [basemap, setBasemap] = useState(SatelliteMaptilerBasemapOption);
    const [aoiFeature, setAoiFeature] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(null);
    const [bounds, setBounds] = useState(null);
    const handleChangeBasemap = (newBasemap) => {
        setBasemap(newBasemap);
    };
    const handleOpenAoiMap = (geometry, name) => {
        setAoiFeature({ feature: geometry, name: name });
    };
    const handleSetZoomLevel = (zoom) => setZoomLevel(zoom);
    const handleSetBounds = (bounds) => setBounds(bounds);
    const getOutlineColor = (type, opacity = 255) => {
        let hexValue;
        switch (basemap.name) {
            case 'Light':
                hexValue = ColorStyles.feedbackSuccess200;
                break;
            default:
                hexValue = ColorStyles.decorativeYellow;
                break;
        }
        return type === 'hex' ? hexValue : hexToRgba(hexValue, opacity);
    };
    return (React.createElement(MapsContext.Provider, { value: {
            selectedBasemap: basemap,
            onChangeBasemap: handleChangeBasemap,
            onOpenAoiMap: handleOpenAoiMap,
            zoomLevel: zoomLevel,
            onSetZoomLevel: handleSetZoomLevel,
            bounds: bounds,
            onSetBounds: handleSetBounds,
            getOutlineColor: getOutlineColor,
        } },
        props.children,
        React.createElement(Dialog, { open: !_isNil(aoiFeature), onClose: () => setAoiFeature(null), keepMounted: false, title: (_a = aoiFeature === null || aoiFeature === void 0 ? void 0 : aoiFeature.name) !== null && _a !== void 0 ? _a : '' },
            React.createElement("div", { style: { gridColumn: '1/-1', height: '60vh' } },
                React.createElement(AreaOfInterestMap, { feature: aoiFeature === null || aoiFeature === void 0 ? void 0 : aoiFeature.feature, type: "dialog" })))));
};
