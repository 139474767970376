import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import React from 'react';
import styled from 'styled-components';
import { SpectralIndexPill } from '@Components/EntityDetails/SpectralIndexPill';
const StyledContainer = styled('div') `
    display: flex;
    flex-direction: column;
    gap: 12px;

    .themeIndex {
        display: flex;
        flex-direction: column;

        .titleContainer {
            display: flex;
            align-items: center;

            span {
                margin-right: 18px;
            }
        }
    }
`;
const ThemeIndex = ({ index }) => {
    return (React.createElement("div", { className: "themeIndex" },
        React.createElement("div", { className: "titleContainer" },
            React.createElement("span", { className: TypographyStyles.smallBold }, index.name),
            React.createElement(SpectralIndexPill, { size: "small", shortName: index.spectral_index.asi_short_name, longName: index.spectral_index.asi_long_name })),
        React.createElement("span", { className: TypographyStyles.smallRegular }, index.description)));
};
export const ThemeIndices = (props) => {
    return (React.createElement(StyledContainer, null, props.indices.map((index) => (React.createElement(ThemeIndex, { index: index, key: `${index.name}${index.spectral_index.id}` })))));
};
