import ColorStyles from '@orbica/component-sdk/build/css/colors.scss';
import styled from 'styled-components';
export const OutlinedContainer = styled('div')(({ $selected, $grid, $padding }) => `
    ${$grid ? 'grid-column: 1/-1; margin: 1px;' : ''}
    border: 1px solid ${$selected ? ColorStyles.brandGreen300 : ColorStyles.greyscale400};
    border-radius: 4px;
    padding: ${$padding !== null && $padding !== void 0 ? $padding : '16px 24px'};
   
    outline: 1px solid ${$selected ? ColorStyles.brandGreen300 : 'transparent'};
`);
