var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Pill } from '@orbica/component-sdk';
import React from 'react';
export const SpectralIndexPill = (_a) => {
    var { size = 'small' } = _a, props = __rest(_a, ["size"]);
    return (React.createElement(Pill, { state: props.disabled ? 'secondary' : 'info', size: size, tooltip: props.longName, onClick: props.onClick, className: props.className, style: props.style, as: props.as }, props.shortName));
};
