import _isNil from 'lodash/isNil';
import React from 'react';
import styled from 'styled-components';
import { AoiRunIndexResultPill } from '@Components/EntityDetails/AoiRunIndexResultPill';
import { SpectralIndexPill } from '@Components/EntityDetails/SpectralIndexPill';
const StyledResultContainer = styled('div') `
    display: grid;
    width: 350px;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    align-items: center;

    .indexResultContainer {
        display: grid;
        grid-column: span 2;
        grid-template-columns: inherit;
        gap: inherit;
        align-items: center;
    }
`;
function getIndexOrLabel(runResult) {
    return runResult.spectral_index === runResult.label ? (React.createElement(SpectralIndexPill, { shortName: runResult.spectral_index })) : (React.createElement("span", null, runResult.label));
}
export const IndicesResult = (props) => {
    if (_isNil(props.results) || props.results.length === 0) {
        return React.createElement(StyledResultContainer, null, "-");
    }
    return (React.createElement(StyledResultContainer, null, props.results.map((result, i) => {
        return (React.createElement("div", { className: "indexResultContainer", key: `${result.spectral_index}${props.aoiName}` },
            getIndexOrLabel(result),
            React.createElement(AoiRunIndexResultPill, { resultValue: result.value })));
    })));
};
