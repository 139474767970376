import { createSlice } from '@reduxjs/toolkit';
import { initialRunResultsState } from './State';
const RunResultsSlice = createSlice({
    name: 'RunResults',
    initialState: initialRunResultsState,
    reducers: {
        reset() {
            return initialRunResultsState;
        },
        setRunResultsMetadata(state, action) {
            state.rawMetadata = action.payload;
        },
        setRunResultRasterLayers(state, action) {
            state.rasterLayers = action.payload;
        },
        setRunResultTrueColorLayers(state, action) {
            state.trueColorLayers = action.payload;
        },
    },
});
export const RunResultsActions = RunResultsSlice.actions;
export const rawMetadataState = (state) => state.runResults.rawMetadata;
export const runResultRasterLayersState = (state) => state.runResults.rasterLayers;
export const runResultTrueColorLayersState = (state) => state.runResults.trueColorLayers;
export default RunResultsSlice.reducer;
