var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _isNil from 'lodash/isNil';
import React from 'react';
import { ThemeIndexCard } from '@Components/Themes/ThemeLayers/ThemeIndexCard';
import { StyledLayerTreeContainer } from './StyledLayerTreeComponents';
export const ThemeIndexLayerTree = (props) => {
    var _a, _b, _c;
    const handleSelectIndex = (index) => __awaiter(void 0, void 0, void 0, function* () {
        props.onSetSpectralIndex(index.spectral_index, props.selectedTheme);
    });
    const selectedIndexShortName = ((_a = props.selectedLayer) === null || _a === void 0 ? void 0 : _a.type) === 'index'
        ? (_b = props.selectedLayer) === null || _b === void 0 ? void 0 : _b.index.short_name
        : null;
    return (React.createElement(StyledLayerTreeContainer, null, (_c = props.selectedTheme) === null || _c === void 0 ? void 0 : _c.theme_spectral_indices.map((themeIndex) => (React.createElement(ThemeIndexCard, { isSelected: !_isNil(props.selectedLayer) &&
            themeIndex.spectral_index.asi_short_name ===
                selectedIndexShortName &&
            props.selectedIndexTheme === props.selectedTheme, onClick: () => handleSelectIndex(themeIndex), name: themeIndex.name, description: themeIndex.description, key: `ticard${themeIndex.spectral_index.id}` })))));
};
