import { ReactComponent as MapIcon } from '@Icons/md/map.svg';
import { IconButton, Menu, MenuItem } from '@orbica/component-sdk';
import _isNil from 'lodash/isNil';
import React, { useContext, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { BasemapOptions, MapsContext } from '@Services/maps';
import { getControlContainer } from './helpers';
const BasemapMenu = (props) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const basemapContext = useContext(MapsContext);
    const openMenu = (e) => {
        setMenuAnchorEl(e.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };
    const handleBaseMapChange = (o) => {
        basemapContext.onChangeBasemap(o);
        if (!_isNil(props.onChange)) {
            props.onChange(o);
        }
        handleMenuClose();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { IconProps: {
                icon: React.createElement(MapIcon, null),
            }, onClick: openMenu, backgroundColor: "#fff", className: "custom-maplibregl-control" }),
        React.createElement(Menu, { open: !_isNil(menuAnchorEl), anchorEl: menuAnchorEl, onClose: handleMenuClose, size: "small", anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
            } }, BasemapOptions.map((o) => (React.createElement(MenuItem, { onClick: () => handleBaseMapChange(o), key: o.name, selected: o.name === basemapContext.selectedBasemap.name }, o.name))))));
};
let hasLoaded = false;
export const BasemapSelectorControl = (props) => {
    const basemapContext = useContext(MapsContext);
    const controlContainer = getControlContainer(props.position);
    if (!_isNil(controlContainer) && !hasLoaded) {
        controlContainer.innerHTML = '';
        hasLoaded = true;
    }
    const html = useMemo(() => {
        return hasLoaded ? React.createElement(BasemapMenu, Object.assign({}, props)) : null;
    }, [basemapContext.selectedBasemap, hasLoaded]);
    return !_isNil(controlContainer) && createPortal(html, controlContainer);
};
