import _isNil from 'lodash/isNil';
export function getSpectralIndicesLabelsByTheme(theme, indices, optionalParams = [], indexAttribute = 'spectral_index') {
    if (_isNil(theme) || (theme === null || theme === void 0 ? void 0 : theme.name) === 'custom') {
        return indices.map((i) => {
            const optionalEntries = Object.entries(i).filter(([key, value]) => optionalParams.includes(key));
            const output = {
                spectral_index: i[indexAttribute],
                label: i[indexAttribute],
            };
            optionalEntries.forEach(([key, value]) => {
                output[key] = value;
            });
            return output;
        });
    }
    else {
        return indices.map((i) => {
            const themeIndex = theme.theme_spectral_indices.find((t) => t.spectral_index.asi_short_name === i[indexAttribute]);
            const optionalEntries = Object.entries(i).filter(([key, value]) => optionalParams.includes(key));
            const output = {
                spectral_index: i[indexAttribute],
                label: themeIndex.name,
            };
            optionalEntries.forEach(([key, value]) => {
                output[key] = value;
            });
            return output;
        });
    }
}
