var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getUniqueIndices, } from '@Data/layers/helpers';
import axiosInstance from '@Services/axios/AxiosClient';
import { graphqlRequest } from '@Services/graphql/GraphqlClient';
import { BASE_API_URL, store } from '@Services/redux';
import { LayersActions } from './Reducer';
export function setSelectedLayerIndex(index) {
    store.dispatch(LayersActions.setSelectedIndex(index));
}
export function intializeLayerIndices(metadata, jobThemeDetails) {
    return __awaiter(this, void 0, void 0, function* () {
        store.dispatch(LayersActions.setThemeName(jobThemeDetails.name));
        const distinctIndices = getUniqueIndices(metadata);
        let displayIndices;
        if (!jobThemeDetails || jobThemeDetails.name === 'custom') {
            const baseIndexInfoResponse = yield graphqlRequest(`
                query GetSpectralIndexInfo {
                    spectral_index(where: {asi_short_name: {_in: [${distinctIndices}]}}) {
                        asi_short_name
                        index_description
                    }
                }                
            `);
            displayIndices = distinctIndices.map((shortName) => {
                var _a;
                return ({
                    label: shortName,
                    spectral_index: shortName,
                    description: baseIndexInfoResponse.status === 200
                        ? (_a = baseIndexInfoResponse.data['spectral_index'].find((i) => i.asi_short_name === shortName)) === null || _a === void 0 ? void 0 : _a.index_description
                        : null,
                });
            });
        }
        else {
            displayIndices = distinctIndices.map((shortName) => {
                const themeIndex = jobThemeDetails.theme_spectral_indices.find((tsi) => tsi.spectral_index.asi_short_name === shortName);
                return {
                    label: themeIndex.name,
                    spectral_index: shortName,
                    description: themeIndex.description,
                };
            });
        }
        if (Array.isArray(displayIndices) && displayIndices.length > 0) {
            store.dispatch(LayersActions.setIndices(displayIndices));
            store.dispatch(LayersActions.setSelectedIndex(displayIndices[0]));
        }
    });
}
export function toggleViewTrueColor() {
    store.dispatch(LayersActions.toggleViewTrueColor());
}
export function getIndexInfo(shortName) {
    return __awaiter(this, void 0, void 0, function* () {
        const indexInfo = yield axiosInstance.get(`${BASE_API_URL}/get_index_info?asi_short_name=${shortName}`);
        return indexInfo.spectral_index[0];
    });
}
export function setMappedSelectedIndex(index) {
    store.dispatch(LayersActions.setMappedSelectedIndex(index));
}
