import { MenuItem } from '@orbica/component-sdk';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { BasemapOptions, MapsContext } from '@Services/maps';
const BasemapContainer = styled('div') `
    grid-column: 1/-1;
`;
export const BaseMapSelector = () => {
    const basemapContext = useContext(MapsContext);
    const handleBaseMapChange = (o) => {
        basemapContext.onChangeBasemap(o);
    };
    return (React.createElement(BasemapContainer, null, BasemapOptions.map((o) => {
        return (React.createElement(MenuItem, { onClick: () => handleBaseMapChange(o), key: o.name, selected: o.name === basemapContext.selectedBasemap.name }, o.name));
    })));
};
