import { createContext, useState } from 'react';
import React from 'react';
export const ExploreResultsContext = createContext({
    selectedAoisFeatureCollection: null,
    onSetSelectedAoisFeatureCollection: () => console.warn('MapsContext.onSetSelectedAoiGeoms is not implemented'),
    onLoadData: () => null,
    loadDataListener: null,
    reset: () => null,
    viewMode: null,
    onSetViewMode: () => null,
});
export const ExploreResultsContextProvider = (props) => {
    const [selectedAoisFc, setSelectedAoisFc] = useState(null);
    const [loadDataListener, setLoadDataListener] = useState(null);
    const [viewMode, setViewMode] = useState('raster');
    const handleSetSelectedAoisFc = (fc) => setSelectedAoisFc(fc);
    const handleLoadData = () => {
        setLoadDataListener(Math.random().toString(12));
    };
    const handleReset = () => {
        setSelectedAoisFc(null);
        setLoadDataListener(null);
        setSelectedAoisFc(null);
    };
    const handleSetViewMode = (mode) => setViewMode(mode);
    return (React.createElement(ExploreResultsContext.Provider, { value: {
            selectedAoisFeatureCollection: selectedAoisFc,
            onSetSelectedAoisFeatureCollection: handleSetSelectedAoisFc,
            onLoadData: handleLoadData,
            loadDataListener: loadDataListener,
            reset: handleReset,
            viewMode: viewMode,
            onSetViewMode: handleSetViewMode,
        } }, props.children));
};
