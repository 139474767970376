var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Table } from '@orbica/component-sdk';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useMemo } from 'react';
export const ComparisonResultTable = (_a) => {
    var { ContainerProps } = _a, props = __rest(_a, ["ContainerProps"]);
    const columnHelper = createColumnHelper();
    const columns = useMemo(() => {
        return [
            columnHelper.accessor('comparativeValue', {
                header: 'Monitored Result (mean)',
                cell: (info) => {
                    var _a, _b;
                    const stats = info === null || info === void 0 ? void 0 : info.row.original;
                    return (_b = (_a = stats === null || stats === void 0 ? void 0 : stats.comparativeValue) === null || _a === void 0 ? void 0 : _a.toPrecision(3)) !== null && _b !== void 0 ? _b : null;
                },
                enableSorting: false,
            }),
            columnHelper.accessor('mean', {
                header: 'Baseline Value (mean))',
                cell: (info) => { var _a, _b; return (_b = (_a = info.getValue()) === null || _a === void 0 ? void 0 : _a.toPrecision(3)) !== null && _b !== void 0 ? _b : null; },
                enableSorting: false,
            }),
            columnHelper.accessor('upper', {
                header: 'Baseline Std. Deviation',
                cell: (info) => {
                    const stats = info.row.original;
                    return stats.upper && stats.mean
                        ? (stats.upper - stats.mean).toPrecision(3)
                        : null;
                },
                enableSorting: false,
            }),
        ];
    }, []);
    return (React.createElement("div", Object.assign({}, ContainerProps),
        React.createElement(Table, { columns: columns, data: props.isLoading ? [] : [props], loading: props.isLoading })));
};
