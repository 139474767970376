var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ReactComponent as FilterIcon } from '@Icons/md/filter.svg';
import { DrawerAccordion, PrimaryButton } from '@orbica/component-sdk';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import { skipToken } from '@reduxjs/toolkit/query';
import bbox from '@turf/bbox';
import _isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { aoisBaseApi } from '@Data/aois/Api';
import { clearAllCachedData } from '@Data/h3/Api';
import { jobsBaseApi } from '@Data/jobs/Api';
import { initializeFromRawMetadata, resetMetadata, useFilterRunResultMetadataQuery, } from '@Data/runResults/Api';
import { MapsContext } from '@Services/maps';
import { Navigation } from '@Services/navigation/Navigation';
import { ExploreResultsContext } from '../../ResultsContext/ResultsContext';
import { DataFiltersDialog } from './DataFiltersDialog/DataFiltersDialog';
import { SelectedFilters } from './SelectedFilters';
import { getAoisFromSearchParams, getDateRangeFromSearchParams, getIndicesFromSearchParams, } from './helpers';
function createAoisFeatureCollection(aois) {
    return {
        type: 'FeatureCollection',
        features: aois.map((aoi) => ({
            type: 'Feature',
            geometry: aoi.aoi_geom,
            properties: null,
        })),
    };
}
function getFilterPayload(filters) {
    const payload = {
        areas_of_interest_ids: filters.areasOfInterest.map((aoi) => aoi.id),
        index_short_names: filters.indices.map((i) => i.index_key),
        job_id: filters.job.id,
        get_classify: true,
    };
    if (filters.isBaseline) {
        payload.get_baseline = true;
    }
    else {
        payload.start_date = filters.startDate.toISO();
        payload.end_date = filters.endDate.toISO();
    }
    return payload;
}
function getFiltersFromSearchParams(searchParams, dispatch) {
    return __awaiter(this, void 0, void 0, function* () {
        const searchJobId = searchParams.get('jobId');
        const searchBaseline = searchParams.get('baseline');
        if (_isNil(searchJobId))
            return null;
        const detailedJobResponse = yield dispatch(jobsBaseApi.endpoints.getRunResultJobDetails.initiate(searchJobId));
        const detailedJob = detailedJobResponse.data;
        const filterIndices = getIndicesFromSearchParams(detailedJob, searchParams);
        let filterDateRange;
        if (_isNil(searchBaseline)) {
            filterDateRange = getDateRangeFromSearchParams(detailedJob, searchParams);
        }
        const aoisResponse = yield dispatch(aoisBaseApi.endpoints.getAois.initiate({ jobId: searchJobId }));
        const aois = aoisResponse.data.aois;
        const filterAois = getAoisFromSearchParams(aois, searchParams);
        return {
            job: detailedJob,
            areasOfInterest: filterAois,
            startDate: !_isNil(filterDateRange)
                ? DateTime.fromMillis(filterDateRange[0])
                : null,
            endDate: !_isNil(filterDateRange)
                ? DateTime.fromMillis(filterDateRange[1])
                : null,
            isBaseline: _isNil(filterDateRange),
            indices: filterIndices,
        };
    });
}
export const DataFiltersAccordion = (props) => {
    const [hasInitialized, setHasInitialized] = useState(false);
    const [areFiltersOpen, setAreFiltersOpen] = useState(false);
    const [searchParams] = useSearchParams();
    const [selectedFilters, setSelectedFilters] = useState(null);
    const mapsContext = useContext(MapsContext);
    const resultsContext = useContext(ExploreResultsContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data: runResultsMetadata, isFetching: isMetadataFetching } = useFilterRunResultMetadataQuery(!_isNil(selectedFilters)
        ? getFilterPayload(selectedFilters)
        : skipToken);
    useEffect(() => {
        resetMetadata();
        clearAllCachedData();
    }, [isMetadataFetching]);
    useEffect(() => {
        if (runResultsMetadata &&
            (runResultsMetadata === null || runResultsMetadata === void 0 ? void 0 : runResultsMetadata.run_result_metadata.length) > 0) {
            initializeFromRawMetadata(runResultsMetadata);
        }
    }, [runResultsMetadata]);
    useEffect(() => {
        const loadJob = () => __awaiter(void 0, void 0, void 0, function* () {
            const filters = yield getFiltersFromSearchParams(searchParams, dispatch);
            handleSetSelectedFilters(filters);
        });
        if (!hasInitialized) {
            if (searchParams.size > 0) {
                if (searchParams.get('load') === 'true') {
                    loadJob();
                }
                else {
                    setAreFiltersOpen(true);
                }
            }
            setHasInitialized(true);
        }
    }, [hasInitialized]);
    const zoomToSelectedAoisBounds = (aois) => {
        const featureCollection = createAoisFeatureCollection(aois);
        const aoiBbox = bbox(featureCollection);
        mapsContext.onSetBounds(aoiBbox);
    };
    const setQueryParams = (filters) => {
        const options = {
            jobId: filters.job.id,
            indices: filters.indices.map((index) => index.index_key).toString(),
            aoiIds: filters.areasOfInterest.map((aoi) => aoi.id).toString(),
        };
        if (filters.isBaseline) {
            options['baseline'] = true;
        }
        else if (filters.startDate && filters.endDate) {
            options['dateRange'] = [
                filters.startDate.toMillis(),
                filters.endDate.toMillis(),
            ].toString();
        }
        const url = Navigation.explore.results.getUrl(options);
        navigate(url);
    };
    const handleSetSelectedFilters = (filters) => {
        setQueryParams(filters);
        setSelectedFilters(filters);
        props.onSendData(filters);
        zoomToSelectedAoisBounds(filters.areasOfInterest);
        resultsContext.onSetSelectedAoisFeatureCollection(createAoisFeatureCollection(filters.areasOfInterest));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerAccordion, { title: "Filters", defaultExpanded: true },
            !_isNil(selectedFilters) && (React.createElement(SelectedFilters, { filters: selectedFilters, onZoomToCombinedBounds: zoomToSelectedAoisBounds })),
            React.createElement(PrimaryButton, { size: "medium", onClick: () => setAreFiltersOpen(true), StartIconProps: {
                    icon: React.createElement(FilterIcon, null),
                }, className: GridContentStyles.max, isLoading: isMetadataFetching, loadingText: "Fetching Data" },
                !_isNil(selectedFilters) ? 'Edit' : 'Set',
                " Filters")),
        React.createElement(DataFiltersDialog, { isOpen: areFiltersOpen, onClose: () => setAreFiltersOpen(false), isEditing: false, onSetFilters: handleSetSelectedFilters })));
};
