import { Header as SdkHeader } from '@orbica/component-sdk';
import React from 'react';
import Styles from './Header.scss';
import { UpgradeSubscriptionButton } from './components/UpgradeSubscriptionButton/UpgradeSubscriptionButton';
import { UserMenu } from './components/UserMenu/UserMenu';
export const Header = (props) => {
    return (React.createElement(SdkHeader, { logoProps: {
            href: '/',
        }, centerChildren: props.children, rightChildren: React.createElement("div", { className: Styles.rightContainer },
            React.createElement(UpgradeSubscriptionButton, null),
            React.createElement(UserMenu, null)) }));
};
