export const AerialBasemapOption = {
    name: 'Aerial (New Zealand)',
    style: {
        version: 8,
        sources: {
            'basemaps-aerial': {
                type: 'raster',
                tiles: [
                    `https://basemaps.linz.govt.nz/v1/tiles/aerial/WebMercatorQuad/{z}/{x}/{y}.webp?api=${process.env.LINZ_API_KEY}`,
                ],
                tileSize: 256,
                attribution: '© <a href="//www.linz.govt.nz/linz-copyright">LINZ CC BY 4.0</a> © <a href="//www.linz.govt.nz/data/linz-data/linz-basemaps/data-attribution">Imagery Basemap contributors</a>',
            },
        },
        layers: [
            {
                id: 'basemaps-aerial',
                type: 'raster',
                source: 'basemaps-aerial',
            },
        ],
    },
};
export const LightMaptilerBasemapOption = {
    name: 'Light',
    style: `https://api.maptiler.com/maps/basic-v2-light/style.json?key=${process.env.MAPTILER_API_KEY}`,
    attribution: 'test',
};
export const DarkMaptilerBasemapOption = {
    name: 'Dark',
    style: `https://api.maptiler.com/maps/streets-v2-dark/style.json?key=${process.env.MAPTILER_API_KEY}`,
};
export const SatelliteMaptilerBasemapOption = {
    name: 'Satellite',
    style: `https://api.maptiler.com/maps/satellite/style.json?key=${process.env.MAPTILER_API_KEY}`,
};
export const HybridMaptilerBasemapOption = {
    name: 'Hybrid',
    style: `https://api.maptiler.com/maps/hybrid/style.json?key=${process.env.MAPTILER_API_KEY}`,
};
export const BasemapOptions = [
    SatelliteMaptilerBasemapOption,
    HybridMaptilerBasemapOption,
    AerialBasemapOption,
    LightMaptilerBasemapOption,
    DarkMaptilerBasemapOption,
];
