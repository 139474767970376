var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Select, SelectOption, toTitleCase } from '@orbica/component-sdk';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import colorbrewer from 'colorbrewer';
import _isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getThemeIcon } from '@Data/imagery/helpers';
import { getIndexInfo, setMappedSelectedIndex } from '@Data/layers/Api';
import { combineIntervalsWithColors, getIntervals } from '@Data/layers/helpers';
import { selectedColorBrewState } from '@Data/symbology/Reducer';
import { ComparisonLayers, CustomIndexLayerTree, ThemeIndexLayerTree, } from './components';
const StyledThemeOptionContainer = styled('div') `
    img {
        width: 25px;
        height: auto;
        margin-right: 1rem;
    }
    display: flex;
    align-items: center;
`;
const ThemeOption = ({ theme }) => {
    return (React.createElement(StyledThemeOptionContainer, null,
        React.createElement("img", { src: getThemeIcon(theme.name), alt: `${theme.name} icon` }),
        toTitleCase(theme.name)));
};
export const ThemeSelector = (props) => {
    var _a;
    const [selectedTheme, setSelectedTheme] = useState(null);
    const [selectedIndexTheme, setSelectedIndexTheme] = useState(null);
    const [indexInfoArray, setIndexInfoArray] = useState(null);
    const dataClass = 10;
    const selectedBrew = useSelector(selectedColorBrewState);
    const mapColorToIndexInfo = (selectedBrew, dataClass) => {
        if (!_isNil(indexInfoArray)) {
            const colors = colorbrewer[selectedBrew.value][dataClass];
            const combinedColouredList = combineIntervalsWithColors(indexInfoArray, colors);
            setMappedSelectedIndex(combinedColouredList);
        }
    };
    const handleSelectThemeIndex = (index, theme) => __awaiter(void 0, void 0, void 0, function* () {
        setSelectedIndexTheme(theme);
        props.onSetLayer({
            index: index,
            type: 'index',
        });
        const indexInfoValues = yield getIndexInfo(index === null || index === void 0 ? void 0 : index.short_name);
        const indexInfoArrays = getIntervals(indexInfoValues.min, indexInfoValues.max, dataClass);
        setIndexInfoArray(indexInfoArrays);
        if (!_isNil(indexInfoArrays)) {
            const colors = colorbrewer[selectedBrew.value][dataClass];
            const combinedColouredList = combineIntervalsWithColors(indexInfoArrays, colors);
            setMappedSelectedIndex(combinedColouredList);
        }
    });
    useEffect(() => {
        mapColorToIndexInfo(selectedBrew, dataClass);
    }, [selectedBrew]);
    const handleSelectComparisonLayer = (layer) => {
        props.onSetLayer({
            type: 'comparison',
            comparisonLayer: layer,
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Select, { value: selectedTheme, onChange: (e, v) => setSelectedTheme(v), renderValue: (theme) => React.createElement(ThemeOption, { theme: theme }), loading: _isNil(props.themes), placeholder: "Select a Theme", label: "Theme", containerClassName: GridContentStyles.max }, (_a = props.themes) === null || _a === void 0 ? void 0 : _a.map((theme) => (React.createElement(SelectOption, { key: theme.id, value: theme },
            React.createElement(ThemeOption, { theme: theme }))))),
        !_isNil(selectedTheme) && selectedTheme.name !== 'custom' && (React.createElement(ThemeIndexLayerTree, { selectedLayer: props.selectedLayer, onSetSpectralIndex: handleSelectThemeIndex, selectedTheme: selectedTheme, selectedIndexTheme: selectedIndexTheme })),
        !_isNil(selectedTheme) && selectedTheme.name === 'custom' && (React.createElement(CustomIndexLayerTree, { selectedLayer: props.selectedLayer, onSetSpectralIndex: handleSelectThemeIndex, selectedTheme: selectedTheme })),
        !_isNil(selectedTheme) && (React.createElement(ComparisonLayers, { onSetComparisonLayer: handleSelectComparisonLayer, selectedLayer: props.selectedLayer }))));
};
